/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import 'flatpickr/dist/themes/light.css';
import React, { useEffect, useState } from 'react';
import { Button, Container, Form, FormLabel } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import uniqid from 'uniqid';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import ReactGA from "react-ga4"

export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      shipperId
      name
      alias
    }
  }
`;

const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      alias
      description
    }
  }
`;

export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      name
    }
  }
`;

const ShipperProductEditor = () => {

  const navigate = useNavigate()
  const { shipperGroupId, shipperId, productId } = useParams();

  const myShipper = useSelector((state) => state.slice.SHIPPER)
  const [errors, setErrors] = useState({})
  const [spinner, showSpinner] = useState(false);
  const [product, setProduct] = useState({
    id: uniqid(),
    name: '',
    alias: '',
    description: '',
    shipperId: shipperId,
  })

  const getProductById = () => {
    showSpinner(true);
    API.graphql({ query: getProduct, variables: { id: productId } })
      .then(({ data }) => { setProduct(data?.getProduct) })
      .catch((err) => console.log(err))
      .finally(() => showSpinner(false))
  }

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: productId ? `/shipper-group/${shipperGroupId}/shipper/${shipperId}/product/${productId}/edit` : `/shipper-group/${shipperGroupId}/shipper/${shipperId}/product/add`,
    })
  }, [])

  useEffect(() => { productId && getProductById() }, [productId])

  const handleChange = async (e) => setProduct((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));

  const isFormValid = async () => {
    const error = {};
    if (!product?.name?.trim()) error['name'] = 'Name is required'
    setErrors(error);
    return Object.keys(error).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await isFormValid()) {
      showSpinner(true)
      let myQuery = productId ? updateProduct : createProduct
      API.graphql({ query: myQuery, variables: { input: product } })
        .then((res) => {
          if (productId) toast.success('Product edited successfully!')
          else toast.success('New product created successfully!')
          navigate(`/shipper-group/${shipperGroupId}/shipper/${shipperId}/product`)
        }).catch((err) => {
          toast.error(err)
        }).finally(() => showSpinner(false))
    }
  }

  return (
    <>
      <PageHeader title={myShipper?.name} name={productId ? 'Update product' : 'Create Product'} classname={'container'} />

      <Spinner display={spinner}>

        <Container>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <FormLabel required={true}>Name</FormLabel>
              <Form.Control type='text' name='name' placeholder='e.g. ABC Specialty Pharmacy' value={product?.name} onChange={handleChange} isInvalid={!!errors?.name} />
              <Form.Control.Feedback type='invalid'>{errors?.name}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mt-4'>
              <FormLabel>Alias</FormLabel>
              <Form.Control type='text' name='alias' placeholder='e.g. abc-specialty' value={product?.alias} onChange={handleChange} />
            </Form.Group>
            <Form.Group className='mt-4'>
              <FormLabel>Description</FormLabel>
              <Form.Control as='textarea' name='description' rows={3} value={product?.description} onChange={handleChange} />
            </Form.Group>
            <hr />
            <Button type='submit' className='btn btn-dark'>Continue</Button>
            <Link to={`/shipper-group/${shipperGroupId}/shipper/${shipperId}/product`} className='btn btn-link text-muted'>Cancel</Link>
          </Form>
        </Container>

      </Spinner>
    </>
  )
}

export default ShipperProductEditor
