import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { PAGE_TITLE } from '../helpers';

const PageHeader = ({ title, name, menus, children, className, image, pageTitle,onStatusChange}) => {
	document.title = `${pageTitle ? pageTitle : name} ${PAGE_TITLE}`;
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
        const updateOnlineStatus = () => {
            setIsOnline(navigator.onLine);
        };

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        onStatusChange && onStatusChange(navigator.onLine);

        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, [isOnline]);

	return (
		<nav className='header'>
			<div className={className ? className : 'container-fluid'}>
				<div className='header-body'>
					<Row className='align-items-center'>

						{image &&
							<Col className='col-auto'>

								<div className="avatar avatar-xxl avatar-4by3">
									<img src={image} alt="..." className="avatar-img rounded img-fluid" style={{ objectFit: 'contain' }} />
								</div>
							</Col>
						}
						<Col>
							<h6 className='header-pretitle'>{title}</h6>
							<div className="d-flex align-items-center">
								<h1 className='header-title me-3'>{name}</h1>
							{ name === "Tasks Board" && (
								<div className="d-flex align-items-center">
									<span>{isOnline ? '🟢 Online' : '🔴 Offline'}</span>
								</div>
                            )}
                            </div>
                        </Col>
						{children && <div className='col-auto text-right'> {children}</div>}
					</Row>
					<Row>
						<Col>
							{
								menus && <>
									<ul className='nav nav-tabs nav-overflow header-tabs'>
										{
											menus.map((menu, index) => {
												return (
													<li key={index} className='nav-item'>
														<Link to={menu.url} className={`nav-link pb-3 pt-4 ${menu.active ? 'active' : ''}`}>
															{menu.name}
														</Link>
													</li>
												)
											})
										}
									</ul>
								</>
							}
						</Col>
					</Row>
				</div>
			</div>
		</nav>
	);
}

export default PageHeader;