import { API } from 'aws-amplify';
import React, { useState } from 'react';
import { Button, Card, Col, Container, Form, FormGroup, Row, Table } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import uniqid from 'uniqid';
import { createInviteUser } from '../../components/AuthLayout';
import FormLabel from '../../components/FormLabel';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { FormError, isValidEmail, isValidPhone } from '../../helpers';
import ReactGA from "react-ga4"

const usersByEmailQuery = /* GraphQL */ `
  query UsersByEmail($email: AWSEmail!) {
    usersByEmail(email: $email) {
      items {
        id
      }
    }
  }
`;

const InviteCreate = () => {

	ReactGA.send({
		hitType: "pageview",
		page: "/user/invite/create",
	})

	const navigate = useNavigate()
	const [spinner, showSpinner] = useState(false);

	const [search, setSearch] = useState(true);
	const [user, setUser] = useState({ email: '', active: true, role: { admin: null } });
	const [errors, setErrors] = useState();

	const isFormValid = () => {
		const error = {};
		if (!user?.email) error.email = 'Please enter a valid email'
		if (!isValidEmail(user?.email?.trim())) error.email = 'Please enter a valid email'
		if (!isValidPhone(user.phone)) error.phone = 'Please enter a valid phone number'

		if (user.role.shipperGroup?.filter(x => x.id === 'NEW').length > 0) {
			toast.error('Please select a valid Shipper Group');
			error.shipperGroup = 'Please select a valid Shipper Group';
		}

		if (user.role.shipper?.filter(x => x.id === 'NEW').length > 0) {
			toast.error('Please select a valid Shipper');
			error.shipperGroup = 'Please select a valid Shipper';
		}

		if (user.role.carrier?.filter(x => x.id === 'NEW').length > 0) {
			toast.error('Please select a valid Courier');
			error.shipperGroup = 'Please select a valid Courier';
		}

		if (!search) {
			if (!(user?.role?.carrier?.length > 0 || user?.role?.shipper?.length > 0 || user?.role?.shipperGroup?.length > 0 || user?.role?.admin !== null)) {
				toast.error('Please choose at least one role for the user.');
				error.roles = 'Please choose at least one role for the user.';
			}
		}

		setErrors(error);
		return Object.keys(error).length === 0;
		// return false
	};

	const sendInvite = async (e) => {
		e.preventDefault();
		if (isFormValid()) {
			showSpinner(true);
			try {
				let input = {
					id: uniqid(),
					email: user.email,
					phone: user.phone,
					role: JSON.stringify(user.role),
					active: true
				}
				await createInviteUser(input);
				toast.success(`Invite has been sent to ${user.email}`)
				navigate('/user/invite')
			} catch (error) {
				toast.error(error.message)
				console.log('error', error)
			}
			showSpinner(false);
		}
	};

	const searchUser = async () => {
		if (isFormValid()) {
			showSpinner(true)
			const { data } = await API.graphql({ query: usersByEmailQuery, variables: { email: user.email } });
			if (data.usersByEmail.items.length === 0) {
				setSearch(false);
			} else {
				navigate(`/user/${data.usersByEmail.items[0].id}/overview`)
			}
			showSpinner(false)
		}

	}

	const handleChange = (e) => setUser({ ...user, [e.target.name]: e.target.value })

	//#region Shipper Group
	const searchShipperGroup = async (keyword) => {
		try {
			let items = []
			const apiName = 'api';
			const path = `/search/shipper-group?size=1000&from=0`;

			let init = {
				body: {
					sort: [{ "name.keyword": { "order": "asc" } }],
					query: {
						bool: {
							should: [],
							must_not: [
								{
									match: {
										active: false
									}
								}
							]
						}
					}
				}
			};

			if (keyword) {
				init.body.query.bool.should = [{ "prefix": { "name": keyword.toLowerCase() } }]
			} else {
				init.body.match = { _index: "shipper-group" };
			}

			const { hits } = await API.post(apiName, path, init);
			const sourceData = hits?.hits?.map(item => item?._source) || [];
			sourceData.forEach(item => items.push({
				label: item.name,
				value: item.id
			}));

			return items

		} catch (error) {
			console.error('Error fetching courier data:', error);
		} finally {
			showSpinner(false);
		}
	};

	const addShipperGroupRole = (e) => {
		let shipperGroup = user.role?.shipperGroup ? [...user.role.shipperGroup] : [];
		shipperGroup.push({
			id: 'NEW',
			name: '',
			role: 'VIEWER'
		})
		setUser({ ...user, role: { ...user.role, shipperGroup: shipperGroup } });
	}

	const removeShipperGroupRole = (index) => {
		let shipperGroup = user.role?.shipperGroup ? [...user.role.shipperGroup] : [];
		shipperGroup.splice(index, 1);
		setUser({ ...user, role: { ...user.role, shipperGroup: shipperGroup } });
	}

	const handleShipperGroupRoleChange = (index, event, name) => {
		let shipperGroup = user.role?.shipperGroup ? [...user.role.shipperGroup] : [];
		let accessIds = shipperGroup.map((role) => role?.id)
		if (accessIds?.includes(event?.value)) {
			toast.error("Shipper group is already selected. Please choose a different one.")
		} else {
			if (name === 'id') {
				shipperGroup[index].id = event.value;
				shipperGroup[index].name = event.label;
			}
			if (name === 'role') shipperGroup[index].role = event;

			setUser({ ...user, role: { ...user.role, shipperGroup: shipperGroup } });
		}
	}
	//#endregion

	//#region Shipper
	const searchShipper = async (keyword) => {
		try {
			let items = []
			const apiName = 'api';
			const path = `/search/shipper?size=1000&from=0`;

			let init = {
				body: {
					sort: [{ "name.keyword": { "order": "asc" } }],
					query: {
						bool: {
							should: [],
							must_not: [
								{
									match: {
										active: false
									}
								}
							]
						}
					}
				}
			};

			if (keyword) {
				init.body.query.bool.should = [
					{
						"prefix": {
							"name": keyword.toLowerCase()
						}
					}
				]
			} else {
				init.body.match = { _index: "shipper" };
			}

			const { hits } = await API.post(apiName, path, init);
			const sourceData = hits?.hits?.map(item => item?._source) || [];
			sourceData.forEach(item => items.push({
				label: item.name,
				value: item.id
			}));

			return items

		} catch (error) {
			console.error('Error fetching courier data:', error);
		} finally {
			showSpinner(false);
		}
	};

	const addShipperRole = (e) => {
		let shipper = user.role?.shipper ? [...user.role.shipper] : [];
		shipper.push({
			id: 'NEW',
			name: '',
			role: 'VIEWER'
		})
		setUser({ ...user, role: { ...user.role, shipper: shipper } });
	}

	const removeShipperRole = (index) => {
		let shipper = user.role?.shipper ? [...user.role.shipper] : [];
		shipper.splice(index, 1);
		setUser({ ...user, role: { ...user.role, shipper: shipper } });
	}

	const handleShipperRoleChange = (index, event, name) => {
		let shipper = user.role?.shipper ? [...user.role.shipper] : [];
		let accessIds = shipper.map((role) => role?.id)
		if (accessIds?.includes(event?.value)) {
			toast.error("Shipper is already selected. Please choose a different one.")
		} else {
			if (name === 'id') {
				shipper[index].id = event.value;
				shipper[index].name = event.label;
			}
			if (name === 'role') shipper[index].role = event;
			setUser({ ...user, role: { ...user.role, shipper: shipper } });
		}
	}
	//#endregion

	//#region Carrier
	const searchCarrier = async (keyword) => {
		try {
			let items = []
			const apiName = 'api';
			const path = `/search/carrier?size=1000&from=0`;

			let init = {
				body: {
					sort: [{ "name.keyword": { "order": "asc" } }],
					query: {
						bool: {
							should: [],
							must_not: [
								{
									match: {
										active: false
									}
								}
							]
						}
					}
				}
			};

			if (keyword) {
				init.body.query.bool.should = [{ "prefix": { "name": keyword.toLowerCase() } }]
			} else {
				init.body.match = { _index: "carrier" };
			}

			const { hits } = await API.post(apiName, path, init);
			const sourceData = hits?.hits?.map(item => item?._source) || [];
			sourceData.forEach(item => items.push({
				label: item.name,
				value: item.id
			}));

			return items

		} catch (error) {
			console.error('Error fetching courier data:', error);
		} finally {
			showSpinner(false);
		}
	};

	const addCarrierRole = (e) => {
		let carrier = user.role?.carrier ? [...user.role.carrier] : [];
		carrier.push({
			id: 'NEW',
			name: '',
			role: 'VIEWER'
		})
		setUser({ ...user, role: { ...user.role, carrier: carrier } });
	}

	const removeCarrierRole = (index) => {
		let carrier = user.role?.carrier ? [...user.role.carrier] : [];
		carrier.splice(index, 1);
		setUser({ ...user, role: { ...user.role, carrier: carrier } });
	}

	const handleCarrierRoleChange = (index, event, name) => {
		let carrier = user.role?.carrier ? [...user.role.carrier] : [];
		let accessIds = carrier.map((role) => role?.id)
		if (accessIds?.includes(event?.value)) {
			toast.error("Courier is already selected. Please choose a different one.")
		} else {
			if (name === 'id') {
				carrier[index].id = event.value;
				carrier[index].name = event.label;
			}
			if (name === 'role') carrier[index].role = event;
			setUser({ ...user, role: { ...user.role, carrier: carrier } });
		}
	}
	//#endregion

	return (
		<>
			<PageHeader name='Invite User' className='container' />
			<Spinner display={spinner}>
				<Container>
					{
						search === true && <section id='search'>
							<FormGroup className='mt-4'>
								<FormLabel required>Email</FormLabel>
								<Form.Control type='email' name='email' placeholder='e.g. john@email.com' onChange={(e) => setUser({ ...user, email: e.target.value.toLowerCase()?.trim() })} value={user?.email} />
								<FormError error={errors?.email} />
							</FormGroup>
							<Button className='btn btn-dark mt-3' onClick={searchUser}>Continue</Button>
						</section>
					}

					{
						search === false && <>
							<section id='roles'>
								<Row>
									<Col>
										<FormGroup className='mt-3'>
											<FormLabel required>Email</FormLabel>
											<Form.Control type='email' name='email' placeholder='e.g. john@email.com' onChange={(e) => setUser({ ...user, email: e.target.value })} value={user?.email} disabled />
											<FormError error={errors?.email} />
										</FormGroup>
									</Col>
									{/* <Col>
										<FormGroup className='mt-3'>
											<FormLabel>Phone</FormLabel>
											<PhoneInput country='us' onlyCountries={['us']} value={user?.phone} placeholder='e.g. 998-776-5543' onChange={handleChange} />
											<FormError error={errors?.phone} />
										</FormGroup>
									</Col> */}
								</Row>
							</section>

							<section id='admin-role' className='mt-4'>
								<Card>
									<Card.Header>Admin Role</Card.Header>
									<Card.Body>
										<button className={`btn btn-${!user.role?.admin ? 'danger' : 'light'} me-2`}
											onClick={() => setUser({ ...user, role: { ...user.role, admin: null } })}>
											No Access
										</button>

										<button className={`btn btn-${user.role?.admin === 'OWNER' ? 'success' : 'light'} me-2`}
											onClick={() => setUser({ ...user, role: { ...user.role, admin: 'OWNER' } })}>
											Owner
										</button>

										<button className={`btn btn-${user.role?.admin === 'EDITOR' ? 'success' : 'light'} me-2`}
											onClick={() => setUser({ ...user, role: { ...user.role, admin: 'EDITOR' } })}>
											Editor
										</button>

										<button className={`btn btn-${user.role?.admin === 'VIEWER' ? 'success' : 'light'} me-2`}
											onClick={() => setUser({ ...user, role: { ...user.role, admin: 'VIEWER' } })}>
											Viewer
										</button>
									</Card.Body>
								</Card>
							</section>

							<section id='shipper-group' className='mt-4'>
								<Card>
									<Card.Header>
										<Row className='align-items-center'>
											<Col>Shipper Group Role</Col>
											<Col className='text-end'>
												<Button variant='outline-secondary' size='sm' onClick={addShipperGroupRole}><i className='fe fe-plus mx-2'></i>Add More</Button>
											</Col>
										</Row>
									</Card.Header>
									<Card.Body className='p-0'>
										<Table className='table-sm table-fixed mb-0'>
											<thead>
												<tr>
													<th className='text-start'>Name</th>
													<th>Role</th>
												</tr>
											</thead>
											<tbody className='list font-size-base'>
												{
													user.role?.shipperGroup?.map((item, index) => (
														<tr key={index}>
															<td>
																<AsyncSelect key={index} value={{ label: item.name || 'Choose Shipper Group', value: item.id }}
																	type='text' loadOptions={searchShipperGroup} defaultOptions
																	onChange={(e) => handleShipperGroupRoleChange(index, e, 'id')} />
															</td>
															<td>
																<button className={`btn btn-${user.role?.shipperGroup[index].role === 'OWNER' ? 'success' : 'light'} me-2`}
																	onClick={() => handleShipperGroupRoleChange(index, 'OWNER', 'role')}>
																	Owner
																</button>

																<button className={`btn btn-${user.role?.shipperGroup[index].role === 'EDITOR' ? 'success' : 'light'} me-2`}
																	onClick={() => handleShipperGroupRoleChange(index, 'EDITOR', 'role')}>
																	Editor
																</button>

																<button className={`btn btn-${user.role?.shipperGroup[index].role === 'VIEWER' ? 'success' : 'light'} me-2`}
																	onClick={() => handleShipperGroupRoleChange(index, 'VIEWER', 'role')}>
																	Viewer
																</button>
																<button className='btn btn-light' onClick={() => removeShipperGroupRole(index)}>
																	<i className='fe fe-trash'></i>
																</button>
															</td>
														</tr>
													))
												}
											</tbody>
										</Table>
									</Card.Body>
								</Card>
							</section>

							<section id='shipper' className='mt-4'>
								<Card>
									<Card.Header>
										<Row className='align-items-center'>
											<Col>Shipper Role</Col>
											<Col className='text-end'>
												<Button variant='outline-secondary' size='sm' onClick={addShipperRole}><i className='fe fe-plus mx-2'></i>Add More</Button>
											</Col>
										</Row>
									</Card.Header>
									<Card.Body className='p-0'>
										<Table className='table-sm mb-0'>
											<thead>
												<tr>
													<th className='text-start'>Name</th>
													<th>Role</th>
													<th></th>
												</tr>
											</thead>
											<tbody className='list font-size-base'>
												{
													user.role?.shipper?.map((item, index) => (
														<tr key={index}>
															<td>
																<AsyncSelect key={index} value={{ label: item.name || 'Choose Shipper', value: item.id }}
																	type='text' loadOptions={searchShipper} defaultOptions
																	onChange={(e) => handleShipperRoleChange(index, e, 'id')} />
															</td>
															<td>
																<button className={`btn btn-${user.role?.shipper[index].role === 'OWNER' ? 'success' : 'light'} me-2`}
																	onClick={() => handleShipperRoleChange(index, 'OWNER', 'role')}>
																	Owner
																</button>

																<button className={`btn btn-${user.role?.shipper[index].role === 'EDITOR' ? 'success' : 'light'} me-2`}
																	onClick={() => handleShipperRoleChange(index, 'EDITOR', 'role')}>
																	Editor
																</button>

																<button className={`btn btn-${user.role?.shipper[index].role === 'VIEWER' ? 'success' : 'light'} me-2`}
																	onClick={() => handleShipperRoleChange(index, 'VIEWER', 'role')}>
																	Viewer
																</button>
																<button className='btn btn-light' onClick={() => removeShipperRole(index)}>
																	<i className='fe fe-trash'></i>
																</button>
															</td>
														</tr>
													))
												}
											</tbody>
										</Table>
									</Card.Body>
								</Card>
							</section>

							<section id='carrier' className='mt-4'>
								<Card>
									<Card.Header>
										<Row className='align-items-center'>
											<Col>Courier Role</Col>
											<Col className='text-end'>
												<Button variant='outline-secondary' size='sm' onClick={addCarrierRole}><i className='fe fe-plus mx-2'></i>Add More</Button>
											</Col>
										</Row>
									</Card.Header>
									<Card.Body className='p-0'>
										<Table className='table-sm mb-0'>
											<thead>
												<tr>
													<th className='text-start'>Name</th>
													<th>Role</th>
													<th></th>
												</tr>
											</thead>
											<tbody className='list font-size-base'>
												{
													user.role?.carrier?.map((item, index) => (
														<tr key={index}>
															<td>
																<AsyncSelect key={index} value={{ label: item.name || 'Choose Courier', value: item.id }}
																	type='text' loadOptions={searchCarrier} defaultOptions
																	onChange={(e) => handleCarrierRoleChange(index, e, 'id')} />
															</td>
															<td>
																<button className={`btn btn-${user.role?.carrier[index].role === 'OWNER' ? 'success' : 'light'} me-2`}
																	onClick={() => handleCarrierRoleChange(index, 'OWNER', 'role')}>
																	Owner
																</button>

																<button className={`btn btn-${user.role?.carrier[index].role === 'EDITOR' ? 'success' : 'light'} me-2`}
																	onClick={() => handleCarrierRoleChange(index, 'EDITOR', 'role')}>
																	Editor
																</button>

																<button className={`btn btn-${user.role?.carrier[index].role === 'VIEWER' ? 'success' : 'light'} me-2`}
																	onClick={() => handleCarrierRoleChange(index, 'VIEWER', 'role')}>
																	Viewer
																</button>
																<button className='btn btn-light' onClick={() => removeCarrierRole(index)}>
																	<i className='fe fe-trash'></i>
																</button>
															</td>
														</tr>
													))
												}
											</tbody>
										</Table>
									</Card.Body>
								</Card>
							</section>

							<div className='mt-3'>
								<Button type='submit' className='btn btn-dark' onClick={sendInvite}>Send Invite</Button>
							</div>
						</>
					}
				</Container>
			</Spinner>
		</>
	);
};

export default InviteCreate;