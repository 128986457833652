/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Table } from 'react-bootstrap';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { StatCard } from '../../components/Card';
import { CarrierFilter, CarrierFilterFromDeliveryService, DateFilter, ShipperFilter, ShipperGroupFilter } from '../../components/EntityFilter';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { TableClearFilter, TableKeywordSearch, TablePagination, TimeZoneFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, getTZ, handleApiError, toLocalDate, toLocalTime } from '../../helpers';

const RatingDashboard = () => {

	const navigate = useNavigate();

	const [spinner, showSpinner] = useState(false);
	const [ratings, setRatings] = useState([])
	const [resetDate, setRestDate] = useState(0);
	const [keyword, setKeyword] = useState('')
	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();
	const [ratingCount, setRatingCount] = useState(0)
	const [shipmentCount, setShipmentCount] = useState(0)
	const [ratingAverage, setRatingAverage] = useState(0)
	const [npsAverage, setNpsAverage] = useState(0)
	const [facets, setFacets] = useState({})
	const [shipperGroup, setShipperGroup] = useState()
	const [shipper, setShipper] = useState()
	const [carrier, setCarrier] = useState()
	const [timeZone, setTimeZone] = useState("America/New_York");
	const [tz, setTz] = useState("EST");

	const [dateFilters, setDateFilters] = useState({
		fromDate: moment().tz(timeZone)?.subtract('days').startOf('day').unix(),
		toDate: moment().tz(timeZone)?.endOf('day').unix()
	});


	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/feedback",
		})
	}, [])
	useEffect(() => {
		const getTimeZone = async () => {
			const timez = await getTZ(shipperGroup?.value, shipper?.value, carrier?.value);
			if (timez) {
				setTimeZone(timez?.id);
				setTz(timez?.alias);
			} else {
				setTimeZone("America/New_York")
				setTz("EST")
			}
		};
		if (shipperGroup?.value || shipper?.value || carrier?.value) getTimeZone();
		if (!shipperGroup || !shipper || !carrier) {
			setTimeZone("America/New_York");
			setTz("EST");
		}
	}, [shipperGroup?.value, shipper?.value, carrier?.value]);

	useEffect(() => {
		const delay = setTimeout(() => { setPageNumber(0); getElasticRatings(); }, DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [keyword, dateFilters, shipperGroup, shipper, carrier])

	useEffect(() => {
		getElasticRatings();
	}, [pageNumber]);

	const getElasticRatings = async () => {
		showSpinner(true)
		try {
			const apiName = 'api';
			const path = `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
			let init = {
				body: {
					query: {
						bool: {
							must: [
								{
									match: { status: "DELIVERED" }
								}
							]
						}
					}
				}
			}

			if (!keyword) {
				init.body.query.bool.must.push({
					range: {
						"actualDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {
				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId"
				]
				if (+keyword) {
					fields.push("number")
				}
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (shipper) init.body.query.bool.must.push({ match: { "shipperId": shipper?.value } })
			if (shipperGroup) init.body.query.bool.must.push({ match: { "shipperGroupId": shipperGroup?.value } })
			if (carrier) init.body.query.bool.must.push({ match: { "carrierId": carrier?.value } })

			let FeedbackCountData = await API.post(apiName, path, init);
			setShipmentCount(FeedbackCountData?.hits?.total?.value)

			const shipmentInit = {
				body: {
					"aggs": {
						"shipper_group": {
							"terms": {
								"field": "shipperGroup.name.keyword",
								"size": 1000
							}
						},
						"shipper": {
							"terms": {
								"field": "shipper.name.keyword",
								"size": 1000
							}
						},
						"carrier": {
							"terms": {
								"field": "carrier.name.keyword",
								"size": 1000
							}
						}
					},
					sort: [
						{
							"_score": { "order": "desc" }
						},
						{
							"actualDeliveryTime": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],

					query: {
						bool: {
							must: [
								{
									match: { status: "DELIVERED" }
								},
								{
									"exists": {
										"field": "ratings.items.feedback"
									}
								}
							]
						}
					}
				}

			}

			if (!keyword) {
				shipmentInit.body.query.bool.must.push({
					range: {
						"actualDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {
				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId"
				]
				if (+keyword) {
					fields.push("number")
				}
				shipmentInit.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (shipperGroup) shipmentInit.body.query.bool.must.push({ match: { "shipperGroupId": shipperGroup?.value } })
			if (shipper) shipmentInit.body.query.bool.must.push({ match: { "shipperId": shipper?.value } })
			if (carrier) shipmentInit.body.query.bool.must.push({ match: { "carrierId": carrier?.value } })

			let data = await API.post(apiName, `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`, shipmentInit);
			setRatings(data.hits.hits)
			setFacets(data.aggregations)
			setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
			setRatingCount(data?.hits?.total?.value);


			if (data.hits.hits.length > 0) {
				let ratingAverage = 0;
				let npsAverage = 0;

				let ratingHitsLength = 0
				let npsHitsLength = 0
				let promoters = 0
				let detractors = 0

				for (var item of data.hits.hits) {
					if (item?._source?.ratings?.items[0]?.rating) {
						ratingHitsLength++
						ratingAverage += item?._source?.ratings?.items[0]?.rating
					}
					if (item?._source?.ratings?.items[0]?.nps) {
						npsHitsLength++
						if (item?._source?.ratings?.items[0]?.nps > 8) {
							promoters++
						} else if (item?._source?.ratings?.items[0]?.nps < 7) {
							detractors++
						}
					}
				}

				ratingAverage = (ratingAverage / ratingHitsLength).toFixed(2);
				ratingAverage = !isNaN(ratingAverage) ? ratingAverage : 0;
				setRatingAverage(ratingAverage);

				npsAverage = ((promoters - detractors) / npsHitsLength) * 100
				npsAverage = !isNaN(npsAverage) ? npsAverage : 0;
				setNpsAverage(Math.round(npsAverage));
			} else {
				setRatingAverage(0);
				setNpsAverage(0);
			}

			showSpinner(false);
		} catch (error) {
			showSpinner(false)
			handleApiError(error)
		}

	}

	const clearFilters = () => {
		setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
		setDateFilters({
			fromDate: moment().tz('America/New_York')?.subtract('days').startOf('day').unix(),
			toDate: moment().tz('America/Los_Angeles')?.endOf('day').unix()
		});
		setShipperGroup(null);
		setShipper(null);
		setKeyword('');
		setCarrier(null)
		setPageNumber(0);
		setTimeZone("America/New_York");
		setTz("EST");
	};
	const tzHandle = (e) => {
		setTimeZone(e);
	};
	return (
		<>
			<PageHeader name='Ratings'>
				<Row>
					<ShipperGroupFilter value={shipperGroup} onChange={setShipperGroup} setTimeZone={setTimeZone} feedbackStatus={true} />
					<ShipperFilter value={shipper} onChange={setShipper} shipperGroup={shipperGroup} setTimeZone={setTimeZone} feedbackStatus={true} />
					{shipper && <CarrierFilterFromDeliveryService value={carrier} onChange={setCarrier} shipper={shipper} shipperGroup={shipperGroup} />}
					{!shipper && <CarrierFilter value={carrier} onChange={setCarrier} shipper={shipper} shipperGroup={shipperGroup} setTimeZone={setTimeZone} feedbackStatus={true} />}
					<DateFilter key={resetDate} onChange={setDateFilters} />

					<TimeZoneFilter

						title={"Time Zone"}
						setTimeZone={setTimeZone}
						dark={true}
						onChange={tzHandle}
						tz={tz}
						setTz={setTz}
					/>
					<TableClearFilter onClick={clearFilters} startOf={6} styleName={"mt-4"} />
				</Row>
			</PageHeader>

			<Container fluid>
				<Row>
					<StatCard title='Response Rate' value={(ratingCount > 0 && shipmentCount > 0) ? ((ratingCount / shipmentCount) * 100).toFixed(2) : 0} icon='percent' />
					<StatCard title='Average Rating' value={ratingAverage ?? '-'} icon='star' />
					<StatCard title='Average NPS' value={npsAverage ?? '-'} icon='thumbs-up' />
				</Row>

				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm' className='mb-0' >
							<thead>
								<tr>
									<th className='text-center'>Shipment #</th>
									<th style={{ wordWrap: "break-word", maxWidth: "200px" }}>Patient / Feedback</th>
									<th className='text-start'>Shipper</th>
									<th className='text-center'>Rating</th>
									<th className='text-center'>NPS</th>
									<th className='text-center'>Date</th>
								</tr>
							</thead>
							<tbody>
								{
									ratings && ratings.map((rating) => {
										return (
											<>
												{(rating?._source?.ratings?.items[0]?.feedback || rating?._source?.ratings?.items[0]?.nps || rating?._source?.ratings?.items[0]?.rating) &&
													< tr key={rating?._source?._source?.id} onClick={() => navigate(`/shipment/${rating?._source?.id}`)} style={{ cursor: 'pointer' }}>
														<td className='text-center'>{rating?._source?.number}</td>
														<td style={{ wordWrap: "break-word", maxWidth: "200px" }}>
															{rating?._source?.ratings?.items[0]?.feedback || '-'}
															<div className='text-small text-muted text-wrap'>{rating?._source?.shipTo?.name}</div>
														</td>
														<td>
															{rating?._source.shipper?.name}
															<div className='small text-muted'>
																{rating?._source?.shipperGroup?.name}
															</div>
														</td>
														<td className='text-center tables-handle' style={{ width: '118px' }} >
															<StarRatings rating={rating?._source?.ratings?.items[0]?.rating || 0} starRatedColor='#FFCD3C' starDimension='14px' starSpacing='1px' />
														</td>
														<td className='text-center tables-handle d-flex align-items-center justify-content-center'>
															{rating?._source?.ratings?.items[0]?.nps ? <div className={`nps nps-${rating?._source?.ratings?.items[0]?.nps * 10}`}></div> : '-'}
														</td>
														<td className='text-center'>
															{toLocalTime(rating?._source?.createdTime, rating?._source?.shipper?.timezone?.id)}
															<div className='small text-muted'>{toLocalDate(rating?._source?.createdTime, rating?._source?.shipper?.timezone?.id)}</div>
														</td>
													</tr >
												}
											</>
										)
									})
								}
							</tbody>

							<TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={7} />
						</Table>
					</Spinner>
				</Card>
			</Container >
		</>
	);
};

export default RatingDashboard;
