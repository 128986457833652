/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Table } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import uniqid from 'uniqid';
import { createInviteUser } from '../../components/AuthLayout';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { InviteStatusBadge } from '../../components/StatusBadge';
import { TableClearFilter, TableKeywordSearch } from '../../components/TableFilter';
import { DELAY_TIMEOUT, handleApiError, isOwner } from '../../helpers';
import ReactGA from "react-ga4"

export const deleteInviteUser = /* GraphQL */ `
  mutation DeleteInviteUser(
    $input: DeleteInviteUserInput!) {
    deleteInviteUser(input: $input) {
      id
      email
    }
  }
`;

const InviteList = () => {

	const [invites, setInvites] = useState([]);
	const [spinner, showSpinner] = useState(true);
	const [keyword, setKeyword] = useState('')

	const userRole = useSelector((state) => state.slice.USER.role)

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/user/invite",
		})
	}, [])

	useEffect(() => {
		const delay = setTimeout(() => getUsers(), DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [keyword])

	const getUsers = async () => {
		showSpinner(true);
		try {
			const apiName = "api"
			const path = "/search/invite-user"
			let init = {
				"body": {
					"sort": [
						{
							"_score": { "order": "desc" }
						},
						{
							"createdTime": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],
					"query": {
						"bool": {
							"must": []
						}
					}
				}
			}

			if (keyword) {
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": ["email"]
						}
					}
				)
			}

			const { hits } = await API.post(apiName, path, init)
			let inviteUsers = hits.hits.map((item) => item?._source)
			setInvites(inviteUsers);
			showSpinner(false)
		} catch (error) {
			handleApiError(error)
			showSpinner(false)
		}

	};

	const clearFilters = () => {
		setKeyword('');
	};

	const menus = [
		{ 'name': 'Active', 'url': `/user`, 'active': false },
		{ 'name': 'Invites', 'url': `/user/invite`, 'active': true }
	]

	const resendInvite = async (data) => {
		showSpinner(true);
		await API.graphql({ query: deleteInviteUser, variables: { input: { id: data?.id } } })
		try {
			let input = {
				id: uniqid(),
				email: data.email,
				phone: data.phone,
				role: data.role,
				active: true
			}
			await createInviteUser(input);
			toast.success(`Invite has been sent to ${data.email}`)
		} catch (error) {
			toast.error(error.message)
			console.log('error', error)
		}
		showSpinner(false);
	}


	return (
		<>
			<PageHeader name='Users' pageTitle={"Invited User List"} menus={menus}>
				{isOwner(userRole) && <Link className='btn btn-dark' to={`/user/invite/create`}>Invite User</Link>}
				<Button className='btn btn-light ms-2' onClick={getUsers}><i className='fe fe-refresh-cw'></i></Button>
			</PageHeader>
			<Container fluid>
				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
							<TableClearFilter onClick={clearFilters} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm'>
							<thead>
								<tr>
									<th>Email</th>
									<th className='text-center'>Invite Status</th>
									<th className='text-center'>Invite Date</th>
									<th className='text-center'>Resend Invite</th>
								</tr>
							</thead>
							<tbody>
								{
									invites?.map((invite, index) => (
										<tr key={index}>
											<td>{invite?.email}</td>
											<td className='text-center'>
												<InviteStatusBadge status={invite?.active ? 'Invite Sent' : 'Accepted'} />
											</td>
											<td className='text-center'>
												{
													invite?.createdAt && <>
														{moment(invite?.createdAt).format('h:mm a z')}
														<div className='small text-muted'>{moment(invite?.createdAt).format('MMM D, YYYY')}</div>
													</>
												}
											</td>
											{
												invite?.active ?
													<td className='cursor-pointer text-center' onClick={() => resendInvite(invite)}>
														<button className='btn btn-light btn-sm '>
															<i className='fe fe-send'></i> Resend
														</button>
													</td>
													: <td></td>
											}
										</tr>
									))
								}
							</tbody>
						</Table>
					</Spinner>
				</Card>
			</Container>
		</>
	);
};

export default InviteList;