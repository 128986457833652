/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { PatientActiveFilter, TableKeywordSearch, TablePagination } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, PAGE_TITLE, handleApiError, isOwner, isViewer } from '../../helpers';
import { storeCarrier } from '../../stores/slice';
import ReactGA from "react-ga4"
import swal from 'sweetalert';
import toast from 'react-hot-toast';

const carrierUsersByCarrierId = /* GraphQL */ `
  query CarrierUsersByCarrierId(
    $carrierId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCarrierUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    carrierUsersByCarrierId(
      carrierId: $carrierId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        carrierId
        userId
        user{
          name
        }

      }
      nextToken
      __typename
    }
  }
`;

const updateCarrier = /* GraphQL */ `
  mutation UpdateCarrier(
    $input: UpdateCarrierInput!
    $condition: ModelCarrierConditionInput
  ) {
    updateCarrier(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

const deleteCarrierUser = /* GraphQL */ `
  mutation DeleteCarrierUser(
    $input: DeleteCarrierUserInput!
    $condition: ModelCarrierUserConditionInput
  ) {
    deleteCarrierUser(input: $input, condition: $condition) {
      id
      role
      carrierId
      userId
      user {
        id
        name
      }
    }
  }
`;
const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name   
    }
  }
`;

const CarrierList = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/courier",
    })
  }, [])

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myUserRole = useSelector((state) => state.slice.USER.role)


  const [spinner, showSpinner] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [carriers, setCarriers] = useState([]);
  const [active, setActive] = useState(true)

  useEffect(() => {
    const delay = setTimeout(() => { getElasticCourier(); }, DELAY_TIMEOUT)
    return () => clearTimeout(delay)
  }, [keyword, active, pageNumber]);

  const getElasticCourier = async () => {
    showSpinner(true);
    try {
      const apiName = 'api';
      const path = `/search/carrier?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;

      let init = {
        body: {
          sort: [{ "name.keyword": { "order": "asc" } }],
          query: {
            bool: {
              must: [],
              must_not: []
            }
          }
        }
      };

      if (active === true) init.body.query.bool.must_not.push({ match: { active: false } })
      if (active === false) init.body.query.bool.must.push({ match: { active: active } })

      if (keyword) {
        init.body.query = {
          "multi_match": {
            "query": keyword,
            "type": "phrase_prefix",
            "fields": ["name", "alias"]
          }
        };
      } else {
        init.body.match = { _index: "carrier" };
      }

      const { hits } = await API.post(apiName, path, init);
      const sourceData = hits?.hits?.map(item => item?._source) || [];
      setCarriers(sourceData);
      setPageCount(Math.ceil(hits.total.value / PAGE_SIZE));
    } catch (error) {
      console.error('Error fetching courier data:', error);
    } finally {
      showSpinner(false);
    }
  };

  const goToOverview = (carrier) => {
    dispatch(storeCarrier(carrier))
    navigate(`/courier/${carrier.id}/overview`)
  }

  const inactiveCarrier = async (carrier, active) => {
    swal({
      text: active ? `Are you sure you want to deactivate ${carrier?.name}` : `Are you sure you want to activate ${carrier?.name}?`,
      buttons: ['No', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        if (active) {
          // deactivate 
          try {
            showSpinner(true)
            const groupData = await API.graphql({ query: updateCarrier, variables: { input: { id: carrier?.id, active: false } } })
            if (groupData) {
              let removeUserMsg
              let nextToken = null
              let carrierUser = []
              //get the Users
              do {
                try {
                  const response = await API.graphql({ query: carrierUsersByCarrierId, variables: { carrierId: carrier?.id, limit: 1000, nextToken: nextToken } });
                  const { items, nextToken: newNextToken } = response.data.carrierUsersByCarrierId;
                  carrierUser = carrierUser.concat(items);
                  nextToken = newNextToken;
                } catch (error) {
                  console.error('Error fetching users:', error);
                  toast.error(`Oops! something went wrong while fetching the users`)
                  break;
                }
              } while (nextToken);
              // removing the users
              if (carrierUser?.length > 0) {
                removeUserMsg = toast.loading('Removing Carrier users')
                const deletePromises = carrierUser.map(async (user) => {
                  try {
                    await API.graphql({ query: deleteCarrierUser, variables: { input: { id: user?.id } } })
                    await API.graphql({ query: updateUser, variables: { input: { id: user?.userId, name: user?.user?.name } } })
                  } catch (error) {
                    toast.dismiss(removeUserMsg)
                    toast.error(`Oops! something went wrong while removing the user`)
                    console.error("API deleteCarrierUser failed : ", error);
                  }
                });
                await Promise.all(deletePromises);
                toast.dismiss(removeUserMsg)
              }
              toast.success(`${groupData?.data?.updateCarrier?.name} has been successfully deactivated`)
            }
            setTimeout(() => {
              getElasticCourier();
            }, 2000)
          } catch (error) {
            handleApiError(error)
            showSpinner(false)
          }
        } else {
          // activate
          try {
            showSpinner(true)
            const groupData = await API.graphql({ query: updateCarrier, variables: { input: { id: carrier?.id, active: true } } })
            if (groupData) {
              toast.success(`${groupData?.data?.updateCarrier?.name} has been successfully activated`)
              setTimeout(() => {
                getElasticCourier();
              }, 2000)
            }
          } catch (error) {
            handleApiError(error)
            showSpinner(false)
          }
        }
      }
    })
  }


  return (
    <>
      <PageHeader name='Couriers' pageTitle={"Courier List"}  >
        {!isViewer(myUserRole) && <Link className='btn btn-dark' to='/courier/add'>
          Add Couriers
        </Link>}
      </PageHeader>

      <Container fluid>
        <Card>
          <Card.Header>
            <TableKeywordSearch keyword={keyword} onChange={setKeyword} />
            <PatientActiveFilter active={active} onChange={setActive} />
          </Card.Header>
          <Spinner display={spinner}>
            <Table responsive size='sm' className='mb-0'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Alias</th>
                  <th>Location</th>
                  <th>status</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  carriers.map((carrier, index) =>
                    <tr key={index} className='border-bottom' onClick={() => goToOverview(carrier)} style={{ cursor: 'pointer' }} >
                      <td>{carrier.name}</td>
                      <td>{carrier.alias}</td>
                      <td>{carrier.address?.city}, {carrier.address?.state}</td>
                      <td>
                        {
                          carrier?.active === false ?
                            <span className={`badge bg-danger-soft`}>Disabled</span>
                            : <span className={`badge bg-success`}>Active</span>
                        }
                      </td>
                      <td className='text-center ' onClick={(e) => e.stopPropagation()}>
                        <div className='dropdown '>
                          <Link to='' className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                            <i className="fe fe-more-vertical" />
                          </Link>
                          <div className='dropdown-menu dropdown-menu-end'>
                            {carrier?.active !== false ?
                              <button className='dropdown-item' onClick={() => isOwner(myUserRole) && inactiveCarrier(carrier, true)}>  Archive </button>
                              : <button className='dropdown-item' onClick={() => isOwner(myUserRole) && inactiveCarrier(carrier, false)}>  Activate </button>
                            }
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                }
              </tbody>
              <TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={7} />
            </Table>
          </Spinner>
        </Card>
      </Container>
    </>
  )
}

export default CarrierList;