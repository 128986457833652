/* eslint-disable react-hooks/exhaustive-deps */

import { MapView } from '@aws-amplify/ui-react-geo';
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Card, ListGroup, Row } from 'react-bootstrap';
import ReactGA from "react-ga4";
import toast from 'react-hot-toast';
import awsConfig from "../../aws-exports";
import { CarrierFilter, DateFilter, ShipperFilter, ShipperGroupFilter, StatusFilter } from '../../components/EntityFilter';
import ListGroupItem from '../../components/ListGroupItem';
import { DriverMarker, DriverRouteMarker, ShipperMarker } from '../../components/MapMarker';
import Spinner from '../../components/Spinner';
import { MapToggleButton } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_TITLE, toLocalTime } from '../../helpers';

const colors = ['blue', 'purple', 'red', 'green', 'orange', 'indigo', 'yellow', 'pink', 'teal', 'black'];

const usersByCarrierId = /* GraphQL */ `
  query UsersByCarrierId (
    $carrierId: ID!
    $nextToken: String
  ) {
    carrierUsersByCarrierId (
      carrierId: $carrierId
      nextToken: $nextToken
    ) {
      items {
      user {
        location {
          latitude
          longitude
          updatedAt
        }
        name
        id
      }
    }
    }
  }
`;

const onShipmentUpdate = /* GraphQL */ `
  subscription ShipmentSubscription ($filter: ModelSubscriptionShipmentFilterInput) {
    onUpdateShipment(filter: $filter) {
      id
	  driverId
      status
	}
  }
`;

const onShipmentRouteUpdate = /* GraphQL */ `
  subscription ShipmentRouteSubscription ($filter: ModelSubscriptionShipmentRouteFilterInput) {
    onUpdateShipmentRoute(filter: $filter) {
      id
	  routeSequence
      routeDistance
      routeDuration
	}
  }
`;

const onUserLocationUpdate = /* GraphQL */ `
  subscription UserLocationSubscription ($filter: ModelSubscriptionUserLocationFilterInput) {
    onUpdateUserLocation(filter: $filter) {
      	id
	  	latitude
    	longitude
    }
  }
`;

const Dispatch = () => {

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/dispatch",
		})
		document.title = `Dispatch ${PAGE_TITLE}`;
	}, [])


	const mapRef = useRef();
	const mapState = { latitude: 37.7061332, longitude: -101.4802194, zoom: 4 }

	const [spinner, showSpinner] = useState(false);
	const [shipments, setShipments] = useState([])
	const [shippers, setShippers] = useState({})
	const [drivers, setDrivers] = useState({})

	const [shipperGroup, setShipperGroup] = useState()
	const [shipper, setShipper] = useState()
	const [carrier, setCarrier] = useState()
	const [driver, setDriver] = useState()
	const [status, setStatus] = useState()
	const [mapStyle, setMapStyle] = useState(awsConfig.geo.amazon_location_service.maps.default);

	const [dateFilters, setDateFilters] = useState({
		fromDate: moment().tz('America/New_York').startOf('day').unix(),
		toDate: moment().tz('America/Los_Angeles').endOf('day').unix()
	});



	async function getDrivers(carrierId) {
		try {
			let res = await API.graphql({ query: usersByCarrierId, variables: { carrierId } })
			const carrierDrivers = res?.data?.carrierUsersByCarrierId?.items;
			let data = {}
			for (let item of carrierDrivers) {
				if (item.user) data[item.user.id] = item.user
			}
			setDrivers(data)
		} catch (error) {
			console.log(error)
		}
	}


	useEffect(() => {
		// API.graphql({ query: onShipmentUpdate }).subscribe({
		// 	next: (response) => {
		// 		const shipment = response?.value?.data?.onUpdateShipment;
		// 		if (shipments[shipment.id]) {
		// 			shipments[shipment.id].status = shipment.status
		// 			setShipments(shipments);
		// 		}
		// 	},
		// 	error: (error) => console.error('onShipmentUpdate', error),
		// });

		// API.graphql({ query: onShipmentRouteUpdate }).subscribe({
		// 	next: (response) => {
		// 		const route = response?.value?.data?.onUpdateShipmentRoute;
		// 		if (shipments[route.id]) {
		// 			shipments[route.id].route = route;
		// 			setShipments(shipments);
		// 		}
		// 	},
		// 	error: (error) => console.error('onShipmentRouteUpdate', error),
		// });

		// API.graphql({ query: onUserLocationUpdate }).subscribe({
		// 	next: (response) => {
		// 		const driver = response?.value?.data?.onUpdateUserLocation;
		// 		// const myDrivers = drivers;
		// 		// console.log('drivers', myDrivers)
		// 		// console.log('driver', driver)
		// 		// if (drivers[driver.id]) {
		// 		//	drivers[driver.id] = driver;
		// 		// setDrivers(drivers);
		// 		// }
		// 	},
		// 	error: (error) => console.error('onUserLocationUpdate', error)
		// });
	}, []);

	useEffect(() => {
		setDrivers([])
		const delay = setTimeout(() => getShipments(), DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [shipperGroup, shipper, carrier, driver, status, dateFilters]);

	async function getShipments() {
		let loader = toast.loading("Loading...")
		try {
			const apiName = 'api';
			const path = `/search/shipment?size=${0}&from=${0}`;
			let init = {
				body: {
					query: {
						bool: {
							must: [{
								range: {
									"expectedDeliveryTime": {
										"gte": dateFilters?.fromDate,
										"lte": dateFilters?.toDate
									}
								}
							}
							]
						}
					}
				}
			}

			if (shipper) init.body.query.bool.must.push({ match: { "shipper.name.keyword": shipper?.label } })
			if (shipperGroup) init.body.query.bool.must.push({ match: { "shipperGroup.name.keyword": shipperGroup?.label } })
			if (carrier) init.body.query.bool.must.push({ match: { "carrier.name.keyword": carrier?.label } })
			if (status) init.body.query.bool.must.push({ match: { "status": status } })
			if (driver) init.body.query.bool.must.push({ match: { "driverId": driver?.value } })

			let data = await API.post(apiName, path, init);
			let page = 0;
			let apiArgs = []
			while (page <= Math.ceil(data.hits.total.value / 1000)) {
				const path = `/search/shipment?size=${1000}&from=${page * 1000}`;
				apiArgs.push({ apiName, path, init });
				page++;
			}
			let promises = apiArgs.map(call => API.post(call.apiName, call.path, call.init));
			Promise.all(promises).then(res => {
				let items = []
				for (let item of res) {
					const sourceData = item?.hits?.hits?.map((item) => item?._source)
					items.push(...sourceData)
				}
				let shippers = {}
				let shipments = {}
				for (var item of items) {
					shipments[item.id] = item;
					if (item?.shipper?.id) shippers[item?.shipper.id] = item.shipper
				}

				setShippers(shippers);
				setShipments(shipments);
				toast.dismiss(loader)
			})
		} catch (error) {
			console.error(error);
			toast.dismiss(loader)
		}
	}
	useEffect(() => {
		if (carrier?.value) getDrivers(carrier?.value)
	}, [carrier])

	return (
		<>

			<MapView ref={mapRef} initialViewState={mapState} mapStyle={mapStyle}>
				{
					Object.values(shipments).filter(x => x.status === 'OUT_FOR_DELIVERY').map((shipment, index) => <DriverRouteMarker key={index} shipment={shipment} />)
				}

				{
					Object.keys(shippers).map((id, index) => <ShipperMarker key={index} shipper={shippers[id]} />)
				}

				{
					Object.values(drivers).map((driver, index) => <DriverMarker key={index} driver={driver} />)
				}
			</MapView>

			<div style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 2, backgroundColor: 'white', borderBottom: '1px solid #EDF2F9' }}>
				<Row className='px-4 py-2'>
					<Spinner display={spinner}>
						<ShipperGroupFilter value={shipperGroup} onChange={setShipperGroup} />
						{shipperGroup && <ShipperFilter value={shipper} shipperGroup={shipperGroup} onChange={setShipper} />}

						<CarrierFilter value={carrier} onChange={setCarrier} />
						<StatusFilter value={status} onChange={setStatus} />
						<DateFilter onChange={setDateFilters} />
					</Spinner>
				</Row>
			</div>

			<div style={{ position: 'fixed', top: 66, bottom: 0, left: 0, zIndex: 1, width: 400, overflow: 'scroll', backgroundColor: 'white' }}>
				<Spinner display={spinner}>
					<Accordion defaultActiveKey="0">
						{
							Object.values(drivers).map((driver, index) => {
								let outForDelivery = Object.values(shipments).filter(x => x.driverId === driver.id && x.status === 'OUT_FOR_DELIVERY').sort((a, b) => a?.route?.routeSequence - b?.route?.routeSequence);
								let lastStop = outForDelivery[outForDelivery.length - 1];
								let driverColor = `var(--bs-${colors[index % 10]}`
								return <>
									<Accordion.Item eventKey={index}>
										<Accordion.Header>
											<div className='row align-items-center w-100'>
												<div className='col-auto pe-0'>
													<span className='badge' style={{ backgroundColor: driverColor }}>&nbsp;&nbsp;</span>
												</div>
												<div className='col'>
													{driver.name}
												</div>
												{
													lastStop?.route &&
													<div className='col-auto'>
														<span className='badge bg-secondary-soft'>
															{lastStop?.route?.routeDuration} min
														</span>
													</div>
												}
											</div>
										</Accordion.Header>
										<Accordion.Body>
											<div className="list-group list-group-flush list-group-activity my-n3">
												{
													outForDelivery.map((shipment, index) => {
														return <div className="list-group-item" key={index}>
															<div className="row">
																<div className="col-auto">
																	<div className="avatar avatar-sm">
																		<div className="avatar-title fs-lg bg-primary-subtle rounded-circle">
																			{shipment.route?.routeSequence}
																		</div>
																	</div>
																</div>
																<div className="col ms-n2">
																	<h5 className="mb-1">
																		{shipment.shipTo.name}
																	</h5>
																	<p className="small text-gray-700 mb-0">
																		{shipment.shipTo.address.address1}
																	</p>
																	<p className="small text-gray-700 mb-0">
																		{shipment.shipTo.address.address2}
																	</p>
																	<p className="small text-gray-700 mb-0">
																		{shipment.shipTo.address.city}, {shipment.shipTo.address.state} {shipment.shipTo.address.postalCode}
																	</p>
																	<small className="text-body-secondary">
																		#{shipment.number} &nbsp;&nbsp; {shipment.route?.duration} min &nbsp;&nbsp; {shipment.route?.routeDistance} mi
																	</small>
																</div>
																<div className='col-auto'>
																	<span className='badge bg-secondary-soft'>
																		{toLocalTime(shipment.route?.arrival, shipment.shipFrom?.timezone?.id)}
																	</span>
																</div>
															</div>
														</div>
													})
												}
											</div>
										</Accordion.Body>
									</Accordion.Item>
									{/* <div className='list-group-item'>
											<div className='row align-items-center'>
												<div className='col-auto pe-0'>
													<span className='badge' style={{ backgroundColor: badgeColor }}>&nbsp;</span>
												</div>
												<div className='col'>
													<h5 className='mb-0'>{driver.name}</h5>
													<div className='row small text-muted'>
														<div className='col pe-0'>{outForDelivery.length} stop</div>
														<div className='col pe-0'>{lastStop?.route.routeDuration || 0} min</div>
														<div className='col pe-0'>{lastStop?.route.routeDistance || 0} mi</div>
													</div>
												</div>
											</div>
										</div> */}
								</>
							})
						}
					</Accordion>
				</Spinner>
			</div>

			<div style={{ position: 'fixed', top: 66, right: 0, zIndex: 1, width: 200 }}>
				<Card>
					<Spinner display={spinner}>
						<ListGroup className='list-group-flush p-3 py-0'>
							<ListGroupItem name='Open' value={Object.values(shipments).filter(x => x.status === 'OPEN').length} />
							<ListGroupItem name='Ready for Pickup' value={Object.values(shipments).filter(x => x.status === 'READY_FOR_PICKUP' || x.status === 'OPEN').length} />
							<ListGroupItem name='In Transit' value={Object.values(shipments).filter(x => x.status === 'IN_TRANSIT').length} />
							<ListGroupItem name='Out for Delivery' value={Object.values(shipments).filter(x => x.status === 'OUT_FOR_DELIVERY').length} />
							<ListGroupItem name='Delivered' value={Object.values(shipments).filter(x => x.status === 'DELIVERED').length} />
							<ListGroupItem name='Exceptions / Returns' value='0' />
						</ListGroup>
					</Spinner>
				</Card>

			</div>

			<div className="" style={{ position: 'fixed', top: '90vh', right: '25px', zIndex: 2 }}>
				<MapToggleButton mapStyle={mapStyle} setMapStyle={setMapStyle} />
			</div>
		</>
	)
}

export default Dispatch;