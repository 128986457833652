import { Image, View } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { useEffect } from "react";
import { Container } from 'react-bootstrap';
import { Outlet } from "react-router-dom";

export const getUserById = /* GraphQL */ `
query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      title
      company
      phone
      email
	    image
      active
      role
        shippers {
          items {
            role
            shipper {
              id
              name
              shipperGroupId
              address {
                location {
                  latitude
                  longitude
                }
              }
		        }
          }
        }
    }
}`;

export const getInviteUser = /* GraphQL */ `
  query GetInviteUser($id: ID!) {
    getInviteUser(id: $id) {
      id
      phone
      email
      role
      active
      createdAt
      updatedAt
    }
  }
`;

export const createUserMutation = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

export const createUserLocationMutation = /* GraphQL */ `
  mutation CreateUserLocation($input: CreateUserLocationInput!) {
    createUserLocation(input: $input) {
      id
    }
  }
`;

export const createShipperGroupUserMutation = /* GraphQL */ `
  mutation CreateShipperGroupUser($input: CreateShipperGroupUserInput!) {
    createShipperGroupUser(input: $input) {
      id
    }
  }
`;

export const createShipperUserMutation = /* GraphQL */ `
  mutation CreateShipperUser($input: CreateShipperUserInput!) {
    createShipperUser(input: $input) {
      id
    }
  }
`;

export const createCarrierUserMutation = /* GraphQL */ `
  mutation CreateCarrierUser($input: CreateCarrierUserInput!) {
    createCarrierUser(input: $input) {
      id
    }
  }
`;

export const createInviteUserMutation = /* GraphQL */ `
  mutation CreateInviteUser($input: CreateInviteUserInput!) {
    createInviteUser(input: $input) {
      id
    }
  }
`;

export const updateInviteUser = /* GraphQL */ `
  mutation UpdateInviteUser($input: UpdateInviteUserInput!) {
    updateInviteUser(input: $input) {
      id
    }
  }
`;

const imagePaths = {
  'Sign In': '/img/login-cover-new.png',
  'Forgot Password': '/img/forget.png',
  'Sign Up': '/img/forget.png',
};

export const CommonHeader = ({ title, subtitle, setImage }) => {
  useEffect(() => { setImage && setImage(imagePaths[title] || '/img/login-cover-new.png') }, [setImage, title]);
  return (
    <></>
  )
};


export const createShipperGroupUser = (input) => API.graphql({ query: createShipperGroupUserMutation, variables: { input: input } });
export const createShipperUser = (input) => API.graphql({ query: createShipperUserMutation, variables: { input: input } });
export const createCarrierUser = (input) => API.graphql({ query: createCarrierUserMutation, variables: { input: input } });
export const createInviteUser = (input) => API.graphql({ query: createInviteUserMutation, variables: { input: input } });


export const AuthLayout = () => {
  return (
    <section className="main-card-container d-flex align-items-center">
      <Container fluid className="login-container">
        <Outlet />
      </Container>
    </section>
  );
};

export default AuthLayout;