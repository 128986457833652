/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Card, Container, Table } from 'react-bootstrap';
import ReactGA from "react-ga4";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { PatientActiveFilter, TableKeywordSearch, TablePagination } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, handleApiError, isOwner, isViewer } from '../../helpers';
import { storeShipperGroup } from '../../stores/slice';

const updateShipperGroup = /* GraphQL */ `
  mutation UpdateShipperGroup($input: UpdateShipperGroupInput!) {
    updateShipperGroup(input: $input) {
      id
      name
    }
  }
`;

const shipperGroupUsersByShipperGroupId = /* GraphQL */ `
  query ShipperGroupUsersByShipperGroupId(
    $shipperGroupId: ID!
    $filter: ModelShipperGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipperGroupUsersByShipperGroupId(
      shipperGroupId: $shipperGroupId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        shipperGroupId
        userId
        user{
          name
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const deleteShipperGroupUser = /* GraphQL */ `
  mutation DeleteShipperGroupUser(
    $input: DeleteShipperGroupUserInput!
    $condition: ModelShipperGroupUserConditionInput
  ) {
    deleteShipperGroupUser(input: $input, condition: $condition) {
      id
   }
  }
`;

const shippersByShipperGroupId = /* GraphQL */ `
  query ShippersByShipperGroupId(
    $shipperGroupId: ID!
    $filter: ModelShipperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shippersByShipperGroupId(
      shipperGroupId: $shipperGroupId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
    }
  }
`;

const shipperUsersByShipperId = /* GraphQL */ `
  query ShipperUsersByShipperId(
    $shipperId: ID!
    $limit: Int
    $nextToken: String
  ) {
    shipperUsersByShipperId(
      shipperId: $shipperId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        shipperId
        userId
        user{
          name
        }
      }
      nextToken
    }
  }
`;

const updateShipper = /* GraphQL */ `
  mutation UpdateShipper(
    $input: UpdateShipperInput!
    $condition: ModelShipperConditionInput
  ) {
    updateShipper(input: $input, condition: $condition) {
      id
      name
    }
  }
`;


const deleteShipperUser = /* GraphQL */ `
mutation DeleteShipperUser(
  $input: DeleteShipperUserInput!
  $condition: ModelShipperUserConditionInput
) {
  deleteShipperUser(input: $input, condition: $condition) {
    id
    role
  }
}
`;

const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name   
    }
  }
`;


const ShipperGroupList = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myUserRole = useSelector((state) => state.slice.USER.role)

  const [spinner, showSpinner] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [shipperGroups, setShipperGroups] = useState([]);
  const [active, setActive] = useState(true)

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/shipper-group",
    })
  }, [])

  useEffect(() => {
    if (keyword) setPageNumber(0)
    const delay = setTimeout(() => { getShipperGroup(); }, DELAY_TIMEOUT)
    return () => clearTimeout(delay)
  }, [keyword, pageNumber, active])

  const getShipperGroup = async () => {
    showSpinner(true);
    try {
      const apiName = 'api';
      const path = `/search/shipper-group?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
      let init = {
        body: {
          sort: [{ "name.keyword": { "order": "asc" } }],
          query: {
            bool: {
              must: [],
              must_not: []
            }
          }
        }
      };

      if (active === true) init.body.query.bool.must_not.push({ match: { active: false } })
      if (active === false) init.body.query.bool.must.push({ match: { active: active } })

      if (keyword) {
        init.body.query = {
          "multi_match": {
            "query": keyword,
            "type": "phrase_prefix",
            "fields": ["name", "alias"]
          }
        };
      } else {
        init.body.match = { _index: "shipperGroup" };
      }
      const { hits } = await API.post(apiName, path, init);
      const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
      setShipperGroups(sourceData);
      setPageCount(Math.ceil(hits?.total?.value / PAGE_SIZE));
      showSpinner(false);

    } catch (error) {
      console.error('ealstic error', error);
      showSpinner(false);

    }
  }

  const goToOverview = (shipperGroup) => {
    dispatch(storeShipperGroup(shipperGroup))
    navigate(`/shipper-group/${shipperGroup.id}/overview`)
  }

  const inactiveShipperGroup = async (shipperGroup, active) => {
    swal({
      text: active
        ? `Are you sure you want to deactivate ${shipperGroup?.name}`
        : `Are you sure you want to activate ${shipperGroup?.name}?`,
      buttons: ['No', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (!status) return;
      try {
        showSpinner(true);
        // Handle deactivation
        if (active) {
          // get active Shippers 
          const { data } = await API.graphql({ query: shippersByShipperGroupId, variables: { shipperGroupId: shipperGroup?.id, filter: { active: { ne: false } }, }, limit: 1000, });
          // Archive all shippers 
          const allShippers = data?.shippersByShipperGroupId?.items || [];
          if (allShippers.length > 0) {
            const removeShipperMsg = toast.loading('Archiving all shipper locations');
            await Promise.all(
              allShippers.map((shipper) => inactiveShipper(shipper?.id))
            );
            toast.dismiss(removeShipperMsg);
          }
          let nextToken = null;
          let groupUsers = [];
          do {
            const response = await API.graphql({
              query: shipperGroupUsersByShipperGroupId,
              variables: { shipperGroupId: shipperGroup?.id, limit: 1000, nextToken },
            });
            const { items, nextToken: newNextToken } = response?.data?.shipperGroupUsersByShipperGroupId;
            groupUsers = groupUsers.concat(items);
            nextToken = newNextToken;
          } while (nextToken);

          // Remove group users in parallel if there are any
          if (groupUsers.length > 0) {
            const removeUserMsg = toast.loading('Removing shipper group users');
            await Promise.all(
              groupUsers.map((user) => {
                API.graphql({ query: deleteShipperGroupUser, variables: { input: { id: user?.id } } })
                API.graphql({ query: updateUser, variables: { input: { id: user?.userId, name: user?.user?.name } } })
              }
              )
            );
            toast.dismiss(removeUserMsg);
          }
          // Deactivate the shipper group
          const groupData = await API.graphql({ query: updateShipperGroup, variables: { input: { id: shipperGroup?.id, active: false } } });
          if (groupData) {
            toast.success(`${groupData?.data?.updateShipperGroup?.name} has been successfully deactivated`);
            getShipperGroup();
          }
        } else {
          // Handle activation
          const groupData = await API.graphql({ query: updateShipperGroup, variables: { input: { id: shipperGroup?.id, active: true } } });
          if (groupData) {
            toast.success(`${groupData?.data?.updateShipperGroup?.name} has been successfully activated`);
          }
        }
      } catch (error) {
        handleApiError(error);
      } finally {
        setTimeout(getShipperGroup, 1500);
      }
    });
  };


  const inactiveShipper = async (shipperId) => {
    try {
      showSpinner(true);
      // Update shipper 
      const groupData = await API.graphql({
        query: updateShipper,
        variables: { input: { id: shipperId, active: false } }
      });
      if (groupData) {
        let nextToken = null;
        let shipperUsers = [];
        // Fetch shipper users 
        do {
          try {
            const response = await API.graphql({
              query: shipperUsersByShipperId,
              variables: { shipperId: shipperId, limit: 1000, nextToken: nextToken }
            });

            const { items, nextToken: newNextToken } = response.data.shipperUsersByShipperId;
            shipperUsers = shipperUsers.concat(items);
            nextToken = newNextToken;

          } catch (error) {
            console.error('Error fetching users:', error);
            toast.error('Oops! Something went wrong while fetching the users.');
            break;
          }
        } while (nextToken);

        // Delete shipper 
        if (shipperUsers.length > 0) {
          const deletePromises = shipperUsers.map(async (user) => {
            try {
              await API.graphql({ query: deleteShipperUser, variables: { input: { id: user?.id } } });
              await API.graphql({ query: updateUser, variables: { input: { id: user?.userId, name: user?.user?.name } } })
            } catch (error) {
              console.error('Error deleting user:', error);
              toast.error('Oops! Something went wrong while removing a user.');
            }
          });
          await Promise.all(deletePromises);
        }
      }

    } catch (error) {
      handleApiError(error);
    }
  };


  return (
    <>
      <PageHeader name='Shipper Groups' pageTitle={"Shipper Group List"}>
        {!isViewer(myUserRole) && <Link className='btn btn-dark' to='/shipper-group/add'>
          Add Shipper Group
        </Link>}
      </PageHeader>

      <Container fluid>
        <Card>
          <Card.Header>
            <TableKeywordSearch keyword={keyword} onChange={setKeyword} />
            <PatientActiveFilter active={active} onChange={setActive} />
          </Card.Header>
          <Spinner display={spinner}>
            <Table responsive size='sm'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Alias</th>
                  <th>Location</th>
                  <th>status</th>
                  <th className='text-center'>Action</th>

                </tr>
              </thead>
              <tbody>
                {
                  shipperGroups.map((group, index) =>
                    <tr key={index} className='border-bottom' onClick={() => goToOverview(group)} style={{ cursor: 'pointer' }}>
                      <td>{group.name}</td>
                      <td>{group.alias}</td>
                      <td>{group.address?.city}, {group.address?.state}</td>
                      <td>
                        {
                          group?.active === false ?
                            <span className={`badge bg-danger-soft`}>Disabled</span>
                            : <span className={`badge bg-success`}>Active</span>
                        }
                      </td>
                      <td className='text-center ' onClick={(e) => e.stopPropagation()}>
                        <div className='dropdown '>
                          <Link to='' className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                            <i className="fe fe-more-vertical" />
                          </Link>
                          <div className='dropdown-menu dropdown-menu-end'>
                            {group?.active !== false ?
                              <button className='dropdown-item' onClick={() => isOwner(myUserRole) && inactiveShipperGroup(group, true)}>  Archive </button>
                              : <button className='dropdown-item' onClick={() => isOwner(myUserRole) && inactiveShipperGroup(group, false)}>  Activate </button>
                            }
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                }
              </tbody>

              <TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={7} />
            </Table>
          </Spinner>
        </Card>
      </Container >
    </>
  )
}

export default ShipperGroupList;