/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { PatientActiveFilter, TableKeywordSearch, TablePagination } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, handleApiError, isOwner, isViewer } from '../../helpers';
import { storeShipper } from '../../stores/slice';
import swal from 'sweetalert';
import toast from 'react-hot-toast';

const shipperUsersByShipperId = /* GraphQL */ `
  query ShipperUsersByShipperId(
    $shipperId: ID!
    $limit: Int
    $nextToken: String
  ) {
    shipperUsersByShipperId(
      shipperId: $shipperId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        shipperId
        userId
        user{
          name
        }
      }
      nextToken
    }
  }
`;
const updateShipper = /* GraphQL */ `
  mutation UpdateShipper(
    $input: UpdateShipperInput!
    $condition: ModelShipperConditionInput
  ) {
    updateShipper(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

const deleteShipperUser = /* GraphQL */ `
mutation DeleteShipperUser(
  $input: DeleteShipperUserInput!
  $condition: ModelShipperUserConditionInput
) {
  deleteShipperUser(input: $input, condition: $condition) {
    id
    role
  }
}
`;

const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name   
    }
  }
`;

const ShipperList = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { shipperGroupId } = useParams()
  const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP)
  const myUserRole = useSelector((state) => state.slice.USER.role)

  const [spinner, showSpinner] = useState(true);
  const [keyword, setKeyword] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [shippers, setShippers] = useState([]);
  const [active, setActive] = useState(true)

  const menus = [
    { 'name': 'Overview', 'url': `/shipper-group/${shipperGroupId}/overview`, 'active': false },
    { 'name': 'Shippers', 'url': `/shipper-group/${shipperGroupId}/shipper`, 'active': true },
  ]

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/shipper-group/${shipperGroupId}/shipper`,
    })
  }, [])

  useEffect(() => {
    if (keyword) setPageNumber(0);
    const delay = setTimeout(() => { getShippers() }, DELAY_TIMEOUT)
    return () => clearTimeout(delay)
  }, [keyword, pageNumber, active])

  const getShippers = async () => {
    showSpinner(true);
    try {
      const apiName = 'api';
      const path = `/search/shipper?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
      let init = {
        body: {
          query: {
            bool: {
              must: [
                {
                  match: {
                    shipperGroupId: shipperGroupId
                  }
                }
              ],
              must_not: []
            }
          }
        },
      };

      if (active === true) init.body.query.bool.must_not.push({ match: { active: false } })
      if (active === false) init.body.query.bool.must.push({ match: { active: active } })

      if (!keyword) {
        init.body.sort = [{ "name.keyword": { "order": "asc" } }]
      }
      if (keyword) {
        init.body.query.bool.must.push(
          {
            "multi_match": {
              "query": keyword,
              "type": "phrase_prefix",
              "fields": ["name", "alias"]
            }
          })
      } else {
        init.body.match = { _index: "shipperGroup" };
      }
      const { hits } = await API.post(apiName, path, init);
      const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
      setShippers(sourceData);
      setPageCount(Math.ceil(hits?.total?.value / PAGE_SIZE));
      showSpinner(false);

    } catch (error) {
      console.error('ealstic error', error);
      showSpinner(false);

    }
  }

  const goToOverview = (shipper) => {
    dispatch(storeShipper(shipper))
    navigate(`/shipper-group/${shipperGroupId}/shipper/${shipper.id}/overview`)
  }

  const inactiveShipper = async (shipper, active) => {
    swal({
      text: active ? `Are you sure you want to deactivate ${shipper?.name}` : `Are you sure you want to activate ${shipper?.name}?`,
      buttons: ['No', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        if (active) {
          // deactivate 
          try {
            showSpinner(true)
            const groupData = await API.graphql({ query: updateShipper, variables: { input: { id: shipper?.id, active: false } } })
            if (groupData) {
              let removeUserMsg = null
              let nextToken = null
              let shipperUsers = []
              //get the Users
              do {
                try {
                  const response = await API.graphql({ query: shipperUsersByShipperId, variables: { shipperId: shipper?.id, limit: 1000, nextToken: nextToken } });
                  const { items, nextToken: newNextToken } = response.data.shipperUsersByShipperId;
                  shipperUsers = shipperUsers.concat(items);
                  nextToken = newNextToken;
                } catch (error) {
                  console.error('Error fetching users:', error);
                  toast.error(`Oops! something went wrong while fetching the users`)
                  break;
                }
              } while (nextToken);
              // removing the users
              if (shipperUsers.length > 0) {
                removeUserMsg = toast.loading('Removing shipper users')
                const deletePromises = shipperUsers.map(async (user) => {
                  try {
                    await API.graphql({ query: deleteShipperUser, variables: { input: { id: user?.id } } });
                    await API.graphql({ query: updateUser, variables: { input: { id: user?.userId, name: user?.user?.name } } })
                  } catch (error) {
                    console.error('Error deleting user:', error);
                    toast.error('Oops! Something went wrong while removing a user.');
                  }
                });
                await Promise.all(deletePromises);
                toast.dismiss(removeUserMsg)
              }
              toast.success(`${groupData?.data?.updateShipper?.name} has been successfully deactivated`)
            }
            getShippers();
          } catch (error) {
            handleApiError(error)
            showSpinner(false)
          }
        } else {
          // activate
          try {
            showSpinner(true)
            const groupData = await API.graphql({ query: updateShipper, variables: { input: { id: shipper?.id, active: true } } })
            if (groupData) {
              toast.success(`${groupData?.data?.updateShipper?.name} has been successfully activated`)
              setTimeout(() => {
                getShippers();
              }, 2000)
            }
          } catch (error) {
            handleApiError(error)
            showSpinner(false)
          }
        }
      }
    })
  }

  return (
    <>
      <PageHeader title='Shipper Group' name={myShipperGroup?.name} pageTitle={"Shipper List"} menus={menus} image={myShipperGroup?.image && myShipperGroup?.image} >
        {!isViewer(myUserRole) && <Link className='btn btn-dark' to={`/shipper-group/${shipperGroupId}/shipper/add`}>
          Add New Shipper
        </Link>}
      </PageHeader >

      <Container fluid>
        <Card>
          <Card.Header>
            <TableKeywordSearch keyword={keyword} onChange={setKeyword} />
            <PatientActiveFilter active={active} onChange={setActive} />
          </Card.Header>
          <Spinner display={spinner} >
            <Table responsive size='sm' className='mb-0 pb-0'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Alias</th>
                  <th>Location</th>
                  <th>status</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  shippers.map((shipper) =>
                    <tr key={shipper.id} className='border-bottom' index={shipper.id} onClick={() => goToOverview(shipper)} style={{ cursor: 'pointer' }} >
                      <td>{shipper.name}</td>
                      <td>{shipper.alias}</td>
                      <td>{shipper.address.city}, {shipper.address.state}</td>
                      <td>
                        {
                          shipper?.active === false ?
                            <span className={`badge bg-danger-soft`}>Disabled</span>
                            : <span className={`badge bg-success`}>Active</span>
                        }
                      </td>
                      <td className='text-center ' onClick={(e) => e.stopPropagation()}>
                        <div className='dropdown '>
                          <Link to='' className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                            <i className="fe fe-more-vertical" />
                          </Link>
                          <div className='dropdown-menu dropdown-menu-end'>
                            {shipper?.active !== false ?
                              <button className='dropdown-item' onClick={() => isOwner(myUserRole) && inactiveShipper(shipper, true)}>  Archive </button>
                              : <button className='dropdown-item' onClick={() => isOwner(myUserRole) && inactiveShipper(shipper, false)}>  Activate </button>
                            }
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                }
              </tbody>

              <TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={7} />
            </Table>
          </Spinner>
        </Card>
      </Container>
    </>
  )
}

export default ShipperList;