import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { StatCard } from '../../components/Card';
import PageHeader from '../../components/PageHeader';
import { isViewer } from '../../helpers';
import ReactGA from "react-ga4"

const ShipperOverview = () => {

  const { shipperGroupId, shipperId } = useParams();
  const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP);
  const myShipper = useSelector((state) => state.slice.SHIPPER);
  const myUserRole = useSelector((state) => state.slice.USER.role)

  const menus = [
    { 'name': 'Overview', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/overview`, 'active': true },
    { 'name': 'Settings', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/setting`, 'active': false },
    { 'name': 'Services', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/service`, 'active': false },
    { 'name': 'Products', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/product`, 'active': false },
  ]

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/shipper-group/${shipperGroupId}/shipper/${shipperId}/overview`,
    })
  }, [])

  return (
    <>
      <PageHeader title={myShipperGroup?.name} name={myShipper?.name} pageTitle={"Shipper Overview"} menus={menus} image={myShipper?.image} >
        {!isViewer(myUserRole) && <Link className='btn btn-dark' to={`/shipper-group/${shipperGroupId}/shipper/${shipperId}/edit`}>
          Edit Shipper
        </Link>}
      </PageHeader>

      <Container fluid>
        <Row>
          <StatCard title='Shipments' value='-' icon='box' />
          <StatCard title='Shipments' value='-' icon='box' />
          <StatCard title='Shipments' value='-' icon='box' />
          <StatCard title='Shipments' value='-' icon='box' />
        </Row>
      </Container>
    </>
  )
}

export default ShipperOverview;