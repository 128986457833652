/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { UserRoleBadge } from '../../components/StatusBadge';
import { PAGE_TITLE, isOwner } from '../../helpers';
import ReactGA from "react-ga4"

const usersByCarrierId = /* GraphQL */ `
  query UsersByCarrierId (
    $carrierId: ID!
    $nextToken: String
  ) {
    carrierUsersByCarrierId (
      carrierId: $carrierId
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        role
        user {
          id
          name
          title
          company
          phone
          email
        }
      }
    }
  }
`;

const CarrierUsers = (props) => {

  const navigate = useNavigate();
  const { id } = useParams()
  const myCarrier = useSelector((state) => state.slice.CARRIER)
  const myUserRole = useSelector((state) => state.slice.USER.role)

  const [spinner, showSpinner] = useState(true);
  const [users, setUsers] = useState([]);

  const menus = [
    { 'name': 'Overview', 'url': `/courier/${id}/overview`, 'active': false },
    { 'name': 'Users', 'url': `/courier/${id}/user`, 'active': true },
  ]

  useEffect(() => {
    getUsers();

    ReactGA.send({
      hitType: "pageview",
      page: `/courier/${id}/user`,
    })

    document.title = `Users ${PAGE_TITLE}`;
  }, []);

  const getUsers = async () => {
    showSpinner(true);
    API.graphql({ query: usersByCarrierId, variables: { carrierId: id } })
      .then((res) => {
        const users = res?.data?.carrierUsersByCarrierId?.items?.sort((a, b) => a?.user?.name.localeCompare(b?.user?.name, undefined, { sensitivity: 'accent' }));
        setUsers(users)
      })
      .catch((error) => console.error(error))
      .finally(() => showSpinner(false))
  };

  return (
    <>
      <PageHeader title='Courier' name={myCarrier?.name} pageTitle={"Courier Users"} menus={menus} >
        <Link to={'/user/invite/create'} className='btn btn-dark ms-2' >
          Invite User
        </Link >
        <Button className='btn btn-light ms-2' onClick={() => { getUsers() }}>
          <i className='fe fe-refresh-cw'></i>
        </Button>
      </PageHeader>
      <Container fluid>
        <Card>
          <Spinner display={spinner}>
            <Table responsive size='sm'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th className='text-center'>Role</th>
                </tr>
              </thead>
              <tbody>
                {
                  users && users.map((user, index) => {
                    return (
                      <tr key={index} onClick={() => isOwner(myUserRole) && navigate(`/user/${user?.user?.id}/overview`)} style={{ cursor: 'pointer' }}>
                        <td>
                          {user?.user?.name}
                          <div className='small text-muted'>
                            {user?.user?.title} {user?.user?.company}
                          </div>
                          <div className='small text-muted'></div>
                          <div className='small text-muted'></div>
                        </td>
                        <td>{user?.user?.phone}</td>
                        <td>{user?.user?.email}</td>
                        <td className='text-center'>
                          <UserRoleBadge role={user?.role} />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          </Spinner>
        </Card>
      </Container>
    </>
  )
}

export default CarrierUsers;