/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { UserRoleBadge } from '../../components/StatusBadge';
import { PAGE_TITLE, isOwner } from '../../helpers';
import ReactGA from "react-ga4"

const usersByShipperGroupId = /* GraphQL */ `
  query GetUsersQuery (
    $shipperGroupId: ID!
    $nextToken: String
  ) {
    shipperGroupUsersByShipperGroupId(
      shipperGroupId: $shipperGroupId
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        role
        user {
          id
          name
          title
          company
          phone
          email
          image
        }
      }
    }
  }
`;

const ShipperGroupUser = () => {

  const navigate = useNavigate();
  const { shipperGroupId } = useParams()
  const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP)
  const myUserRole = useSelector((state) => state.slice.USER.role)

  const [spinner, showSpinner] = useState(false)
  const [users, setUsers] = useState([]);

  const menus = [
    { 'name': 'Overview', 'url': `/shipper-group/${shipperGroupId}/overview`, 'active': false },
    { 'name': 'Shippers', 'url': `/shipper-group/${shipperGroupId}/shipper`, 'active': false },
    { 'name': 'Users', 'url': `/shipper-group/${shipperGroupId}/user`, 'active': true },
  ]

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/shipper-group/${shipperGroupId}/user`,
    })
  }, [shipperGroupId])


  useEffect(() => getUsers(), [])

  const getUsers = () => {
    showSpinner(true)
    API.graphql({ query: usersByShipperGroupId, variables: { shipperGroupId: shipperGroupId } })
      .then((response) => { setUsers(response?.data?.shipperGroupUsersByShipperGroupId?.items?.sort((a, b) => a?.user?.name.localeCompare(b?.user?.name, undefined, { sensitivity: 'accent' }))) })
      .catch((error) => console.error(error))
      .finally(() => showSpinner(false))
  }

  return (
    <>
      <PageHeader title='Shipper Group' name={myShipperGroup?.name} pageTitle={"Shipper Group User"} menus={menus} image={myShipperGroup?.image}>
        <Link to={'/user/invite/create'} className='btn btn-dark ms-2' >
          Invite User
        </Link >
        <Button className='btn btn-light ms-2' onClick={() => { getUsers() }}>
          <i className='fe fe-refresh-cw'></i>
        </Button>
      </PageHeader>

      <Container fluid>
        <Card>
          <Spinner display={spinner}>
            <Table responsive size='sm'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th className='text-center'>Role</th>
                </tr>
              </thead>
              <tbody>
                {
                  users && users.map((user, index) => {
                    return (
                      <tr key={index} onClick={() => isOwner(myUserRole) && navigate(`/user/${user.user.id}/overview`)} style={{ cursor: 'pointer' }}>
                        <td>
                          {user?.user?.name}
                          <div className='small text-muted'>
                            {user?.user?.title} {user?.user?.company}
                          </div>
                        </td>
                        <td>{user?.user?.phone}</td>
                        <td>{user?.user?.email}</td>
                        <td>
                          <UserRoleBadge role={user?.role} />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Spinner>
        </Card>
      </Container>
    </>
  )
}

export default ShipperGroupUser;