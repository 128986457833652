/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Card, Container, Dropdown, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { isViewer } from '../../helpers';
import swal from "sweetalert";
import toast from 'react-hot-toast';
import ReactGA from "react-ga4"

export const deliveryServicesByShipperId = /* GraphQL */ `
  query DeliveryServicesByShipperId($shipperId: ID!) {
    deliveryServicesByShipperId (shipperId: $shipperId) {
      items {
        id
        type
        name
        value
        pickupBy
        tatMin
        tatMax
        maxDistance
        sort
        active
        carrierId
        carrier {
          name
        }
      }
      nextToken
    }
  }
`;

const updateDeliveryService = /* GraphQL */ `
  mutation UpdateDeliveryService($input: UpdateDeliveryServiceInput!) {
    updateDeliveryService(input: $input) {
      id
    }
  }
`;

const ShipperService = () => {

  const navigate = useNavigate()
  const myShipper = useSelector((state) => state.slice.SHIPPER);
  const myUserRole = useSelector((state) => state.slice.USER.role)
  const { shipperGroupId, shipperId } = useParams();

  const menus = [
    { 'name': 'Overview', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/overview`, 'active': false },
    { 'name': 'Settings', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/setting`, 'active': false },
    { 'name': 'Services', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/service`, 'active': true },
    { 'name': 'Products', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/product`, 'active': false },
  ]

  const [spinner, showSpinner] = useState(false);
  const [services, setServices] = useState([])

  useEffect(() => {
    getServices()
    ReactGA.send({
      hitType: "pageview",
      page: `/shipper-group/${shipperGroupId}/shipper/${shipperId}/service`,

    })
  }, [])

  const getServices = () => {
    showSpinner(true);
    API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId: shipperId } })
      .then(({ data }) => {
        const items = data?.deliveryServicesByShipperId?.items.sort((a, b) => a.sort - b.sort)
        setServices(items)
      })
      .catch((error) => console.error(error))
      .finally(() => showSpinner(false))
  }

  const getTat = (min, max) => {
    let tat = '-'
    if (min && max) {
      if (min === max) {
        tat = `${min} hr`
      } else {
        tat = `${min}-${max} hr`
      }
    }

    return tat
  }


  const updateService = (service, active) => {
    swal({
      text: `Are you sure you want to continue?`,
      buttons: ['No', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        try {
          showSpinner(true)
          await API.graphql({ query: updateDeliveryService, variables: { input: { id: service?.id, active } } })
            .then(async (response) => {
              getServices()
              showSpinner(false)
            })

        } catch (error) {
          console.error(error);
          showSpinner(false)
          toast.error('Something went wrong, please try again.');
        }
      }
    });
  }

  return (
    <>
      <PageHeader title={myShipper?.name} name='Services' pageTitle={"Shipper Services"} menus={menus} image={myShipper?.image}>
        {!isViewer(myUserRole) && <Link to={`/shipper-group/${shipperGroupId}/shipper/${shipperId}/service/add`} className='btn btn-dark'>  Add Service</Link>}
      </PageHeader>
      <Container fluid>
        <Card>
          <Spinner display={spinner} >
            <Table responsive size='sm'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Courier</th>
                  <th>Service</th>
                  <th>Type</th>
                  <th className='text-center'>Pickup By</th>
                  <th className='text-center'>Deliver By</th>
                  <th className='text-center'>Delivery Radius</th>
                  <th >Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  services && services.map((service, index) => {
                    return (
                      <tr key={index} onClick={() => !isViewer(myUserRole) && navigate(`/shipper-group/${shipperGroupId}/shipper/${shipperId}/service/${service.id}/edit`)} style={{ cursor: 'pointer' }}  >
                        <td>{service?.sort || '-'}</td>
                        <td>{service?.carrier?.name}</td>
                        <td>{service?.name}</td>
                        <td>{service?.type}</td>
                        <td className='text-center'>{service?.pickupBy || '-'}</td>
                        <td className='text-center'>
                          {getTat(service?.tatMin, service?.tatMax)}
                        </td>
                        <td className='text-center'>{service?.maxDistance ? service?.maxDistance + ' mi' : '-'}</td>
                        <td className='text-wrap' onClick={(e) => e.stopPropagation()} >
                          <Dropdown>
                            <Dropdown.Toggle variant='light' size='sm'>{(service?.active === true ? 'Active' : 'Inactive')}</Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => { updateService(service, true) }}>Active</Dropdown.Item>
                              <Dropdown.Item onClick={() => { updateService(service, false) }}>Inactive</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </Spinner>
        </Card>
      </Container>
    </>
  )
}

export default ShipperService;