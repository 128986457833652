import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { StatCard } from '../../components/Card';
import PageHeader from '../../components/PageHeader';
import { isViewer } from '../../helpers';
import ReactGA from "react-ga4"

const CarrierOverview = () => {

  const { id } = useParams()
  const myCarrier = useSelector((state) => state.slice.CARRIER)
  const myUserRole = useSelector((state) => state.slice.USER.role)

  const menus = [
    { 'name': 'Overview', 'url': `/courier/${id}/overview`, 'active': true },
  ]

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/courier/${id}/overview`,
    })
  }, [])

  return (
    <>
      <PageHeader title='Courier' name={myCarrier?.name} pageTitle={"Courier Overview"} menus={menus}>
        {!isViewer(myUserRole) && <Link className='btn btn-dark' to={`/courier/${id}/edit`}>
          Edit Courier
        </Link>}
      </PageHeader>

      <Container fluid>
        <Row>
          <StatCard title='Shipments' value='-' icon='box' />
          <StatCard title='Shipments' value='-' icon='box' />
          <StatCard title='Shipments' value='-' icon='box' />
          <StatCard title='Shipments' value='-' icon='box' />
        </Row>
      </Container>
    </>
  )
}

export default CarrierOverview;