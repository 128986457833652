/* eslint-disable react-hooks/exhaustive-deps */

import { MapView } from '@aws-amplify/ui-react-geo';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { API, Storage } from 'aws-amplify';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Button, Col, Container, ListGroup, Modal, Row, Table } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Form from 'react-bootstrap/Form';
import Flatpickr from 'react-flatpickr';
import ReactGA from "react-ga4";
import { toast } from 'react-hot-toast';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import PhoneInput from 'react-phone-input-2';
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import StarRatings from 'react-star-ratings';
import swal from 'sweetalert';
import uniqid from 'uniqid';
import awsConfig from "../../aws-exports";
import { AddressView, GoogleAddressField } from '../../components/Address';
import { Card, MultiButtonCard } from '../../components/Card';
import FormLabel from '../../components/FormLabel';
import ListGroupItem from '../../components/ListGroupItem';
import { ActualDeliveredMarker, DriverMarker, OutForReturnMarker, ReturnedMarker, ShipmentMarker, ShipperMarker } from '../../components/MapMarker';
import Spinner from '../../components/Spinner';
import { MapToggleButton } from '../../components/TableFilter';
import PodRelationship from '../../data/pod-relationship.json';
import ShipmentStatus from '../../data/shipment-status.json';
import SignatureType from '../../data/signature-type.json';
import StorageType from '../../data/storage-type.json';
import { FormError, PAGE_TITLE, formatNumber, getDistance, getDistanceFromHere, handleApiError, isValidEmail, isValidPhone, isViewer, statusBadgeColor, statusButtonColor, statusIcon, toLocalDate, toLocalDateTime, toLocalTime } from '../../helpers';

const QRCode = require('qrcode.react');

const onUserLocationUpdate = /* GraphQL */ `
  subscription UserLocationSubscription ($filter: ModelSubscriptionUserLocationFilterInput) {
    onUpdateUserLocation(filter: $filter) {
      	id
	  	latitude
    	longitude
    }
  }
`;

const onShipmentRouteUpdate = /* GraphQL */ `
  subscription ShipmentRouteSubscription ($filter: ModelSubscriptionShipmentRouteFilterInput) {
    onUpdateShipmentRoute(filter: $filter) {
		id
		routeSequence
		routeDuration
		routeDistance
		updatedAt
		updatedAt
	}
  }
`;

const getShipmentQuery = /* GraphQL */ `
  query GetShipment($id: ID!) {
    getShipment(id: $id) {
		id
		number
		authCode
		shipFrom
		shipTo
		weight
		distance
		status
		services
		deliveryInstructions
		items
		billing
		billing_carrier
		billing_shipper
		attachment
		shipperId
		customerId
		shipper{
			settings{
				items {
					key
					value
				}
			}
		  }
		shipperGroupId
		boxId
		box{
			number
			name
		}
		isGreenPhox
		createdTime
		readyForPickupTime
	  	expectedPickupTime
		actualPickupTime
		pickupLocation {
			latitude
			longitude
		}

	  	expectedDeliveryTime
		actualDeliveryTime
		deliveryLocation {
			latitude
			longitude
		}

	  	outForReturnTime
		returnedTime
		returnLocation {
			latitude
			longitude
		}

	  extId
      extTrackingNumber
      extLabelUrl

	  carrierId
      carrier {
        name
		alias
      }
	  route {
		routeSequence
		routeDistance
		routeDuration
		updatedAt
	  }
	  driverId
      driver {
        name
		location {
			latitude
			longitude
		}
      }

	  deliveryServiceId
      deliveryService {
		id
        type
        name
        pickupBy
        tatMin
        tatMax
        maxDistance
		coverage
      }

	  history {
		items {
			id
			createdTime
			createdAt
			status
			description
			attachment
			signature
			userId
			user {
				name
				image
			}
			location{
				latitude
				longitude
			}
		}
	  }

	  ratings {
		items {
			id
			rating
			nps
			feedback
		}
	  }
		 tasks(filter: {status: {eq: OPEN}}) {
      items {
        id
        status
        title
        dueBy
        user {
          name
        }
      }
    }
    }
  }
`;

const updateShipmentRating = /* GraphQL */ `
  mutation UpdateShipmentRating($input: UpdateShipmentRatingInput!) {
    updateShipmentRating(input: $input) {
      id
    }
  }
`;

const createShipmentRating = /* GraphQL */ `
  mutation CreateShipmentRating(
    $input: CreateShipmentRatingInput!
  ) {
    createShipmentRating(input: $input) {
      id
      shipperGroupId
      shipperId
      shipmentId
      rating
      nps
      feedback
    }
  }
`;

const updateShipment = /* GraphQL */ `
  mutation UpdateShipment(
    $input: UpdateShipmentInput!
    $condition: ModelShipmentConditionInput
  ) {
    updateShipment(input: $input, condition: $condition) {
      id
      status
      shipTo
	  shipFrom
	  shipperGroupId
	  shipperId
      items
      services
      attachment
	  batch
	  authCode
	  actualPickupTime
	  actualDeliveryTime
      deliveryInstructions
      expectedPickupTime
      expectedDeliveryTime
	  carrierId
	   carrier{
		id
		name
		alias
	  }
	  createdTime
	  customerId
	  deliveryServiceId
	  distance
      driverId
      driver {
        name
        image
      }
      deliveryService {
        id
        type
        name
        pickupBy
        tatMin
        tatMax
        maxDistance
      }
	  extId
	  weight
	  billing
	  extTrackingNumber
	  number
	  userId
	  outForReturnTime
	  returnedTime
	  updatedAt
	  createdAt
    }
  }
`;

const createShipmentStatusHistory = /* GraphQL */ `
  mutation CreateShipmentStatusHistory($input: CreateShipmentStatusHistoryInput! $condition: ModelShipmentStatusHistoryConditionInput) {
    createShipmentStatusHistory(input: $input, condition: $condition) {
		id
		status
		description
		attachment
		signature
		createdTime
		userId
		user
		{
			image
			name
		}
    }
  }
`;

const updateShipmentStatusHistory = /* GraphQL */ `
  mutation UpdateShipmentStatusHistory($input: UpdateShipmentStatusHistoryInput!) {
    updateShipmentStatusHistory(input: $input) {
		id
		status
		description
		attachment
		signature
		createdTime
		userId
		user
		{
			image
			name
		}
    }
  }
`;

const deliveryServicesByShipperId = /* GraphQL */ `
  query DeliveryServicesByShipperId(
    $shipperId: ID!
	$filter: ModelDeliveryServiceFilterInput
  ) {
    deliveryServicesByShipperId(
      shipperId: $shipperId
	  filter: $filter
    ) {
      items {
        id
        type
        name
        pickupBy
        tatMin
        tatMax
        maxDistance
        shipperId
        carrierId
		coverage
		carrier {
			name
			id
			image
			alias
			active
		}
      }
      nextToken
    }
  }
`;

const carrierUsersByCarrierId = /* GraphQL */ `
  query CarrierUsersByCarrierId($carrierId: ID! $limit: Int $nextToken: String) {
    carrierUsersByCarrierId(carrierId: $carrierId limit: $limit nextToken: $nextToken) {
      items
	  {
			id
			carrierId
			userId
			user
			{
				name
				active
			}
    	}
    	nextToken
    }
  }
`;

const sendSlackAlert = /* GraphQL */ `
  mutation SendSlackAlert($input: SendSlackAlertInput!) {
    sendSlackAlert(input: $input)
  }
`;
const sendShipmentPatientNotification = /* GraphQL */ `
  query SendShipmentPatientNotification($shipmentId: ID!) {
    sendShipmentPatientNotification(shipmentId: $shipmentId)
  }
`;

const sendCarrierNotificationMutation = /* GraphQL */ `
  query sendCarrierNotification ($shipmentId: ID!) {
    sendShipmentCarrierNotification (shipmentId: $shipmentId)
	sendShipmentCarrierUserNotification (shipmentId: $shipmentId)
  }
`;

const createFedexLabelMutation = /* GraphQL */ `
  mutation createFedexLabel ($id: ID!) {
    createFedexLabel (id: $id)
  }
`;

const cancelUpsLabelMutation = /* GraphQL */ `
  mutation CancelUpsLabel($id: ID!) {
    cancelUpsLabel(id: $id)
  }
`;

const cancelEndiciaLabelMutation = /* GraphQL */ `
mutation CancelEndiciaLabel($id: ID!) {
  cancelEndiciaLabel(id: $id)
}
`;



const updateShipmentBoxMutation = /* GraphQL */ `
  mutation UpdateShipmentBox(
    $input: UpdateShipmentBoxInput!
  ) {
    updateShipmentBox(input: $input) {
      id
    }
  }
`;

const createBoxStatusHistory = /* GraphQL */ `
mutation CreateBoxStatusHistory(
  $input: CreateBoxStatusHistoryInput!
  $condition: ModelBoxStatusHistoryConditionInput
) {
  createBoxStatusHistory(input: $input, condition: $condition) {
	id
	boxId
	userId
	shipmentId
  }
}
`;

const cancelFedexLabel = /* GraphQL */ `
  mutation CancelFedexLabel($id: ID!) {
    cancelFedexLabel(id: $id)
  }
`;

const cancelCxtLabelMutation = /* GraphQL */ `
  mutation CancelCxtLabel($id: ID!) {
    cancelCxtLabel(id: $id)
  }
`;

const processShipmentBilling = /* GraphQL */ `
  mutation ProcessShipmentBilling($id: ID!) {
    processShipmentBilling(id: $id)
  }
`;

const ShipmentDetailMap = ({ shipment, viewState }) => {
	const [mapStyle, setMapStyle] = useState(awsConfig.geo.amazon_location_service.maps.default);
	const [driver, setDriver] = useState(shipment.driver)

	useEffect(() => {
		if (shipment.driverId && shipment.status === 'OUT_FOR_DELIVERY') {
			API.graphql({ query: onUserLocationUpdate, variables: { filter: { id: { eq: shipment.driverId } } } }).subscribe({
				next: (response) => {

					let location = {
						latitude: response?.value?.data?.onUpdateUserLocation.latitude,
						longitude: response?.value?.data?.onUpdateUserLocation.longitude
					}
					setDriver((prev) => ({ ...prev, location }))
				},
				error: (error) => console.error('onUserLocationUpdate', error)
			});

		}

	}, [])

	return (
		<div className='mt-n2 bg-light' style={{ width: '100%', height: '500px' }}>
			<MapView initialViewState={viewState} style={{ width: '100%', height: '500px' }} mapStyle={mapStyle} >
				<ShipperMarker shipper={shipment.shipFrom} />
				<ShipmentMarker shipment={shipment} shipmentDetailsLatLong={true} />
				<ActualDeliveredMarker shipment={shipment} />
				{shipment.status === 'OUT_FOR_DELIVERY' && <DriverMarker driver={driver} />}
				<ReturnedMarker shipment={shipment} />
				<OutForReturnMarker shipment={shipment} />

				<div className="d-flex justify-content-end me-3" style={{ zIndex: 3, position: 'absolute', bottom: 30, right: 10 }} >
					<MapToggleButton mapStyle={mapStyle} setMapStyle={setMapStyle} />
				</div>

			</MapView>
		</div>
	)
}

const ShipmentStats = ({ shipment, id }) => {

	const [shipmentRoute, setShipmentRoute] = useState(shipment?.route);
	const [timeRemaining, setTimeRemaining] = useState("");

	useEffect(() => {
		let diffInMins = moment().diff(moment(shipment?.route?.updatedAt), "minutes")
		let timeDiff = (shipment?.route?.routeDuration - diffInMins) * 60
		setTimeRemaining(timeDiff)
	}, [shipment?.route?.updatedAt])


	useEffect(() => {
		const shipmentRouteSubscription = API.graphql({ query: onShipmentRouteUpdate, variables: { filter: { id: { eq: id } } } }).subscribe({
			next: (response) => {
				const route = response.value.data.onUpdateShipmentRoute;
				setShipmentRoute({ ...shipment, route: route })
				let diffInMins = moment().diff(moment(shipment?.route?.updatedAt), "minutes")
				setTimeRemaining((shipment?.route?.routeDuration - diffInMins) * 60)
			},
			error: (error) => console.error('Subscription error:', error),
		});

		return () => {
			shipmentRouteSubscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		let timer = setInterval(() => {
			setTimeRemaining(prevTime => prevTime - 1)
		}, 1000)

	}, [shipment?.route?.updatedAt])

	let liveHours = Math.floor(timeRemaining / 3600);
	let liveMinutes = Math.floor((timeRemaining % 3600) / 60);
	let liveETA = liveHours > 0 ? `${liveHours} hr ${liveMinutes} min` : `${liveMinutes} min`;

	let deliveryStatus
	if (shipmentRoute?.routeSequence > 1) {
		deliveryStatus = `The courier is ${shipmentRoute?.routeSequence} stops and ${liveETA} away`
	} else if (shipmentRoute?.routeSequence === 1) {
		deliveryStatus = `Courier should arrive in about  ${liveETA}`
	} else if (shipment?.route?.routeSequence === 1 && liveMinutes <= 0) {
		deliveryStatus = `Courier will arrive shortly. For more information contact us at (844) 688-7469`
	}

	let pickuptime;
	const readyForPickupItem = shipment?.history?.find(item => item?.status === "READY_FOR_PICKUP");
	if (readyForPickupItem) {
		pickuptime = moment(readyForPickupItem?.createdTime);
	}

	const tat = (shipment?.status === 'DELIVERED' && shipment?.actualDeliveryTime) ? moment.unix(shipment?.actualDeliveryTime).diff(moment.unix(pickuptime), 'minutes') : '-';
	const hrs = tat && Math.floor(tat / 60);
	const min = tat && tat % 60;
	const tatData = hrs > 0 ? `${hrs} hr, ${min} ${min === 1 ? `min` : `min(s)`}` : `${min} ${min === 1 ? `min` : `min(s)`}`;

	return (
		<>
			{shipment?.status === "READY_FOR_PICKUP" && (
				<ListGroupItem name="Estimated Pickup By"
					value={`${toLocalDateTime(
						shipment?.expectedPickupTime,
						shipment?.shipFrom?.timezone?.id
					)}`}
				/>
			)}

			{shipment?.status === "IN_TRANSIT" && (
				<ListGroupItem
					name="Estimated Delivery By"
					value={`${toLocalDateTime(
						shipment?.expectedDeliveryTime,
						shipment?.shipFrom?.timezone?.id
					)}`}
				/>
			)}

			{shipment?.status === "OUT_FOR_DELIVERY" && (

				<ListGroupItem
					name="Estimated Delivery By"
					value={`${deliveryStatus || toLocalDateTime(
						shipment?.expectedDeliveryTime,
						shipment?.shipFrom?.timezone?.id
					)}`}
				/>
			)}

			{shipment?.status === "DELIVERED" && (
				<>
					<ListGroupItem
						name="Delivered At"
						value={toLocalDateTime(
							shipment?.actualDeliveryTime,
							shipment?.shipFrom?.timezone?.id,
							shipment?.shipFrom?.timezone?.alias
						)}
					/>
					<hr />
					<ListGroupItem
						name="Turnaround Time"
						value={(tatData && !isNaN(tat) && tatData)}
					/>
				</>
			)}

			{shipment?.status === "RETURNED" && (
				<ListGroupItem
					name="Returned At"
					value={toLocalDateTime(
						shipment?.returnedTime,
						shipment?.shipFrom?.timezone?.id,
						shipment?.shipFrom?.timezone?.alias
					)}
				/>
			)}
		</>
	);
};

const ShipmentDetail = () => {
	const myUser = useSelector((state) => state.slice.USER);

	const { id } = useParams();
	const mapRef = useRef();
	const navigate = useNavigate()

	const [spinner, showSpinner] = useState(true);
	const [shipment, setShipment] = useState([])
	const [deliveryServices, setDeliveryServices] = useState([]);
	const [drivers, setDrivers] = useState([]);
	const [qrCode, setQrCode] = useState();
	const [carrierlist, setCarrierlist] = useState([]);
	const [viewState, setViewState] = useState({
		latitude: 37.7061332,
		longitude: -101.4802194,
		zoom: 4
	});

	const [multi_Dimensional_Shipment_Item, setMulti_Dimensional_Shipment_Item] = useState(false)


	useEffect(() => {
		getShipment()
	}, []);

	useEffect(() => {
		if (shipment?.number) {
			document.title = `Shipment #${shipment?.number} ${PAGE_TITLE}`
			ReactGA.send({
				hitType: "pageview",
				page: `/shipment/${id}`,
			})
		}

	}, [shipment?.number]);

	useEffect(() => mapRef.current?.flyTo({ center: [viewState.longitude, viewState.latitude], zoom: 10 }), [viewState]);


	function checkDimension(arr) {
		if (Array.isArray(arr) && arr.some(innerArr => Array.isArray(innerArr))) {
			setMulti_Dimensional_Shipment_Item(true)
		} else if (Array.isArray(arr)) {
			setMulti_Dimensional_Shipment_Item(false)
		} else {
			console.log("Not an array");
		}
	}

	const getShipment = () => {
		showSpinner(true);
		API.graphql({ query: getShipmentQuery, variables: { id: id } })
			.then((data) => {
				let shipment = data?.data?.getShipment
				if (shipment) {
					if (shipment.shipFrom) shipment.shipFrom = JSON.parse(shipment.shipFrom)
					if (shipment.shipTo) shipment.shipTo = JSON.parse(shipment.shipTo)
					if (shipment.items) shipment.items = JSON.parse(shipment.items)
					if (shipment.history?.items?.length > 0) shipment.history = shipment.history?.items?.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));
					else shipment.history = []

					if (shipment?.status === "DELIVERED") {
						setViewState({
							latitude: shipment?.shipTo?.address?.location?.latitude,
							longitude: shipment?.shipTo?.address?.location?.longitude,
							zoom: 18,
						})
					} else {
						setViewState({
							latitude: shipment?.shipFrom?.address?.location?.latitude,
							longitude: shipment?.shipFrom?.address?.location?.longitude,
							zoom: 12,
						});
					}
					checkDimension(shipment?.items)
					setShipment(shipment);
					getDeliveryServices(shipment.shipperId, shipment.isGreenPhox);
					getDrivers(shipment.carrierId);

					let qrCode = `${shipment?.id || ''}`;
					setQrCode(qrCode);
				} else {
					console.error('shipment not found')
				}
			})
			.catch((error) => console.error(error))
			.finally(() => showSpinner(false))
	};

	const getDeliveryServices = (shipperId, isGreenPhox) => {
		API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId, filter: { active: { eq: true } } } }).then(({ data }) => {
			let items = data?.deliveryServicesByShipperId?.items;
			let carriers = [];
			if (items.length > 0) {
				items = items.filter(record => record.carrier.name !== 'USPS');
				setDeliveryServices(items)
				// set all carriers
				if (isGreenPhox) {
					items.forEach((item) => {
						if (!['usps', 'ups', 'fedex'].includes(item.carrier.name.toLowerCase())) {
							if (carriers.findIndex(x => x.id === item?.carrier?.id) < 0 && item?.carrier?.active !== false)
								carriers.push(item.carrier)
						}
					});
					setCarrierlist(carriers)
				} else {
					items.forEach((item) => {
						if (carriers.findIndex(x => x.id === item?.carrier?.id) < 0 && item?.carrier?.active !== false)
							carriers.push(item.carrier)
					});
					setCarrierlist(carriers)
				}
			}
		}).catch(error => console.error('getDeliveryServices', error))
	};

	const getDrivers = (carrierId) => {
		API.graphql({ query: carrierUsersByCarrierId, variables: { carrierId } }).then(({ data }) => {
			setDrivers(data.carrierUsersByCarrierId?.items)
		}).catch(error => console.error('getDrivers', error))
	};

	const calculateBilling = async () => {
		showSpinner(true)
		try {
			await API.graphql({ query: processShipmentBilling, variables: { id: id } });
			getShipment();
		} catch (error) {
			handleApiError(error);
			showSpinner(false);
		}
	}

	//#region Components

	const ShipmentHeader = () => {

		const [spinner, showSpinner] = useState(false);

		const [shipToModal, showShipToModal] = useState(false)
		const [statusModal, showStatusModal] = useState(false);

		const [status, setStatus] = useState(shipment?.status);
		const [statusDescription, setStatusDescription] = useState('');
		const [statusDate, setStatusDate] = useState(new Date());
		const [errors, setErrors] = useState()
		const [attachment, setAttachment] = useState({});
		const [distance, setDistance] = useState(shipment?.distance);
		const [shipTo, setShipTo] = useState(shipment?.shipTo);
		const [isNotify, setNotify] = useState();

		useEffect(() => {
			let shipTodata = shipTo;
			if (!shipTo?.firstName) shipTodata.firstName = shipTo?.name
			setShipTo(shipTodata)
		}, [])

		const handleShipToChange = (e) => setShipTo({ ...shipTo, [e.target.name]: e.target.value });
		const handlePhoneChange = (phone) => setShipTo({ ...shipTo, phone: `+${phone}` });
		const handleAddress1Change = (address1) => setShipTo((prevState) => ({ ...prevState, address: { ...prevState.address, address1: address1 } }));
		const handleAddress2Change = (e) => setShipTo((prevState) => ({ ...prevState, address: { ...prevState.address, address2: e.target.value } }));

		const handleAddressSelect = async (values, placeId) => {
			const places = await geocodeByPlaceId(placeId);
			const latLong = await getLatLng(places[0]);
			let street_number = '';
			let sub_locality1 = '';
			let sub_locality2 = '';
			let sub_locality3 = '';
			let route = '';
			let city = '';
			let state = '';
			let postalCode = '';
			let address2 = ''

			let location = {};
			if (latLong) {
				location.latitude = latLong.lat;
				location.longitude = latLong.lng;
			}

			places && places.length > 0 && places[0].address_components.forEach((address) => {
				if (address.types.includes('street_number')) street_number = address.short_name;
				if (address.types.includes('sublocality_level_3')) sub_locality1 = address.short_name;
				if (address.types.includes('sublocality_level_2')) sub_locality2 = address.short_name;
				if (address.types.includes('sublocality_level_1')) sub_locality3 = address.short_name;
				if (address.types.includes('route')) route = address.short_name;
				if (address.types.includes('locality')) city = address.short_name;
				if (address.types.includes('administrative_area_level_1')) state = address.short_name;
				if (address.types.includes('postal_code')) postalCode = address.long_name;
				address2 = ''
			});

			const address = {
				address1: street_number ? `${street_number} ${route}` : `${sub_locality1} ${sub_locality2} ${sub_locality3} ${route}`,
				address2,
				postalCode,
				city,
				state,
				location,
			}

			const distance = await getDistance(address.location, shipment.shipFrom.address.location);
			setDistance(distance)

			setShipTo({
				...shipTo,
				address: address
			});
		};


		const isFormValid = async () => {
			const error = {};
			if (!shipTo?.name?.trim()) error.name = 'Name is required';
			if (!shipTo?.address?.address1?.trim()) error.address1 = 'Address 1 is required';
			if (!shipTo?.address?.postalCode?.trim()) error.postalCode = 'Postal Code is required'
			if (!isValidPhone(shipTo?.phone?.trim())) error.phone = 'Please enter a valid phone number';
			if (!isValidEmail(shipTo?.email?.trim())) error['email'] = 'Please enter a valid email'

			if (shipment?.deliveryService?.maxDistance && !["usps", "fedex", "ups"].includes(shipment?.carrier?.alias?.toLowerCase())) {
				if (distance > shipment?.deliveryService?.maxDistance) {
					error.address1 = "Distance is more then 55 mi, please select another Courier"
					error.address1 = `Delivery location is ${distance} mi away, it should be within ${shipment?.deliveryService?.maxDistance} mi`
				}
			}

			// 55 mi logic 
			let selectedCarrier = carrierlist?.find((item) => item.id === shipment?.carrierId)
			if (shipment?.shipFrom?.alias?.toLowerCase() === "aurora mail order pharmacy" && ['WI', "IL"].includes(shipment?.shipTo?.address?.state)) {
				if (selectedCarrier?.name?.includes("WI")) {
					let distance = await getDistanceFromHere(shipment?.shipTo?.address?.location, shipment?.shipFrom?.address?.location);
					if (distance > 55) {
						error.address1 = 'Distance is more then 55 mi, please select another Courier';
					}
				} else if (selectedCarrier?.name?.includes("IL")) {
					const chicagoLocation = { latitude: 42.047586035877956, longitude: -87.98072053317183 }
					const chicagoDistance = await getDistanceFromHere(chicagoLocation, shipment?.shipTo?.address?.location);
					if (chicagoDistance > 55) {
						error.address1 = "Distance is more then 55 mi, please select another Courier"
						error.address1 = 'Distance is more then 55 mi, please select another Courier';
					}
				}
			}

			setErrors(error);
			console.log(error);
			return Object.keys(error).length === 0;
		};

		const updateShipTo = async () => {
			let error = await isFormValid();
			if (!error) return

			if (shipment?.deliveryService?.coverage !== null) {
				if (shipment?.deliveryService?.coverage?.length !== 0 && !shipment?.deliveryService?.coverage?.includes(shipTo?.address?.postalCode)) {
					return toast.error('Service is not available at this Postal Code!')
				}
			}
			showSpinner(true);

			const distance = await getDistanceFromHere(shipTo.address.location, shipment.shipFrom.address.location);

			shipTo.name = (shipTo.firstName && shipTo.lastName) ? shipTo.firstName + " " + shipTo.lastName : shipTo.firstName ? shipTo.firstName : shipTo?.name
			const input = { id, shipTo: JSON.stringify(shipTo), distance: distance }
			API.graphql({ query: updateShipment, variables: { input: input } }).then(async () => {
				toast.success('Ship To address has been updated');
				await calculateBilling()
				showShipToModal(false);
			}).catch((error) => {
				toast.error('Oops something went werong');
				showShipToModal(false);
			}).finally(() => showSpinner(false))
		}

		const updateStatus = () => {
			showSpinner(true);
			const inputDate = moment(statusDate).format('YYYY-MM-DD HH:mm')
			const tzDateTime = moment.tz(inputDate, shipment?.shipFrom?.timezone?.id).unix()

			let createdTime = moment().unix();
			let boxInput = {
				id: shipment?.boxId
			}

			let boxStatusHistory = {
				boxId: shipment?.boxId,
				shipmentId: shipment?.id,
				userId: myUser?.id,
				createdTime: moment().unix()
			}

			let inputShipment = { id, status: status }
			if (status === 'IN_TRANSIT') {
				inputShipment.actualPickupTime = tzDateTime;
				createdTime = tzDateTime
			}
			else if (status === 'OUT_FOR_DELIVERY') {
				inputShipment.actualPickupTime = tzDateTime;
				createdTime = tzDateTime
			}
			else if (status === 'DELIVERED') {
				boxInput.status = 'OUT_FOR_RETURN'
				boxStatusHistory.status = 'OUT_FOR_RETURN'
				inputShipment.actualDeliveryTime = tzDateTime;
				createdTime = tzDateTime
				if (attachment) {
					let myAttachment = shipment.attachment || [];
					myAttachment = myAttachment.concat(Object.values(attachment));
					inputShipment.attachment = myAttachment
				}
			}
			else if (status === 'OUT_FOR_RETURN') {
				boxInput.status = 'OUT_FOR_RETURN'
				boxStatusHistory.status = 'OUT_FOR_RETURN'
				inputShipment.outForReturnTime = tzDateTime;
				createdTime = tzDateTime
			}
			else if (status === 'RETURNED') {
				boxInput.status = 'RETURNED'
				inputShipment.returnedTime = tzDateTime;
				createdTime = tzDateTime
			}

			let inputShipmentHistory = {
				id: uniqid(),
				shipmentId: shipment.id,
				userId: myUser.id,
				status: status,
				createdTime: createdTime,
				description: statusDescription
			}

			const slackInput = {
				channel: 'alerts',
				message: `Shipment ${shipment?.number} (${shipment?.deliveryService?.name}) is ${status}`,
				user: shipment?.shipFrom?.name
			}

			let task = []
			task.push(API.graphql({ query: updateShipment, variables: { input: inputShipment } }))
			{ shipment?.isGreenPhox && shipment?.boxId && task.push(API.graphql({ query: updateShipmentBoxMutation, variables: { input: boxInput } })) }
			task.push(API.graphql({ query: createShipmentStatusHistory, variables: { input: inputShipmentHistory } }))
			task.push(API.graphql({ query: sendSlackAlert, variables: { input: slackInput } }))
			isNotify && task.push(API.graphql({ query: sendShipmentPatientNotification, variables: { shipmentId: id } }))
			shipment.carrier.name.toLowerCase() === 'ups' && shipment?.extTrackingNumber && status === 'EXCEPTION' && task.push(API.graphql({ query: cancelUpsLabelMutation, variables: { id } }))
			shipment?.carrier?.name?.toLowerCase() === 'usps' && shipment?.extTrackingNumber && status === 'EXCEPTION' && task.push(API.graphql({ query: cancelEndiciaLabelMutation, variables: { id } }))
			shipment?.carrier?.name?.toLowerCase() === 'fedex' && shipment?.extTrackingNumber && status === 'EXCEPTION' && task.push(API.graphql({ query: cancelFedexLabel, variables: { id } }))
			shipment?.carrier?.alias?.toLowerCase() === 'clockwork' && status === 'EXCEPTION' && task.push(API.graphql({ query: cancelCxtLabelMutation, variables: { id } }));

			Promise.all(task).then(() => {
				toast.success(`Status has been updated to ${ShipmentStatus[status]}`);
				if (status === 'EXCEPTION' || status === 'OUT_FOR_RETURN') API.graphql({ query: sendCarrierNotificationMutation, variables: { shipmentId: id } })
				calculateBilling()
				showSpinner(false);
			}).catch((error) => { console.log(error); showSpinner(false); showStatusModal(false); });

			if (['OUT_FOR_RETURN', 'DELIVERED'].includes(status) && shipment?.isGreenPhox && shipment?.boxId) {
				boxStatusHistory.status = 'OUT_FOR_RETURN';
				boxStatusHistory.description = statusDescription;
				API.graphql({ query: createBoxStatusHistory, variables: { input: boxStatusHistory } }).then((response) => {
				}).catch((error) => {
					console.error(error);
					toast.error('Opps, Something went wrong');
				})
			}

			if (['OUT_FOR_DELIVERY', 'IN_TRANSIT'].includes(status) && shipment?.isGreenPhox && shipment?.boxId) {
				boxStatusHistory.status = 'IN_USE';
				boxStatusHistory.description = (status === 'OUT_FOR_DELIVERY' ? 'Shipment out for delivery' : status === 'IN_TRANSIT' ? 'Shipment in Transit' : null)
				API.graphql({ query: createBoxStatusHistory, variables: { input: boxStatusHistory } }).then((response) => {
				}).catch((error) => {
					console.error(error);
					toast.error('Opps, Something went wrong');
				})
			}

			return false;
		}

		const processFile = ({ file }) => {
			const ext = file.name.split('.').pop();
			return { file, key: `${uniqid()}.${ext}` };
		};

		const onUploadSuccess = ({ key }) => {
			Storage.get(key).then(url => {
				setAttachment(prevAttachment => ({ ...prevAttachment, [key]: url.split('?')[0] }));
			});
		};
		return (
			<>
				<nav className='header mb-0'>
					<Container fluid>
						<div className='header-body'>
							<Row className='align-items-center'>
								<Col className='col-auto p-0'>
									{shipment?.carrier?.name?.toLowerCase() !== ('fedex' || 'usps') && !isViewer(myUser.role) && <>
										<Button className='btn btn-light btn-sm ms-2' onClick={() => showShipToModal(true)}>
											<i className='fe fe-edit-2' />
										</Button>
									</>}
								</Col>
								<Col className="d-flex">
									<div>
										<h1 className="mb-1">
											{`#${shipment?.number}`}
										</h1>
										<h2 className="text-muted mb-1">
											{shipment?.customerId ?
												<Link to={`/patient/edit/${shipment?.customerId}`}>
													{shipment?.shipTo?.name || "-"}
												</Link> :
												<>
													{shipment?.shipTo?.name || "-"}
												</>
											}
										</h2>
									</div>
									<div className="align-item-centers">
										{shipment?.isGreenPhox && < img src="/img/greenphox.svg" alt="green-phox-icon rounded-circle" className="mx-2" height={'50px'} width={'50px'} />}
									</div>
								</Col>
								<Col>
									<div className='small text-muted'>
										<AddressView address={shipment?.shipTo?.address} />
									</div>
								</Col>
								<Col>
									<div className='small text-muted'>
										{
											shipment?.shipTo?.email && <div>
												<i className='fe fe-mail' /> {shipment?.shipTo?.email}
											</div>
										}
										{
											shipment?.shipTo?.phone?.length > 10 && <div>
												<i className='fe fe-phone' /> {formatNumber(shipment?.shipTo?.phone)}
											</div>
										}
									</div>
								</Col>
								<Col className='col-auto'>
									<Button className={statusButtonColor(shipment?.status)} onClick={() => !isViewer(myUser.role) && showStatusModal(true)}>
										<i className={statusIcon(shipment?.status)} /> {ShipmentStatus[shipment?.status]}
									</Button>
								</Col>
							</Row>
						</div>
					</Container>
				</nav>

				<Modal show={shipToModal}>
					<Modal.Header>
						<Modal.Title className='modal-title'>Ship To Address</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col lg={6} md={6}>
								<Form.Group className="mb-3">
									<FormLabel required> First Name</FormLabel>
									<Form.Control type="text" name="firstName" placeholder="e.g. John" defaultValue={shipTo?.firstName} onChange={handleShipToChange} isInvalid={!!errors?.name} />
									<Form.Control.Feedback type="invalid">
										{errors?.first_name}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col lg={6} md={6}>
								<Form.Group className="mb-3">
									<FormLabel>Last Name</FormLabel>
									<Form.Control type="text" name="lastName" placeholder="e.g. doe" defaultValue={shipTo?.lastName} onChange={handleShipToChange} />
								</Form.Group>
							</Col>
						</Row>
						<Form.Group className='mb-3'>
							<Form.Label>Company</Form.Label>
							<Form.Control type='text' name='company' placeholder='e.g. PhoxHealth Inc' defaultValue={shipTo?.company} onChange={handleShipToChange} />
						</Form.Group>
						<Row>
							<Col lg={6} md={6}>
								<Form.Group className='mb-3'>
									<FormLabel>Email</FormLabel>
									<Form.Control type='text' name='email' placeholder='e.g. john@phoxhealth.com' value={shipTo?.email} onChange={handleShipToChange} />
									<FormError error={errors?.email} />
								</Form.Group>
							</Col>
							<Col lg={6} md={6}>
								<Form.Group className='mb-3'>
									<FormLabel>Phone</FormLabel>
									<PhoneInput name='phone' country='us' onlyCountries={['us']} value={shipTo?.phone || ''} placeholder='e.g. 998-776-5543' onChange={handlePhoneChange} />
									<FormError error={errors?.phone} />
								</Form.Group>
							</Col>
						</Row>
						<Form.Group className='mb-3'>
							<FormLabel required>Address Line 1 </FormLabel>
							<GoogleAddressField name='address.address1' value={shipTo?.address?.address1}
								error={errors?.address1} isInvalid={!!errors?.address1}
								onChange={handleAddress1Change} onSelect={handleAddressSelect} />
						</Form.Group>
						<Form.Group className='mb-3'>
							<FormLabel>Address Line 2 </FormLabel>
							<Form.Control type='text' name='address2' placeholder='e.g. near city center' defaultValue={shipTo?.address?.address2} onChange={handleAddress2Change} />
						</Form.Group>
						<Row>
							<Col lg={4} md={4}>
								<Form.Group className='mb-3'>
									<FormLabel required>City</FormLabel>
									<Form.Control type='text' placeholder='e.g. +1 New York' value={shipTo?.address?.city} disabled />
								</Form.Group>
							</Col>
							<Col lg={4} md={4}>
								<Form.Group>
									<FormLabel required>State</FormLabel>
									<Form.Control type='text' placeholder='e.g. NY' value={shipTo?.address?.state} disabled />
								</Form.Group>
							</Col>
							<Col lg={4} md={4}>
								<Form.Group className='mb-3'>
									<FormLabel required>Postal Code</FormLabel>
									<Form.Control type='text' placeholder='e.g. 10018' value={shipTo?.address?.postalCode} disabled />
								</Form.Group>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer className='display-flex-start'>
						<Spinner display={spinner}>
							<button className='btn btn-dark ms-2' onClick={() => updateShipTo()}>Update</button>
							<button onClick={() => { showShipToModal(false); setShipTo(shipment?.shipTo); setErrors() }} className='btn btn-link text-muted'>Cancel</button>
						</Spinner>
					</Modal.Footer>
				</Modal>

				<Modal show={statusModal}>
					<Modal.Header>
						<Modal.Title className='modal-title'>Update Shipment Status</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className='mb-3'>
							<FormLabel required>Status</FormLabel>
							<Form.Select name='status' defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
								<option value='' disabled>Select Status</option>
								{Object.keys(ShipmentStatus).map((key) => <option key={key} value={key}>{ShipmentStatus[key]}</option>)}
							</Form.Select>
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label>Description</Form.Label>
							<Form.Control as='textarea' name='statusDescription' defaultValue={statusDescription} onChange={(e) => setStatusDescription(e.target.value)} />
						</Form.Group>

						{
							['IN_TRANSIT', 'OUT_FOR_DELIVERY', 'DELIVERED', 'OUT_FOR_RETURN', 'RETURNED'].includes(status) && <>
								<FormLabel required>
									{status === 'IN_TRANSIT' && 'Actual Pickup Time'}
									{status === 'OUT_FOR_DELIVERY' && 'Actual Pickup Time'}
									{status === 'DELIVERED' && 'Actual Delivery Time'}
									{status === 'OUT_FOR_RETURN' && 'Out for Return Time'}
									{status === 'RETURNED' && 'Returned Time'}
								</FormLabel>
								<div className='mb-3'>
									<Flatpickr className='form-control d-block' name='statusDate'
										value={statusDate}
										options={{ enableTime: true, dateFormat: 'M d, Y h:i K', static: true }}
										onChange={(dates) => setStatusDate(new Date(dates[0]))}
									/>
								</div>
							</>
						}

						{
							(['READY_FOR_PICKUP', 'OUT_FOR_DELIVERY', 'DELIVERED', 'OUT_FOR_RETURN'].includes(status))
							&& (
								<Form.Group controlId='formBasicCheckbox'>
									<Form.Check type='checkbox' label='Notify Patient' checked={isNotify} onChange={(e) => setNotify(e.target.checked)} />
								</Form.Group>
							)
						}

						{
							status === 'DELIVERED' &&
							<div className="mt-3">
								<StorageManager
									acceptedFileTypes={['.png', '.jpeg', '.jpg']}
									accessLevel='public' maxFileCount={3}
									showImages={false} path={`shipment/${id}/`}
									processFile={processFile} onUploadSuccess={onUploadSuccess}
								/>
							</div>
						}

					</Modal.Body>
					<Modal.Footer className='display-flex-start'>
						<Spinner display={spinner}>
							<button className='btn btn-dark ms-2' onClick={() => updateStatus()}>Update</button>
							<button onClick={() => showStatusModal(false)} className='btn btn-link text-muted'>Cancel</button>
						</Spinner>
					</Modal.Footer>
				</Modal>
			</>
		)
	}



	const ShipmentDetails = () => {
		const [spinner, showSpinner] = useState(false);
		const [myShipment, setMyShipment] = useState(shipment);
		const [shipmentModal, showShipmentModal] = useState(false);
		const [storageTypesSettings, setStorageTypesSettings] = useState([]);
		let storageTypes = myShipment?.services && myShipment?.services?.map((key, index) => index !== myShipment?.services?.length - 1 ? StorageType[key] === undefined ? '' : StorageType[key] + ', ' : StorageType[key]);

		const handleShipmentServiceChange = (service) => {
			let services = myShipment?.services;
			if (services) {
				if (services.includes(service)) {
					services = services.filter(x => x !== service);
					if (shipment?.shipper?.settings?.items?.find(x => x.key === 'shipment_photo_id_required')?.value === "Yes") {
						if (service === "ADULT_SIGNATURE" && services.includes("PHOTO_ID")) {
							services = services.filter(x => x !== "PHOTO_ID");
						}
					}
				} else {
					services.push(service);
					if (shipment?.shipper?.settings?.items?.find(x => x.key === 'shipment_photo_id_required')?.value === "Yes") {
						if (service === "PHOTO_ID" && !services?.includes("ADULT_SIGNATURE")) {
							services.push("ADULT_SIGNATURE")
						}
					}
				}
			} else {
				services = [service]
			}
			setMyShipment({ ...myShipment, services });
		}

		useEffect(() => {
			let storageTypeSettings = [];
			const refrigerated = shipment?.shipper?.settings?.items.find(x => x.key === 'storage_refrigerated')
			if (refrigerated?.value === 'Yes') storageTypeSettings.push('REFRIGERATED')

			const dry_ice = shipment?.shipper?.settings?.items.find(x => x.key === 'storage_dry_ice')
			if (dry_ice?.value === 'Yes') storageTypeSettings.push('DRY_ICE')

			const cold_chain = shipment?.shipper?.settings?.items.find(x => x.key === 'storage_cold_chain')
			if (cold_chain?.value === 'Yes') storageTypeSettings.push('COLD_CHAIN')

			const room_temperature = shipment?.shipper?.settings?.items.find(x => x.key === 'storage_ambient')
			if (room_temperature?.value === 'Yes') storageTypeSettings.push('ROOM_TEMPERATURE')

			setStorageTypesSettings(storageTypeSettings);

			let photo_Id = shipment?.shipper?.settings?.items.find(x => x.key === 'shipment_photo_id_required')
			if (photo_Id?.value === "Yes") {
				SignatureType.PHOTO_ID = "Photo ID Required"
			}

		}, [])

		const updateShipmentDetails = () => {
			showSpinner(true);
			const input = {
				id,
				deliveryInstructions: myShipment.deliveryInstructions,
				services: myShipment?.services
			}
			API.graphql({ query: updateShipment, variables: { input } }).then(({ data }) => {
				toast.success(`Shipment details has been updated`);
				getShipment()
				showShipmentModal(false);
				showSpinner(false);
			});
		};

		let trackingUrl = "";
		if (
			shipment?.carrier?.name?.toLowerCase() === "fedex" &&
			shipment?.extTrackingNumber
		) {
			trackingUrl = `https://www.fedex.com/fedextrack/?trknbr=${shipment?.extTrackingNumber}`;
		} else if (
			shipment?.carrier?.name?.toLowerCase() === "usps" &&
			shipment?.extTrackingNumber
		) {
			trackingUrl = `https://tools.usps.com/go/TrackConfirmAction.action?tLabels=${shipment?.extTrackingNumber}`;
		} else if (
			shipment?.carrier?.name?.toLowerCase() === "ups" &&
			shipment?.extTrackingNumber
		) {
			trackingUrl = `https://www.ups.com/track?track=yes&trackNums=${shipment?.extTrackingNumber}`;
		}

		return <>
			<Card title='Shipment Details' bodyPadding='py-0' buttonName={shipment?.carrier?.name?.toLowerCase() !== ('fedex' || 'usps') && !isViewer(myUser.role) ? 'Edit' : ''} onClick={() => showShipmentModal(true)}>
				<ListGroup className='list-group-flush'>
					{shipment?.isGreenPhox && <ListGroupItem name="Box Number" value={shipment?.box?.number || shipment?.box?.name} url={`/box/details/${id}`} />}
					<ListGroupItem name='Shipper' value={shipment?.shipFrom?.name} />
					<ListGroupItem name='Shipper Phone' value={shipment?.shipFrom?.phone || '-'} />
					<ListGroupItem name='Delivery Service' value={shipment?.deliveryService?.name} />
					<ListGroupItem name='Delivery Date' value={toLocalDate(shipment?.expectedDeliveryTime, shipment?.shipFrom?.timezone?.id)} />
					<ListGroupItem name='Order / Reference Number' value={shipment?.extId} />
					<ListGroupItem name='Adult Signature' value={shipment?.services?.includes('ADULT_SIGNATURE') ? 'Required' : '-'} />
					{shipment?.services?.includes('PHOTO_ID') && <ListGroupItem name='Photo ID' value={'Required'} />}
					<ListGroupItem name='Package Type' value={storageTypes || '-'} />
					<ListGroupItem name='Weight' value={shipment?.weight ? `${shipment?.weight} lbs` : '-'} />
					<ListGroupItem name='Distance' value={`${shipment?.distance} mi`} />
					<ListGroupItem name='Tracking Number' value={shipment?.extTrackingNumber || '-'} url={trackingUrl} />
					<ListGroupItem name='Delivery Instructions' value={shipment.deliveryInstructions || '-'} />
				</ListGroup>
			</Card>

			<Modal show={shipmentModal} aria-labelledby='contained-modal-title-vcenter'>
				<Modal.Header>
					<Modal.Title className='modal-title'>Update Shipment Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='d-flex justify-content-between'>
						<FormLabel >Adult Signature </FormLabel>
					</Form.Group>
					<Form.Group className='mb-3 d-flex justify-content-start'>
						{
							Object.keys(SignatureType).map((key, index) => {
								return (
									<Button key={index}
										className={`btn btn-${myShipment?.services?.includes(key) ? 'success' : 'light'} me-2 mt-2`}
										onClick={() => handleShipmentServiceChange(key)}>
										{SignatureType[key]}
									</Button>
								)
							})
						}
					</Form.Group>

					<Form.Group className='d-flex justify-content-between'>
						<FormLabel >Storage Type</FormLabel>
					</Form.Group>

					<Form.Group className='mb-3 d-flex justify-content-start'>
						{
							Object.keys(StorageType).map((key, index) => {
								return (
									<>
										{
											storageTypesSettings.length > 0 ?
												storageTypesSettings.includes(key) &&
												< Button key={index}
													className={`btn btn-${myShipment?.services?.includes(key) ? 'success' : 'light'} me-2 mt-2`}
													onClick={() => handleShipmentServiceChange(key)}>
													{StorageType[key]}
												</Button > :
												< Button key={index}
													className={`btn btn-${myShipment?.services?.includes(key) ? 'success' : 'light'} me-2 mt-2`}
													onClick={() => handleShipmentServiceChange(key)}>
													{StorageType[key]}
												</Button >
										}
									</>
								)
							})
						}
					</Form.Group>

					<Form.Group className='mb-3'>
						<Form.Label>Delivery Instructions</Form.Label>
						<Form.Control as='textarea' defaultValue={myShipment?.deliveryInstructions}
							onChange={(e) => setMyShipment({ ...myShipment, deliveryInstructions: e.target.value })} />
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className='display-flex-start'>
					<Spinner display={spinner}>
						<button className='btn btn-dark ms-2' onClick={() => updateShipmentDetails()}>Update</button>
						<button className='btn btn-link text-muted' onClick={() => { showShipmentModal(false); getShipment() }} >Cancel</button>
					</Spinner>
				</Modal.Footer>
			</Modal >
		</>
	}

	const ShipmentItems = () => {
		const [spinner, showSpinner] = useState(false);
		const [items, setItems] = useState(shipment?.items);
		const [itemModal, showItemModal] = useState(false);



		const searchProducts = async (keyword) => {
			try {
				const apiName = 'api';
				const path = `/search/product?size=1000&from=0`;
				const init = {
					body: {
						sort: [
							{
								"name": {
									"order": "desc",
								}
							}
						],
						query: {
							bool: {
								must: [
									{
										match: {
											shipperId: shipment?.shipperId
										}
									}
								]
							}
						}
					},
				};
				const { hits } = await API.post(apiName, path, init);
				const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];

				return sourceData.map((item) => ({
					value: item.id,
					label: item.name,
				}));
			} catch (error) {
				console.error('ealstic error', error);
				showSpinner(false);

			}
		};

		const addShipmentItem = () => {
			let myItems = [...items];
			myItems.push({ id: 'NEW', name: 'Product', number: '' });
			setItems(myItems);
		};

		const removeShipmentItem = (index) => {
			let myItems = [...items];
			myItems.splice(index, 1);
			setItems(myItems);
		};

		const handleItemProductChange = (index, id, name) => {
			let myItems = [...items];
			myItems[index].id = id;
			myItems[index].name = name;
			setItems(myItems);
		}

		const handleItemNumberChange = (index, value) => {
			let myItems = [...items];
			myItems[index].number = value;
			setItems(myItems);
		}

		const updateShipmentItems = () => {
			showSpinner(true);
			const input = { id, items: JSON.stringify(items) }
			API.graphql({ query: updateShipment, variables: { input } }).then(async ({ data }) => {
				await calculateBilling()
				toast.success(`Package items has been updated`);
				showItemModal(false);
				showSpinner(false);
			});
		};

		return (
			<>
				<Card title='Items' bodyPadding='py-0' buttonName={(shipment?.carrier?.name?.toLowerCase() !== ('fedex' || 'usps')) && !isViewer(myUser.role) ? 'Edit' : ''} onClick={() => showItemModal(true)} >
					<ListGroup className='list-group-flush'>
						{
							shipment?.items?.map((item, index) => <ListGroupItem key={index} name={`${index + 1} - ${item?.name}`} value={item.number} />)
						}
					</ListGroup>
				</Card>

				<Modal show={itemModal} aria-labelledby='contained-modal-title-vcenter' onHide={() => showItemModal(false)}>
					<Modal.Header>
						<Modal.Title className='modal-title'>Update Package Items</Modal.Title>
						<button className='btn btn-sm btn-light mb-2' onClick={() => addShipmentItem()}>Add Item</button>
					</Modal.Header>
					<Modal.Body className='p-0'>
						<Table className='table table-sm mb-0 p-0'>
							<thead>
								<tr>
									<th className='text-center'>#</th>
									<th>Product</th>
									<th>Reference Number</th>
									<th></th>
								</tr>
							</thead>
							<tbody className='list font-size-base'>
								{
									items && items?.map((item, index) => {
										return (
											<tr key={index}>
												<td className='text-center'>{`${index + 1}`}</td>
												<td>
													<AsyncSelect cacheOptions defaultOptions
														value={{ label: item?.name || 'Search Product', value: item?.id }}
														placeholder='Search product'
														loadOptions={searchProducts}
														onChange={(e) => handleItemProductChange(index, e.value, e.label)}
													/>
												</td>
												<td>
													<input className='form-control' key={index} type='text' name={`orderNumber-${index}`} placeholder='e.g. 1112-12322' value={item?.number} onChange={(e) => handleItemNumberChange(index, e.target.value)} />
												</td>
												<td className='text-center'>
													{
														index > 0 && <button className='btn btn-sm btn-outline-secondary' onClick={() => removeShipmentItem(index)}>
															<i className='fe fe-trash'></i>
														</button>
													}
												</td>
											</tr>
										);
									})
								}
							</tbody>
						</Table>
					</Modal.Body>
					<Modal.Footer className='display-flex-start'>
						<Spinner display={spinner}>
							<button className='btn btn-dark ms-2' onClick={() => updateShipmentItems()}>Update</button>
							<button className='btn btn-link text-muted' onClick={() => showItemModal(false)} >Cancel</button>
						</Spinner>
					</Modal.Footer>
				</Modal>
			</>
		)
	}


	const MultiDimensionalShipmentItems = () => {
		const [items, setItems] = useState(shipment?.items);
		const [itemModal, showItemModal] = useState(false);
		const [itemIndex, setItemIndex] = useState();
		const [spinner, showSpinner] = useState(false);
		const [ndcAndQtyFlag, setNdcAndQtyFlag] = useState(false)
		const [packageErrors, setPackageErrors] = useState([]);

		const searchProducts = async (keyword) => {
			try {
				const apiName = 'api';
				const path = `/search/product?size=1000&from=0`;
				const init = {
					body: {
						query: {
							bool: {
								must: [
									{
										match: {
											shipperId: shipment?.shipperId
										}
									}
								]
							}
						}
					},
				};
				if (keyword) init.body.query.bool.must.push({
					"prefix": { "name": keyword.toLowerCase() }
				})
				const { hits } = await API.post(apiName, path, init);
				const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];

				return sourceData.map((item) => ({
					value: item.id,
					label: item.name,
				}));
			} catch (error) {
				console.error('ealstic error', error);
				showSpinner(false);

			}
		};

		const addShipmentItem = () => {
			let myItems = [...items[itemIndex]];
			myItems.push({ id: "NEW", name: "Product", number: "" });
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};

		const removeShipmentItem = (index) => {
			let myItems = [...items[itemIndex]];
			myItems.splice(index, 1);
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};

		const handleItemProductChange = (index, id, name) => {
			let myItems = [...items[itemIndex]];
			myItems[index].id = id;
			myItems[index].name = name;
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};

		const handleItemNumberChange = (index, value) => {
			let myItems = [...items[itemIndex]];
			myItems[index].number = value;
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};

		const handleItemNdcChange = (index, value) => {
			let myItems = [...items[itemIndex]];
			myItems[index].ndc = value;
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};

		const handleItemQtyChange = (index, value) => {
			let myItems = [...items[itemIndex]];
			myItems[index].qty = value;
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};

		const handleSelectTier = (index, value) => {
			let myItems = [...items[itemIndex]];
			myItems[index].tier = value;
			let newPackages = [...items]
			newPackages[itemIndex] = myItems
			setItems(newPackages);
		};

		const validatePackageData = () => {
			let errorList = [];
			items[itemIndex].forEach((item, index) => {
				let errors = {};
				if (!item.number || item.number.trim() === '') {
					errors.number = 'Reference no. is required';
				}

				if (!item.ndc || item.ndc.trim() === '') {
					errors.ndc = 'NDC is required';
				}

				if (!item.qty || item.qty.trim() === '') {
					errors.qty = 'QTY is required';
				}

				if (Object.keys(errors).length > 0) {
					errorList.push({ index, errors });
				}
			});
			console.error(errorList, "errorList");
			setPackageErrors(errorList);

			return errorList
		}



		const updateShipmentItems = () => {

			let error = []
			if (ndcAndQtyFlag) {
				error = validatePackageData()
			}
			if (error.length > 0) return
			showSpinner(true);
			try {
				const input = { id, items: JSON.stringify(items), packageCount: items?.length };
				API.graphql({ query: updateShipment, variables: { input } }).then(
					async ({ data }) => {
						await calculateBilling()
						toast.success(`Package items has been updated`);
						showItemModal(false);
						showSpinner(false);
					}
				);

			} catch (error) {
				showItemModal(false);
				showSpinner(false);
				console.error(error);
				toast.error('Something went wrong, please try again.');
			}
		};

		const addPackages = () => {
			items[0].some((item) => {
				if (item?.ndc || item?.qty) {
					setNdcAndQtyFlag(true)
					return
				}
			})

			let addedNewPackage = JSON.parse(JSON.stringify(items))
			if (ndcAndQtyFlag) {
				addedNewPackage.push([{
					id: 'rx',
					name: 'Product',
					number: '',
					ndc: '',
					qty: ''
				}])

			} else {
				addedNewPackage.push([{
					id: 'rx',
					name: 'Product',
					number: ''
				}])

			}

			setItems(addedNewPackage)
			setItemIndex(addedNewPackage.length - 1)
			showItemModal(true)
		}

		const handleRemovePackage = (boxIndex) => {
			swal({
				text: `If you proceed, you will lose the Package data. Are you sure you want to continue?`,
				buttons: ['No', 'Yes'],
				icon: 'error',
				dangerMode: true,
			}).then(async (status) => {
				if (status) {
					let packages = [...items];
					packages.splice(boxIndex, 1)
					try {
						showSpinner(true)
						const input = { id, items: JSON.stringify(packages), packageCount: packages.length };
						API.graphql({ query: updateShipment, variables: { input } }).then(
							async () => {
								await calculateBilling()
								toast.success(`Package items has been updated`);
								showItemModal(false);
								showSpinner(false);
							}
						);

					} catch (error) {
						showItemModal(false);
						showSpinner(false);
						console.error(error);
						toast.error('Something went wrong, please try again.');
					}
				}
			});

		}


		const ItemsContainer = ({ value, index }) => {
			return <div className='list-group-item'>
				<h5 className='my-2'>{`${index + 1} - ${value?.name}`}</h5>
				<div className="d-flex justify-content-between flex-wrap gap-1">
					<span className='small w-50 text-muted'>{value?.number}</span>
					<span className='small  text-muted'>{value?.ndc}</span>
					<span className='small text-muted'>{value?.qty}</span>
					<span className='small text-muted'>{value?.tier}</span>
				</div>

			</div>
		}


		const getErrorMessage = (index, field) => {
			const error = packageErrors.find((err) => err.index === index);
			return error ? error.errors[field] : '';
		};


		return (
			<>
				<div>
					<div className="d-flex justify-content-between py-3 align-items-center">
						<h4>
							{`Package Content (${items?.length})`}
						</h4>
						{
							items?.length < 5 && (!isViewer(myUser?.role)) && ['OPEN', 'READY_FOR_PICKUP']?.includes(shipment?.status) &&
							<button className="btn btn-light btn-sm" onClick={() => addPackages()} >Add Package</button>
						}
					</div>
					{shipment?.items?.map((box, boxIndex) => (
						<div className="card" key={boxIndex}>
							<div div className="card-header d-flex justify-content-between pb-0" >
								<h4>{`Package ${boxIndex + 1}`}</h4>
								<div >
									{
										['OPEN', 'READY_FOR_PICKUP']?.includes(shipment?.status) && (!isViewer(myUser?.role)) &&
										<>
											<div className="btn btn-light btn-sm me-2" onClick={() => {
												showItemModal(true);
												setItemIndex(boxIndex);
												items[0]?.some((item) => {
													if (item?.ndc || item?.qty) {
														setNdcAndQtyFlag(true)
														return
													}
												})
											}}> <i className="fe fe-edit-3"></i> </div>
											{items?.length > 1 &&
												<div className="btn btn-light btn-sm" onClick={() => handleRemovePackage(boxIndex)} > <i className="fe fe-trash"></i> </div>
											}
										</>
									}
								</div>
							</div>

							<div className="card-body px-lg-3 px-xl-4 px-md-4 px-sm-4 py-0">

								<ListGroup className="list-group-flush py-0" key={boxIndex}>
									{
										box?.map((item, index) => (

											<>
												{
													(item?.ndc || item?.qty) ? <ItemsContainer
														key={index}
														value={item}
														index={index}
													/> :
														<ListGroupItem
															key={index}
															name={`${index + 1} - ${item?.name}`}
															value={item.number}
														/>
												}
											</>
										))
									}
								</ListGroup>
							</div>
						</div >
					))}

				</div >

				<Modal
					size='xl'
					show={itemModal}
					aria-labelledby="contained-modal-title-vcenter"
					onHide={() => { showItemModal(false); setItems(shipment?.items); setNdcAndQtyFlag(false) }}
				>
					<Modal.Header>
						<Modal.Title className="modal-title">
							Update Package Items
						</Modal.Title>
						<button
							className="btn btn-sm btn-light mb-2"
							onClick={() => addShipmentItem()}
						>
							Add Item
						</button>
					</Modal.Header>
					<Modal.Body className="p-0">
						<Table className="table table-sm mb-0 p-0">
							<thead>
								<tr>
									<th className="text-center">#</th>
									<th>Product</th>
									<th>Reference Number</th>
									{
										ndcAndQtyFlag && <>
											<th className="text-center"> NDC</th>
											<th className="text-center"> QTY</th>
											<th className="text-center"> PhoxTail Guarantee</th>
										</>
									}
									<th></th>
								</tr>
							</thead>
							<tbody className="list font-size-base">
								{items &&
									items[itemIndex]?.map((item, index) => {
										return (
											<tr key={index}>
												<td className="text-center">{`${index + 1}`}</td>
												<td>
													<AsyncSelect
														cacheOptions
														defaultOptions
														value={{
															label: item?.name || "Search Product",
															value: item?.id,
														}}
														placeholder="Search product"
														loadOptions={searchProducts}
														onChange={(e) =>
															handleItemProductChange(index, e.value, e.label)
														}
													/>
												</td>
												<td>
													<input
														className={`${getErrorMessage(index, 'number') && "mt-4"}  form-control`}
														key={index}
														type="text"
														name={`orderNumber-${index}`}
														placeholder="e.g. 1112-12322"
														value={item?.number}
														onChange={(e) =>
															handleItemNumberChange(index, e.target.value)
														}
													/>
													<FormError error={getErrorMessage(index, 'ndc')} />
												</td>
												{(ndcAndQtyFlag || (item?.ndc || item?.qty)) && <>
													<td>
														<input
															className={`${getErrorMessage(index, 'ndc') && "mt-4"}  form-control`}
															key={index}
															type='text'
															name={`ndc-${index}`}
															placeholder='e.g. Metformin'
															value={item?.ndc}
															onChange={(e) =>
																handleItemNdcChange(index, e.target.value)
															}
														/>
														<FormError error={getErrorMessage(index, 'ndc')} />
													</td>
													<td>
														<input
															className={`${getErrorMessage(index, 'qty') && "mt-4"}  form-control`}
															key={index}
															type='text'
															name={`qty-${index}`}
															placeholder='e.g. 100gm'
															value={item?.qty}
															onChange={(e) =>
																handleItemQtyChange(index, e.target.value)
															}
														/>
														<FormError error={getErrorMessage(index, 'qty')} />
													</td>
													<td>
														<Form.Select value={item?.tier} onChange={(e) => handleSelectTier(index, e.target.value)}>
															<option key={0} value={0}>Tier 0: $100 </option>
															<option key={1} value={1}>Tier 1: $5,000 </option>
															<option key={2} value={2}>Tier 2: $20,000</option>
															<option key={3} value={3}>Tier 3: $40,000 </option>
															<option key={4} value={4}>Tier 4: $60,000 </option>
														</Form.Select>
													</td>
												</>
												}
												<td className="text-center">
													{index > 0 && (
														<button
															className="btn btn-sm btn-outline-secondary"
															onClick={() => removeShipmentItem(index)}
														>
															<i className="fe fe-trash"></i>
														</button>
													)}
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</Modal.Body>
					<Modal.Footer className="display-flex-start">
						<Spinner display={spinner}>
							<button
								className="btn btn-dark ms-2"
								onClick={() => updateShipmentItems()}
							>
								Update
							</button>
							<button
								className="btn btn-link text-muted"
								onClick={() => { showItemModal(false); getShipment(); setNdcAndQtyFlag(false); setPackageErrors([]) }}
							>
								Cancel
							</button>
						</Spinner>
					</Modal.Footer>
				</Modal >
			</>
		);
	};


	const ShipmentRating = () => {
		const [ratingModal, showRatingModal] = useState()
		const [errors, setErrors] = useState({ rating: '' })
		// eslint-disable-next-line no-unused-vars
		const [rating, setRating] = useState(shipment?.ratings?.items?.length > 0 ? shipment?.ratings?.items[0] : null)
		const [shipmentRating, setShipmentRating] = useState({
			rating: '',
			nps: '',
			feedback: ''
		})

		const validateShipmentRating = () => {
			if (!shipmentRating?.rating) {
				setErrors({ rating: 'Required.' })
			} else {
				setErrors({ rating: '' })
			}
		}

		const handelUpdateRating = async () => {
			showSpinner(true)
			await validateShipmentRating()
			const createRating = {
				id: uniqid(),
				shipmentId: shipment?.id,
				rating: shipmentRating?.rating,
				nps: shipmentRating?.nps || 0,
				feedback: shipmentRating?.feedback || '',
				createdTime: moment().unix(),
				shipperGroupId: shipment?.shipperGroupId || null,
				shipperId: shipment?.shipperId || null,
			};
			if (!errors?.rating) {
				if (shipment?.ratings?.items?.length > 0) {
					API.graphql({ query: updateShipmentRating, variables: { input: shipmentRating } })
						.then((response) => {
							toast.success('Customer Rating has been Updated succesfully')
							showSpinner(false)
							getShipment()
							showRatingModal(false)
						})
				} else {
					API.graphql({ query: createShipmentRating, variables: { input: createRating } })
						.then((response) => {
							toast.success('Customer Rating has been Updated succesfully')
							showSpinner(false)
							getShipment()
							showRatingModal(false)
						})
				}
			}
		}

		return (
			<>
				<Card title='Customer Rating' buttonName={shipment?.history?.[0]?.status === 'DELIVERED' ? 'Edit' : null} onClick={() => { shipment?.ratings?.items?.length > 0 ? setShipmentRating(shipment?.ratings?.items[0]) : setShipmentRating({ ...rating, id: uniqid() }); showRatingModal(true) }}>
					<p>{rating?.feedback || '-'}</p>
					<Row>
						<Col>
							<StarRatings rating={rating?.rating || 0} starRatedColor='#FFCD3C' starDimension='24px' starSpacing='1px' />
						</Col>
						<Col className='col-auto'>
							{rating?.nps ? <div className={`nps nps-${(rating?.nps || 0) * 10}`}></div> : ''}
						</Col>
					</Row>
				</Card>
				<Modal show={ratingModal}>
					<Modal.Header>
						<Modal.Title className='modal-title'>Update Rating</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className='mb-3'>
							<Form.Label className='m-0' onWheel={(e) => e.target.blur()}>Rating<span className='text-danger'>*</span></Form.Label>
							<Form.Control type='number' placeholder='Enter Rating 1 ot 5' name='rating' defaultValue={shipmentRating?.rating} value={shipmentRating?.rating ? shipmentRating?.rating : ''}
								onKeyPress={(e) => {
									if (e.key === 'e' || e.key === 'E' || e.key === '.' || e.key === '-') {
										e.preventDefault();
									}
								}}
								onChange={(e) => {
									const inputValue = e.target.value;
									if (/^[1-5]$/.test(inputValue) || inputValue === '') {
										setShipmentRating({ ...shipmentRating, rating: inputValue });
									}
								}} />
							<FormError error={errors?.rating} className='m-0' />
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label className='m-0' onWheel={(e) => e.target.blur()}>NPS</Form.Label>
							<Form.Control type='number' placeholder='Enter NPS 1 ot 10' name='nps' defaultValue={shipmentRating?.nps} value={shipmentRating?.nps ? shipmentRating?.nps : ''}
								onKeyPress={(e) => {
									if (e.key === 'e' || e.key === 'E' || e.key === '.' || e.key === '-') {
										e.preventDefault();
									}
								}}
								onChange={(e) => {
									const inputValue = e.target.value;
									if (/^(?:[1-9]|10)$/.test(inputValue) || inputValue === '') {
										setShipmentRating({ ...shipmentRating, nps: inputValue });
									}
								}} />
						</Form.Group>
						<Form.Group className='mb-3'>
							<Form.Label className='m-0'>Feedback</Form.Label>
							<Form.Control as='textarea' name='feedback' defaultValue={shipmentRating?.feedback}
								onChange={(e) => setShipmentRating({ ...shipmentRating, feedback: e.target.value })} />
						</Form.Group>
					</Modal.Body>
					<Modal.Footer className='display-flex-start'>
						<Spinner display={spinner}>
							<button className='btn btn-dark ms-2' onClick={() => handelUpdateRating()}>Update</button>
							<button onClick={() => showRatingModal(false)} className='btn btn-link text-muted'>Cancel</button>
						</Spinner>
					</Modal.Footer>
				</Modal>
			</>

		)
	}

	const RecentActivity = () => {
		const [spinner, showSpinner] = useState(false);
		const [statusHistoryModal, showStatusHistoryModal] = useState(false);
		const [history, setHistory] = useState();
		const updateStatusHistory = () => {
			showSpinner(true);
			const input = { id: history.id, description: history.description }
			API.graphql({ query: updateShipmentStatusHistory, variables: { input } }).then(() => {
				toast.success('Status history has been updated');
				getShipment();
				showStatusHistoryModal(false);
			});
		}

		return <>
			<Card title='Recent Activity'>
				<ListGroup className='list-group-flush list-group-activity my-n3'>
					{
						shipment?.history && shipment?.history.sort((a, b) => b.createdTime - a.createdTime).map((item, index) => {
							return (
								<ListGroup.Item key={index}>
									<Row>
										<Col className='col-auto'>
											<div className='avatar avatar-sm'>
												<div className={`avatar-title fs-lg badge bg-${statusBadgeColor(item?.status)} rounded-circle`}>
													<i className={statusIcon(item?.status)}></i>
												</div>
											</div>
										</Col>
										<Col>
											{ShipmentStatus[item.status]}
											<div className='small text-muted'>
												<div>{item.description}</div>
												<div className='mt-2'>
													<i className='fe fe-user' /> {item?.user?.name || 'Admin'}
												</div>
											</div>
										</Col>
										<Col className='col-auto p-0'>
											<Button className='btn btn-sm btn-light' onClick={() => { setHistory(item); showStatusHistoryModal(true); }}>
												<i className='fe fe-edit-2' />
											</Button>
										</Col>
										<Col className='col-auto'>
											<div className='small'>{toLocalTime(item?.createdTime, shipment?.shipFrom?.timezone?.id)}</div>
											<div className='small text-muted'>{toLocalDate(item?.createdTime, shipment?.shipFrom?.timezone?.id)}</div>
										</Col>
									</Row>
								</ListGroup.Item>
							)
						})
					}
				</ListGroup>
			</Card>

			<Modal show={statusHistoryModal}>
				<Modal.Header>
					<Modal.Title className='modal-title'>Update Status History</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<Form.Label>Description</Form.Label>
						<Form.Control as='textarea' name='statusDescription' defaultValue={history?.description}
							onChange={(e) => setHistory({ ...history, description: e.target.value })} />
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className='display-flex-start'>
					<Spinner display={spinner}>
						<button className='btn btn-dark ms-2' onClick={() => updateStatusHistory()}>Update</button>
						<button onClick={() => showStatusHistoryModal(false)} className='btn btn-link text-muted'>Cancel</button>
					</Spinner>
				</Modal.Footer>
			</Modal>
		</>
	}

	const CarrierDriver = () => {
		const [spinner, showSpinner] = useState(false);
		const [driverModal, showDriverModal] = useState(false);
		const [qrModal, OpenQrModal] = useState(false);
		const [driverId, setDriverId] = useState(shipment?.driverId);
		const [driverList, setDriverList] = useState(drivers);
		const [carrierId, setCarrierId] = useState(shipment?.carrierId);
		const [serviceList, setServicelist] = useState(deliveryServices)
		const [deliveryServiceId, setDeliveryServiceId] = useState(shipment?.deliveryServiceId)
		const [expectedPickupDeliveryTime, setExpectedPickupDeliveryTime] = useState({
			expectedPickupTime: shipment?.expectedPickupTime,
			expectedDeliveryTime: shipment?.expectedDeliveryTime,
			type: shipment?.deliveryService?.type
		})

		const [weight, setWeigth] = useState(shipment?.weight || null)
		const [errors, setErrors] = useState({})

		useEffect(() => {
			getCarrierDrivers(carrierId)
			let services = deliveryServices?.filter(item => item?.carrier?.id === carrierId)
			setServicelist(services)
		}, [carrierId])

		let carrier = carrierlist.filter((item) => item.id === carrierId)

		const getServiceId = (carrierId) => {
			setCarrierId(carrierId)
			showDriverModal(true)
			let services = deliveryServices.filter(item => item.carrier.id === carrierId)
			setServicelist(services)
		}

		const getCarrierDrivers = () => {
			API.graphql({ query: carrierUsersByCarrierId, variables: { carrierId } })
				.then(({ data }) => setDriverList(data?.carrierUsersByCarrierId?.items.sort((a, b) => a?.user?.name?.localeCompare(b?.user?.name, undefined, { sensitivity: 'accent' })))?.filter((item) => item?.user?.active === true))
				.catch((err) => console.log(err))
		}

		let deliveryType;
		if (carrier[0]?.name === 'FedEx') {
			deliveryType = deliveryServices.filter(item => item.id === deliveryServiceId)
			deliveryType = deliveryType[0]?.name === 'FedEx Standard Overnight' ? 'STANDARD_OVERNIGHT' : 'GROUND_HOME_DELIVERY'
		}

		const getPickupDeliveryTime = (deliveryServiceid) => {
			setDeliveryServiceId(deliveryServiceid)
			let deliveryServiceArray = deliveryServices.filter(item => item.id === deliveryServiceid)
			let deliveryService = deliveryServiceArray[0];

			let expectedPickupTime = null
			let expectedDeliveryTime = null


			if (deliveryService?.type === 'TODAY') {
				expectedPickupTime = moment.unix(shipment?.createdTime).add(1, 'hours')
				expectedDeliveryTime = moment.unix(shipment?.createdTime).add(deliveryService.tatMax + 1, 'hours')
			} else if (deliveryService?.type === 'TOMORROW') {
				const dateString = moment.unix(shipment?.createdTime).add(1, 'days').format('YYYY-MM-DD')
				const expectedPickupString = `${dateString} ${deliveryService?.pickupBy}`
				expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
				expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(deliveryService?.tatMax, 'hours')
			} else if (deliveryService?.type === 'MONDAY') {
				const dateString = moment.unix(shipment?.createdTime).add(8 - moment().isoWeekday(), 'days').format('YYYY-MM-DD')
				const expectedPickupString = `${dateString} ${deliveryService?.pickupBy}`
				expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
				expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(deliveryService?.tatMax, 'hours')
			}

			setExpectedPickupDeliveryTime((prev) => ({
				...prev,
				expectedPickupTime: expectedPickupTime ? expectedPickupTime?.unix() : null,
				expectedDeliveryTime: expectedDeliveryTime ? expectedDeliveryTime?.unix() : null,
				type: deliveryService?.type
			}));
		}

		const handlePickupDateChange = async (selectedDates, dateStr) => {
			const expectedPickupString = `${dateStr} ${shipment?.deliveryService?.pickupBy}`
			const expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
			const expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(shipment?.deliveryService?.tatMax, 'hours')

			setExpectedPickupDeliveryTime((prev) => ({
				...prev,
				expectedPickupTime: expectedPickupTime.unix(),
				expectedDeliveryTime: expectedDeliveryTime.unix()
			}));
		}
		const isFormValid = async () => {
			let error = {};

			let selectedCarrier = carrierlist?.find((item) => item.id === carrierId)
			if (shipment?.shipFrom?.alias?.toLowerCase() === "aurora mail order pharmacy" && ['WI', "IL"].includes(shipment?.shipTo?.address?.state)) {
				if (selectedCarrier?.name?.includes("WI")) {
					let distance = await getDistanceFromHere(shipment?.shipTo?.address?.location, shipment?.shipFrom?.address?.location);
					if (distance > 55) {
						error.carrier = 'Distance is more then 55 mi, please select another Courier';
					}
				} else if (selectedCarrier?.name?.includes("IL")) {
					const chicagoLocation = { latitude: 42.047586035877956, longitude: -87.98072053317183 }
					const chicagoDistance = await getDistanceFromHere(chicagoLocation, shipment?.shipTo?.address?.location);
					if (chicagoDistance > 55) {
						error.carrier = 'Distance is more then 55 mi, please select another Courier';
					}
				}
			}

			if (!driverId && carrier[0]?.name !== 'FedEx') error.driver = 'Please select a driver';
			if (!deliveryServiceId) error.service = 'Please select a Service';
			if (!carrierId) error.carrier = 'Please select a Courier';

			if (carrier[0]?.name === 'FedEx') {
				if (!weight) error.weight = 'Weight is required'
				if (weight < 0) error.weight = 'Please enter a valid weight'
				if (weight?.toString()?.trim()?.startsWith('.')) error.weight = 'Please enter a valid weight'
			}
			if (expectedPickupDeliveryTime.type === 'FUTURE' && (!expectedPickupDeliveryTime.expectedDeliveryTime && !expectedPickupDeliveryTime.expectedPickupTime)) error.date = 'Please select a future date';
			setErrors(error);
			console.log(error)
			return Object.keys(error).length === 0
		}


		const updateDriver = async () => {
			let error = await isFormValid()
			if (error) {
				showSpinner(true);
				API.graphql({
					query: updateShipment, variables: {
						input: {
							id: id,
							driverId,
							carrierId,
							deliveryServiceId,
							expectedPickupTime: expectedPickupDeliveryTime?.expectedPickupTime,
							expectedDeliveryTime: expectedPickupDeliveryTime?.expectedDeliveryTime
						}
					}
				}).then(async () => {
					let task = []
					// #region FedEx
					if (carrier[0]?.name === 'FedEx') {
						task.push(API.graphql({ query: createFedexLabelMutation, variables: { id: id } }));
					}

					task.push(await API.graphql({ query: sendCarrierNotificationMutation, variables: { shipmentId: id } }))
					Promise.all(task);
					await calculateBilling()
					toast.success('Shipment has been updated')
					showDriverModal(false);
				})
				// #endregion
			}
		}

		let cardButtons = [{ buttonName: <img src='/img/qricon.png' alt='qrIcon' style={{ height: '20px' }} />, onClick: () => OpenQrModal(true) }, { buttonName: 'Edit', onClick: () => showDriverModal(true) }]

		return <>
			<MultiButtonCard title='Courier / Driver' buttons={!isViewer(myUser.role) && cardButtons} >
				<div className=''>
					<ListGroupItem name="Courier Name" value={shipment?.carrier ? shipment?.carrier?.name : "-"} />
					<hr />
					<ListGroupItem name="Driver Name" value={(shipment?.carrier?.name === "FedEx" || shipment?.carrier?.name === "USPS" || shipment?.carrier?.name === "UPS") ? '' : shipment?.driver?.name || "Unassigned"} />
					{shipment?.status !== 'OPEN' &&
						<>
							<hr />
							<ShipmentStats shipment={shipment} id={id} />
						</>
					}
				</div>
			</MultiButtonCard>

			<Modal
				show={qrModal}
				aria-labelledby="contained-modal-title-vcenter"
				onHide={() => OpenQrModal(false)} >
				<Modal.Header>
					<Modal.Title className="modal-title">
						QR Code
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{qrCode && (
						<div className="text-center">
							<QRCode.QRCodeSVG value={qrCode} size={150} />
						</div>
					)}
				</Modal.Body>
				<Modal.Footer className="display-flex-start">
					<button onClick={() => { OpenQrModal(false) }} className="btn btn-outline-light text-muted">
						Close
					</button>
				</Modal.Footer>
			</Modal >

			<Modal show={driverModal}>
				<Modal.Header>
					<Modal.Title className='modal-title'>Update Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<FormLabel required={true}>Courier</FormLabel>
						<Form.Select className='phox-form-select' value={carrierId} onChange={(e) => getServiceId(e.target.value)} isInvalid={!!errors?.carrier}>
							<option value=''>Select Courier</option>
							{
								carrierlist?.map((carrier, index) => <option key={index} value={carrier.id}>{carrier?.name}</option>)
							}
						</Form.Select>
						<Form.Control.Feedback type='invalid'>{errors?.carrier}</Form.Control.Feedback>
					</Form.Group>

					<Form.Group className='mb-3'>
						<FormLabel required>Delivery Service </FormLabel>
						<Form.Select value={deliveryServiceId}
							onChange={(e) => getPickupDeliveryTime(e.target.value)}
							isInvalid={!!errors?.service}>
							<option value='' >Select Delivery Service</option>
							{
								serviceList.map((item, index) => <option key={index} value={item?.id}>{item?.name}</option>)
							}
						</Form.Select>
						<FormError error={errors?.service} />
					</Form.Group>


					{carrier[0]?.name === 'FedEx' &&
						<Form.Group className='mb-3' >
							<FormLabel required>Weight </FormLabel>
							<Form.Control type='number' placeholder='e.g. 2' defaultValue={shipment?.weight || ''} onChange={(e) => setWeigth(e.target.value)} onWheel={(e) => e.target.blur()} />
							<FormError error={errors?.weight} />
							<Form.Control.Feedback type='invalid'>{errors?.weight}</Form.Control.Feedback>
						</Form.Group>
					}

					{
						expectedPickupDeliveryTime.type === 'FUTURE' && <div>
							<hr />
							<FormLabel required>Deliver On </FormLabel>
							<Form.Group className='mb-3 row'>
								<Flatpickr className='form-control'
									onChange={handlePickupDateChange}
									value={moment.unix(expectedPickupDeliveryTime?.expectedPickupTime).toDate()}
									options={{
										altInput: true,
										altFormat: 'F j, Y',
										minDate: moment().toDate(),
										static: true
									}}>
									<input data-input className='form-input' />
								</Flatpickr>
							</Form.Group>
							<FormError error={errors?.date} />
						</div>
					}

					{carrier[0]?.name !== 'FedEx' &&

						<>
							<Form.Group className='mb-4'>
								<FormLabel required={true}>Driver</FormLabel>
								<Form.Select className='phox-form-select' value={driverId} onChange={(e) => setDriverId(e.target.value)} isInvalid={!!errors?.driver}>
									<option value=''>Select Driver</option>
									{
										driverList?.map((driver, index) => <option key={index} value={driver.userId}>{driver?.user?.name}</option>)
									}
								</Form.Select>
								<Form.Control.Feedback type='invalid'>{errors?.driver}</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className='mb-3'>
								<FormLabel required={true}>Estimated Pickup By</FormLabel>
								<div className='border p-3  rounded'> {toLocalDateTime(expectedPickupDeliveryTime?.expectedPickupTime, shipment?.shipFrom?.timezone?.id)}   </div>
							</Form.Group>

							<Form.Group className='mb-3'>
								<FormLabel required={true}>Estimated Delivery By</FormLabel>
								<div className='border p-3  rounded'>{toLocalDateTime(expectedPickupDeliveryTime?.expectedDeliveryTime, shipment?.shipFrom?.timezone?.id)}   </div>
							</Form.Group>
						</>
					}
				</Modal.Body>
				<Modal.Footer className='display-flex-start'>
					<Spinner display={spinner}>
						<button className='btn btn-dark ms-2' onClick={() => updateDriver()}>Update</button>
						<button className='btn btn-link text-muted'
							onClick={() => {
								showDriverModal(false);
								setErrors();
								getDeliveryServices(shipment?.shipperId, shipment.isGreenPhox)
								setCarrierId(shipment?.carrierId)
								setDeliveryServiceId(shipment?.deliveryServiceId)
								setDriverId(shipment?.driverId);
								setExpectedPickupDeliveryTime({
									expectedPickupTime: shipment?.expectedPickupTime,
									expectedDeliveryTime: shipment?.expectedDeliveryTime,
									type: shipment?.deliveryService?.type
								})
							}} >Cancel</button>
					</Spinner>
				</Modal.Footer>
			</Modal>
		</>
	}

	const DeliveryProof = () => {
		const [spinner, showSpinner] = useState(false);
		const [deliveryProofModal, showDeliveryProofModal] = useState(false);
		const [history, setHistory] = useState();
		const [imageIndex, setImageIndex] = useState(0);
		const [attachment, setAttachment] = useState({});

		useEffect(() => {
			const index = shipment?.history?.findIndex(x => x.status === 'DELIVERED' || 'RETURNED');
			if (index >= 0) {
				const historyItem = shipment?.history[index];
				if (historyItem.signature && typeof historyItem.signature === 'string') {
					historyItem.signature = JSON.parse(historyItem.signature);
				}
				setHistory(historyItem);
			}
		}, []);

		const updateDeliveryProof = () => {
			showSpinner(true);
			let myAttachment = shipment.attachment || [];
			myAttachment = myAttachment.concat(Object.values(attachment));

			API.graphql({ query: updateShipment, variables: { input: { id, attachment: myAttachment } } });

			const input = { id: history.id, signature: JSON.stringify(history.signature) };
			API.graphql({ query: updateShipmentStatusHistory, variables: { input } }).then(() => {
				toast.success('Proof of delivery has been updated');
				getShipment();
				showDeliveryProofModal(false);
			});
		};

		const processFile = ({ file }) => {
			const ext = file.name.split('.').pop();
			return { file, key: `${uniqid()}.${ext}` };
		};

		const onUploadSuccess = ({ key }) => {
			Storage.get(key).then(url => {
				setAttachment(prevAttachment => ({ ...prevAttachment, [key]: url.split('?')[0] }));
			});
		};

		const handleSlide = currentIndex => setImageIndex(currentIndex)

		const removeImg = async () => {
			swal({
				text: `Do you want to remove the Image?`,
				buttons: ['No', 'Yes'],
				icon: 'error',
				dangerMode: true
			}).then(async status => {
				if (status) {
					const filteredDeliveryProofs = shipment?.attachment.filter((item, index) => index !== imageIndex);
					const attachments = filteredDeliveryProofs.slice();
					API.graphql({ query: updateShipment, variables: { input: { id, attachment: attachments } } }).then(() => {
						toast.success('Proof of delivery has been removed');
						getShipment();
					});
				}
			});
		};

		let closedByOtp = false;
		const otp = shipment?.history?.find(item => item?.description?.toLowerCase() === "closed by pin");
		if (otp) { closedByOtp = true; }

		return <>
			<Card title='Proof of Delivery' bodyPadding='py-0' buttonName={history?.id && !isViewer(myUser.role) ? 'Edit' : null} onClick={() => showDeliveryProofModal(true)}>
				<ListGroup className='list-group-flush'>
					<ListGroupItem key='name' name='Signed By' value={history?.signature?.name || '-'} />
					<ListGroupItem key='relationship' name='Relationship' value={history?.signature?.relationship || '-'} />
					<ListGroupItem key='reason' name='Reason' value={history?.signature?.reason || '-'} />
					<ListGroupItem key='verification_code' name='One-Time Passcode' value={closedByOtp ? shipment?.authCode : '-'} />
				</ListGroup>
				<div className='position-relative'>
					{
						shipment?.attachment?.length > 0 &&
						<i className='fe fe-x-circle img-delete-btn p-1 position-absolute top-0 right-0 cursor-pointer' style={{ right: '0', zIndex: '999' }}
							onClick={() => removeImg()} />
					}
					{
						shipment?.attachment?.length > 0 && <div className='position-relative my-3'>
							<ImageGallery showBullets={false} showThumbnails={false} lazyLoad={true} showPlayButton={false} onSlide={handleSlide}
								items={shipment?.attachment?.map((item, index) => ({ original: item }))} />
						</div>
					}
				</div>
			</Card>

			<Modal show={deliveryProofModal}>
				<Modal.Header>
					<Modal.Title className='modal-title'>Proof Of Delivery</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<FormLabel>Signed By</FormLabel>
						<Form.Control type='text' placeholder='e.g. John doe' defaultValue={history?.signature?.name}
							onChange={(e) => setHistory({ ...history, signature: { ...history?.signature, name: e.target.value } })} />
					</Form.Group>
					<Form.Group className='mb-3'>
						<FormLabel>Relationship</FormLabel>
						<Form.Select defaultValue={history?.signature?.relationship}
							onChange={(e) => setHistory({ ...history, signature: { ...history?.signature, relationship: e.target.value } })}>
							<option value=''>Select Relationship</option>
							{
								Object.keys(PodRelationship).map((key) => <option key={key} value={PodRelationship[key]}>{PodRelationship[key]}</option>)
							}
						</Form.Select>
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Label>Reason</Form.Label>
						<Form.Control as='textarea' placeholder='e.g. Infection, Disability' defaultValue={history?.signature?.reason}
							onChange={(e) => setHistory({ ...history, signature: { ...history?.signature, reason: e.target.value } })} />
					</Form.Group>

					<StorageManager
						acceptedFileTypes={['.png', '.jpeg', '.jpg']}
						accessLevel='public' maxFileCount={3}
						showImages={false} path={`shipment/${id}/`}
						processFile={processFile} onUploadSuccess={onUploadSuccess}
					/>

				</Modal.Body>
				<Modal.Footer className='display-flex-start'>
					<Spinner display={spinner}>
						<button className='btn btn-dark ms-2' onClick={() => updateDeliveryProof()}>Update</button>
						<button className='btn btn-link text-muted' onClick={() => { showDeliveryProofModal(false); getShipment() }}>Cancel</button>
					</Spinner>
				</Modal.Footer>
			</Modal>
		</>
	}

	const ShipperBilling = ({ shipment }) => {

		const [shipperBilling, setShipperBilling] = useState(JSON.parse(shipment?.billing_shipper));
		const [billingModal, showBillingModal] = useState(false)
		const [accordion, openAccordion] = useState(false)
		const [errors, setErrors] = useState({});

		const validateBilling = () => {
			const validateNumber = (value) => { return value !== null && value !== undefined && !isNaN(value) && value !== ''; }

			let error = {};
			if (!validateNumber(shipperBilling?.delivery_fee)) error.delivery_fee = "Please enter a valid numbers"
			if (!validateNumber(shipperBilling?.processing_fee)) error.processing_fee = "Please enter a valid numbers"
			if (!validateNumber(shipperBilling?.green_phox_fee)) error.green_phox_fee = "Please enter a valid numbers"
			if (!validateNumber(shipperBilling?.phox_tail_fee)) error.phox_tail_fee = "Please enter a valid numbers"
			if (!validateNumber(shipperBilling?.return_fee)) error.return_fee = "Please enter a valid numbers"
			if (!validateNumber(shipperBilling?.fuel_surcharge)) error.fuel_surcharge = "Please enter a valid numbers"
			if (!validateNumber(shipperBilling?.other_fee)) error.other_fee = "Please enter a valid numbers"

			setErrors(error)
			return error
		}

		const updateShipperbilling = async () => {
			let error = validateBilling()
			if (!Object.keys(error).length <= 0) return

			let billingInput = JSON.stringify({
				delivery_fee: shipperBilling?.delivery_fee,
				processing_fee: shipperBilling?.processing_fee,
				green_phox_fee: shipperBilling?.green_phox_fee,
				phox_tail_fee: shipperBilling?.phox_tail_fee,
				return_fee: shipperBilling?.return_fee,
				fuel_surcharge: shipperBilling?.fuel_surcharge,
				other_fee: shipperBilling?.other_fee,
				total: Number(shipperBilling?.delivery_fee) + Number(shipperBilling?.fuel_surcharge) + Number(shipperBilling?.processing_fee) + Number(shipperBilling?.green_phox_fee) + Number(shipperBilling?.phox_tail_fee) + Number(shipperBilling?.return_fee) + Number(shipperBilling?.other_fee)
			})
			showBillingModal(false)
			swal({
				text: `Are you sure you want to continue?`,
				buttons: ['No', 'Yes'],
				icon: 'warning',
				dangerMode: true
			}).then(async status => {
				if (status) {
					showSpinner(true);
					try {
						await API.graphql({ query: updateShipment, variables: { input: { id, billing_shipper: billingInput } } })
						toast.success("Courier billing has been updated")
						getShipment();
					} catch (error) {
						handleApiError(error);
						showSpinner(false);
					}
				}
			});
		}

		function CustomToggle({ children, eventKey }) {
			const decoratedOnClick = useAccordionButton(eventKey, () =>
				console.log('totally custom!'),
			);

			return (
				<button
					type="button"
					className='btn btn-sm btn-white border-0'
					onClick={() => { decoratedOnClick(); openAccordion(!accordion) }}
				>
					{children}
				</button>
			);
		}

		return <>

			<Accordion>
				<div className="card" >
					<div className="card-header">
						<h4 className='card-header-title'>Shipper Billing</h4>

						<Col className='col-auto mt-3 me-2'>
							<h5> {"$" + Number(shipperBilling?.total).toFixed(2) ?? 0.00}  </h5>
						</Col>
						<Col className='col-auto mx-2'>
							<Button className='btn btn-sm btn-light' onClick={() => calculateBilling()} >
								<i className='fe fe-rotate-cw'></i>
							</Button>
						</Col>
						<Col className='col-auto mx-2'>
							<Button className='btn btn-sm btn-light' onClick={() => showBillingModal(true)}>
								<i className='fe fe-edit'> </i>
							</Button>
						</Col>
						<Col className='col-auto mx-2'><CustomToggle eventKey="0"> <i className={`fe fe-${accordion ? "chevron-up" : "chevron-down"}`}></i> </CustomToggle></Col>

					</div>
					<Accordion.Collapse eventKey="0">
						<div className='card-body'>
							<ListGroup className='list-group-flush'>
								<ListGroupItem name='Delivery Fee' value={"$" + Number(shipperBilling?.delivery_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Processing Charge' value={"$" + Number(shipperBilling?.processing_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='GreenPhox Fee' value={"$" + Number(shipperBilling?.green_phox_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Phox Tail Fee' value={"$" + Number(shipperBilling?.phox_tail_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Return Fee' value={"$" + Number(shipperBilling?.return_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Fuel Surcharge' value={"$" + Number(shipperBilling?.fuel_surcharge).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Other Fee' value={"$" + Number(shipperBilling?.other_fee).toFixed(2) ?? 0.00} />
								<ListGroupItem name='Total' value={"$" + Number(shipperBilling?.total).toFixed(2) ?? 0.00} />
							</ListGroup>
						</div>
					</Accordion.Collapse>
				</div>
			</Accordion>

			<Modal show={billingModal}>
				<Modal.Header>
					<Modal.Title className='modal-title'>Update Billing</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<FormLabel>Delivery Fee</FormLabel>
						<Form.Control type='number' onWheel={(e) => e.target.blur()} defaultValue={shipperBilling?.delivery_fee} onChange={(e) => setShipperBilling({ ...shipperBilling, delivery_fee: e.target?.value })} />
						<FormError error={errors.delivery_fee} />
					</Form.Group>
					<Form.Group className='mb-3'>
						<FormLabel>Processing Charge</FormLabel>
						<Form.Control type='number' onWheel={(e) => e.target.blur()} defaultValue={shipperBilling?.processing_fee} onChange={(e) => setShipperBilling({ ...shipperBilling, processing_fee: e.target?.value })} />
						<FormError error={errors.processing_fee} />
					</Form.Group>
					<Form.Group className='mb-3'>
						<FormLabel>Fuel Surcharge</FormLabel>
						<Form.Control type='number' onWheel={(e) => e.target.blur()} defaultValue={shipperBilling?.fuel_surcharge} onChange={(e) => setShipperBilling({ ...shipperBilling, fuel_surcharge: e.target?.value })} />
						<FormError error={errors.fuel_surcharge} />
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Label> GreenPhox Fee</Form.Label>
						<Form.Control type='number' onWheel={(e) => e.target.blur()} defaultValue={shipperBilling?.green_phox_fee} onChange={(e) => setShipperBilling({ ...shipperBilling, green_phox_fee: e.target?.value })} />
						<FormError error={errors.green_phox_fee} />
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Label>Phox Tail Fee</Form.Label>
						<Form.Control type='number' onWheel={(e) => e.target.blur()} defaultValue={shipperBilling?.phox_tail_fee} onChange={(e) => setShipperBilling({ ...shipperBilling, phox_tail_fee: e.target?.value })} />
						<FormError error={errors.phox_tail_fee} />
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Label>Return Fee</Form.Label>
						<Form.Control type='number' onWheel={(e) => e.target.blur()} defaultValue={shipperBilling?.return_fee} onChange={(e) => setShipperBilling({ ...shipperBilling, return_fee: e.target?.value })} />
						<FormError error={errors.return_fee} />
					</Form.Group>
					<Form.Group className='mb-3'>
						<Form.Label>Other Fee</Form.Label>
						<Form.Control type='number' onWheel={(e) => e.target.blur()} defaultValue={shipperBilling?.other_fee} onChange={(e) => setShipperBilling({ ...shipperBilling, other_fee: e.target?.value })} />
						<FormError error={errors.other_fee} />
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className='display-flex-start'>
					<Spinner display={spinner}>
						<button className='btn btn-dark ms-2' onClick={() => updateShipperbilling()} >Update</ button>
						<button className='btn btn-link text-muted' onClick={() => { showBillingModal(false); setErrors({}); setShipperBilling(JSON.parse(shipment?.billing_shipper)) }}>Cancel</button>
					</Spinner>
				</Modal.Footer>
			</Modal>
		</>
	}

	const CarrierBilling = ({ shipment }) => {
		const [carrierBilling, setCarrierBilling] = useState(JSON.parse(shipment?.billing_carrier));
		const [billingModal, showBillingModal] = useState(false)
		const [accordion, openAccordion] = useState(false)
		const [errors, setErrors] = useState({});

		const validateBilling = () => {
			const validateNumber = (value) => { return value !== null && value !== undefined && !isNaN(value) && value !== ''; }

			let error = {};
			if (!validateNumber(carrierBilling?.delivery_fee)) error.delivery_fee = "Please enter a valid numbers"
			if (!validateNumber(carrierBilling?.other_fee)) error.other_fee = "Please enter a valid numbers"
			setErrors(error)
			return error
		}

		const updateCarrierBilling = async () => {

			let error = validateBilling()
			if (!Object.keys(error).length <= 0) return

			let billingInput = JSON.stringify({
				delivery_fee: carrierBilling?.delivery_fee,
				other_fee: carrierBilling?.other_fee,
				total: Number(carrierBilling?.delivery_fee) + Number(carrierBilling?.other_fee)
			})

			showBillingModal(false)
			swal({
				text: `Are you sure you want to continue?`,
				buttons: ['No', 'Yes'],
				icon: 'warning',
				dangerMode: true
			}).then(async status => {
				if (status) {
					showSpinner(true);
					try {
						await API.graphql({ query: updateShipment, variables: { input: { id, billing_carrier: billingInput } } })
						toast.success("Courier billing has been updated")
						getShipment();
					} catch (error) {
						handleApiError(error);
						showSpinner(false);
					}
				}
			});
		}


		function CustomToggle({ children, eventKey }) {
			const decoratedOnClick = useAccordionButton(eventKey, () =>
				console.log('totally custom!'),
			);

			return (
				<button
					type="button"
					className='btn btn-sm btn-white border-0'
					onClick={() => { decoratedOnClick(); openAccordion(!accordion) }}
				>
					{children}
				</button>
			);
		}

		return <>
			<Accordion>
				<div className="card" >
					<div className="card-header">
						<h4 className='card-header-title'>Courier Billing</h4>
						<Col className='col-auto mt-3 me-2'>
							<h5> {"$" + Number(carrierBilling?.total).toFixed(2) ?? 0.00}  </h5>
						</Col>
						<Col className='col-auto mx-2'>
							<Button className='btn btn-sm btn-light' onClick={() => calculateBilling()} >
								<i className='fe fe-rotate-cw'></i>
							</Button>
						</Col>
						<Col className='col-auto mx-2'>
							<Button className='btn btn-sm btn-light' onClick={() => showBillingModal(true)}>
								<i className='fe fe-edit'> </i>
							</Button>
						</Col>
						<Col className='col-auto mx-2'><CustomToggle eventKey="0"> <i className={`fe fe-${accordion ? "chevron-up" : "chevron-down"}`}></i> </CustomToggle></Col>
					</div>
					<Accordion.Collapse eventKey="0">
						<div className='card-body'>
							<ListGroup className='list-group-flush'>
								{<ListGroupItem name='Delivery Fee' value={"$" + Number(carrierBilling?.delivery_fee)?.toFixed(2) ?? 0.00} />}
								{<ListGroupItem name='Other Fee' value={"$" + Number(carrierBilling?.other_fee)?.toFixed(2) ?? 0.00} />}
								{<ListGroupItem name='Total' value={"$" + Number(carrierBilling?.total)?.toFixed(2) ?? 0.00} />}
							</ListGroup>
						</div>
					</Accordion.Collapse>
				</div>
			</Accordion>


			<Modal show={billingModal}>
				<Modal.Header>
					<Modal.Title className='modal-title'>Update Billing</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className='mb-3'>
						<FormLabel>Delivery Fee</FormLabel>
						<Form.Control type='number' onWheel={(e) => e.target.blur()} value={carrierBilling?.delivery_fee} onChange={(e) => setCarrierBilling({ ...carrierBilling, delivery_fee: e.target?.value })} />
						<FormError error={errors?.delivery_fee} />
					</Form.Group>
					<Form.Group className='mb-3'>
						<FormLabel>Other Fee</FormLabel>
						<Form.Control type='number' onWheel={(e) => e.target.blur()} value={carrierBilling?.other_fee} onChange={(e) => setCarrierBilling({ ...carrierBilling, other_fee: e.target?.value })} />
						<FormError error={errors?.other_fee} />
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className='display-flex-start'>
					<Spinner display={spinner}>
						<button className='btn btn-dark ms-2' onClick={() => updateCarrierBilling()} >Update</ button>
						<button className='btn btn-link text-muted' onClick={() => { showBillingModal(false); setCarrierBilling(JSON.parse(shipment?.billing_carrier)); setErrors({}) }}>Cancel</button>
					</Spinner>
				</Modal.Footer>
			</Modal >
		</>

	}
	//#endregion

	//#endregion


	return (
		<>
			<Spinner display={spinner}>
				<ShipmentHeader />
				<ShipmentDetailMap shipment={shipment} viewState={viewState} />


				<Container fluid className='mt-5'>
					<Row>
						<Col xl={8}>
							<Row>
								<Col xl={6}>
									<ShipmentDetails />
									{
										multi_Dimensional_Shipment_Item ? <MultiDimensionalShipmentItems /> : <ShipmentItems />
									}
								</Col>
								<Col xl={6}>
									<RecentActivity />
									<CarrierDriver />
									{shipment?.billing_shipper && <ShipperBilling shipment={shipment} />}
									{shipment?.billing_carrier && <CarrierBilling shipment={shipment} />}
								</Col>
							</Row>
						</Col>
						<Col xl={4}>
							<ShipmentRating />
							<DeliveryProof />
							<Card title={'Tasks'}>
								{shipment?.tasks?.items?.map((task) => (
									<div className='p-2 m-2' style={{ cursor: "pointer" }} onClick={(() => navigate(`/task/${task?.id}`))}>
										<h4>{task?.title}</h4>
										<div className="col">
											<p className={`card-text small text-body-secondary ${moment().unix() > task?.dueBy && 'text-danger'}`}>
												<i className="fe fe-clock" />{" "}
												{moment.unix(task?.dueBy).format("MMM-DD HH:mm a")}
											</p>
										</div>
										<hr className='m-1' />
									</div>
								))}
							</Card>
						</Col>
					</Row>
				</Container>
			</Spinner>
		</>
	);
};

export default ShipmentDetail;
