import React from 'react';
import { Form } from 'react-bootstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import { placesAutoCompleteOptions } from '../helpers';
import { FormError, SearchAddressFromHere } from '../helpers';
import AsyncSelect from 'react-select/async';

export const AddressField = ({ name, value, error, onChange }) => {
    return (
        <>
            <AsyncSelect
                key={value}
                value={value ? { label: value, value: value } : { label: '-- Search Address --', value: null }}
                cacheOptions
                defaultOptions
                loadOptions={SearchAddressFromHere}
                onChange={onChange}
            />
            <FormError error={error} />
        </>
    )
};


export const GoogleAddressField = ({ name, value, error, isInvalid, onChange, onSelect }) => {
    return (
        <PlacesAutocomplete name={name} value={value || ''}
            searchOptions={placesAutoCompleteOptions}
            onChange={onChange} onSelect={onSelect}>
            {
                ({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className='search-input-container'>
                        <Form.Control {...getInputProps({ placeholder: 'e.g. 100 Main St', className: 'form-control' })} value={value || ''} isInvalid={isInvalid} />
                        <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
                        <div className='autocomplete-container bg-light'>
                            {suggestions.map((suggestion, index) => {
                                const className = 'autocomplete-item';
                                return (
                                    <div key={index} {...getSuggestionItemProps(suggestion, { className })}>
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )
            }
        </PlacesAutocomplete>
    );
};

export const AddressView = ({ address, mapLatLong }) => {
    let location = address?.location;
    let cityState = ''
    if (address?.city && address?.state) cityState = `${address.city}, ${address.state}`
    else if (address?.city) cityState = address.city
    else if (address?.state) cityState = address.state

    return (
        <div className='small text-muted'>
            <div>{address?.address1}</div>
            <div>{address?.address2}</div>
            <div>{cityState} {address?.postalCode}</div>
            {(mapLatLong && location.latitude && location.longitude) &&
                <a a href={`http://maps.google.com/maps?q=&layer=c&cbll=${location?.latitude}, ${location?.longitude}`} target='_blank' > {`${location?.latitude.toFixed(4)},${location?.longitude.toFixed(4)}`} </a>
            }
        </div >
    )
}