import { StorageManager } from '@aws-amplify/ui-react-storage';
import { API, Storage } from 'aws-amplify';
import { uniqueId } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, FormLabel, InputGroup, Modal, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import { DELAY_TIMEOUT, fileUpload, getTZ, PAGE_SIZE, toLocalDate, toLocalTime } from '../../helpers';
import { TableExportFilter, TableKeywordSearch, TablePagination, TimeZoneFilter } from '../../components/TableFilter';
import { DateFilter } from '../../components/EntityFilter';
import { TableClearFilter } from '../../components/TableFilter';
import ReactGA from "react-ga4";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";




const getList = `query ListTasks {
    listTasks(filter: {status: {eq: OPEN}}) {
        items {
        attachment
        comments
        createdTime
        description
        dueBy
        id
        label
        shipment {
            number
        }
        shipmentId
        status
        title
        user {
            name
            id
        }
        userId
        }
    }
    }`

const createTaskSub = `subscription createTaskSub {
        onCreateTask {
            attachment
            comments
            createdTime
            description
            dueBy
            id
            label
            shipmentId
            status
            title
            shipment {
            number
            }
            userId
            user {
            name
            id
            }
        }
            }`


const updateTaskSub = `subscription updateTaskSub {
                onUpdateTask {
                    attachment
                    comments
                    createdTime
                    description
                    dueBy
                    id
                    label
                    shipmentId
                    status
                    title
                    shipment {
                    number
                    }
                    userId
                    user {
                    name
                    id
                    }
                }
                    }`
const createTaskMutation = /* GraphQL */ `
            mutation CreateTask($input: CreateTaskInput!){
                createTask(input: $input) {
                id 
                attachment
                comments
                createdTime
                description
                dueBy
                id
                label
                shipment {
                    number
                }
                shipmentId
                status
                title
                user {
                    name
                    id
                }
                userId
                    }
            }`;
const updateTaskMutation = /* GraphQL */ `
            mutation UpdateTask($input: UpdateTaskInput!){
                updateTask(input: $input) {
                id
                attachment
                comments
                createdTime
                description
                dueBy
                id
                label
                shipment {
                    number
                }
                shipmentId
                status
                title
                user {
                    name
                    id
                }
                userId
                    }
            }`;

const shipmentIdByNumber = /* GraphQL */ `
            query MyQuery($number: Int!) {
            shipmentsByNumber(number: $number) {
                items {
                id
                }
            }}`;



export default function Task({dark}) {
    const [taskList, setTaskList] = useState({});
    const [tz, setTz] = useState("EST");
    const [rawTaskList, setRawTaskList] = useState([])
    const myUser = useSelector((state) => state.slice.USER)
    const [userList,setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(true)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [timeZone, setTimeZone] = useState("America/New_York");
    const [keyword, setKeyword] = useState('')
    const [resetDate, setRestDate] = useState(0);
    const params = useParams()
    const [pageNumber, setPageNumber] = useState(0);
    const [dateFilters, setDateFilters] = useState(null);
    const allUsers = [];
     const textColor = dark ? 'text-light' : 'text-dark'
    

    async function getTaskList() {
        try {
            const apiName = 'api';
			const path = `/search/task?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
			let init = {
				body: {
					sort: [
						{
							"_score": { "order": "desc" }
						},
						{
							"updatedAt": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],
					query: {
						bool: {
							must: [{match : {'status' :'OPEN'}}]
						}
					}
				}
			};

			if (!keyword && dateFilters) {
				init.body.query.bool.must.push({
					range: {
						"createdTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}   
            if(selectedUser){
                init.body.query.bool.must.push({match : {"userId" : selectedUser?.value}})
            }

			if (keyword) {
				let fields = [
					"label",
                    "description",
                    "title"
				]
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			const res = await API.post(apiName, path, init);
            const items  = res.hits.hits

            let data = []
            for(let item of items){
                data.push(item._source)
            }
            setRawTaskList(data);
            const result = Object.groupBy(data, ({ label }) => label);
            Object.keys(result).forEach((key) => {
                const itemArray = result[key];
                itemArray.forEach((item) => {
                    if (item?.user) {
                        allUsers.push(item.user);
                    }
                });
            });
            setUserList(allUsers);
            setTaskList(result)
            setLoading(false)


            API.graphql({ query: createTaskSub }).subscribe({
                next: (response) => {
                    
                    let resData = response.value.data.onCreateTask;
                    data.unshift( {...resData,comments : resData?.comments ? JSON.parse(resData?.comments) :[]})
                    setRawTaskList(data)
                    const result = Object.groupBy(data, ({ label }) => label);
                    setTaskList(result)
                },
                error: (error) => console.error('onShipmentUpdate', error),
            });
            API.graphql({ query: updateTaskSub }).subscribe({
                next: (response) => {
                    let resData = response.value.data.onUpdateTask
                    if (resData.status !== 'DONE') {
                        let idx = data.findIndex(item => item.id === resData.id)
                        data[idx] = {...resData,comments : resData?.comments ? JSON.parse(resData?.comments) :[]}
                    } else data = data.filter(item => item.id !== resData.id)
                    setRawTaskList(data)
                    const result = Object.groupBy(data, ({ label }) => label);
                    setTaskList(result)
                },
                error: (error) => console.error('onShipmentUpdate', error),
            });

        } catch (error) {
            console.log(error)
        }

    }
    const handleStatusChange = (isOnline) => {
        if (isOnline) {
            getTaskList();
        }
    };

    const handleFullPage = () => {
        const elem = document.documentElement;

        if (!document.fullscreenElement) {
            elem.requestFullscreen().then(() => setIsFullScreen(true)).catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen().then(() => setIsFullScreen(false));
        }
    }


    const searchUser = async (keyword) => {
        let items = [];
        try {
            const apiName = 'api';
            const path = `/search/user?size=100&from=0`;
            let init = {
                body: {
                    sort: [
                        {
                            "_score": { "order": "desc" }
                        }
                    ],
                    query: {
                        bool: {
                            must: [
                                { match: { active: true } },
                                {
                                    terms: { "role.keyword": ['OWNER', 'EDITOR', 'VIEWER'] }
                                }
                            ]
                        }
                    }
                }
            };



            if (keyword) {
                delete init.body.sort;
                init.body.query.bool.should = [
                    { "prefix": { "label": keyword.toLowerCase() } },
                    { "prefix": { "title": keyword.toLowerCase() } },
                    { "prefix": { "description": keyword.toLowerCase() } },
                ]
            }

            let data = await API.post(apiName, path, init);
            data.hits.hits.forEach(item => items.push({
                label: item?._source?.name,
                value: item?._source?.id
            }));
            return items
            
        } catch (error) {
            console.error('error from  elastic search cutomer', error)
        }
    }

    async function getIdByShipmentNumber(number) {
        try {
            let res = await API.graphql({ query: shipmentIdByNumber, variables: { number } })
            let shipment = res.data.shipmentsByNumber.items[0]
            return shipment?.id
        } catch (e) {
            console.log(e)
        }

    }


    
const DEBOUNCE_DELAY = 300;
    useEffect(() => {
        const handler = setTimeout(() => {
            setLoading(true)
            getTaskList();
        }, DEBOUNCE_DELAY);
        return () => {
            clearTimeout(handler);
        };
    }, [keyword, dateFilters,selectedUser]);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/task",
        })
    }, [])

    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);

    function Card({ task, setShowModal, setNewTask ,setAttachment}) {
        useEffect(() => {
            if (params?.id) {
                let task = rawTaskList.find(item => item.id === params?.id)
                setAttachment(task?.attachment)
                setNewTask({ ...task, comments: JSON.parse(task.comments),attachment:JSON.parse(task.attachment), username: task.user.name, userId: task.userId, shipmentNumber: task?.shipment?.number })
                let div = document.getElementById(params?.id)
                div.click()
            }
        }, [taskList])

        return (
            <div
                className='card mb-2 bg-light'
                id={task?.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                    let all = task?.attachment?.map(item=>{
                        return {url : item}
                    })
                    setAttachment(all)
                    setNewTask({ ...task, comments: task?.comments, username: task?.user?.name, userId: task?.userId, shipmentNumber: task?.shipment?.number })
                    setShowModal(true)
                }}
            >
                <div className="p-3">
                    {task?.shipment?.number && <h5 className='text-muted'>#{task?.shipment?.number}</h5>}
                    <h4>{task?.title}</h4>
                    <div className="row align-items-center">
                        <div className="col">
                            <p className={`card-text small text-body-secondary ${moment().unix() > task?.dueBy && 'text-danger'}`}>
                                <i className="fe fe-clock" />{" "}
                                {moment.unix(task?.dueBy).format("MMM-DD hh:mm A")}
                            </p>
                        </div>
                        <div className="col-auto">
                            <div className="avatar-group bg-white rounded-circle d-flex align-items-center justify-content-center p-3" style={{ width: '20px', height: '20px' }}>
                                <h5 className='m-0'>{task?.user?.name?.split(" ")[0][0].toUpperCase()}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    function Column({ title, tasks ,state, setState, label } ) {
        const [showModal, setShowModal] = useState(false)
        const [isErrorVisible, setIsErrorVisible] = useState(false); 
        const [isLoading, setIsLoading] = useState(false)
        const [isFileValid, setIsFileValid] = useState(true);
        const [attachment,setAttachment]= useState([]);
        const imgInputRef = useRef(null);
        const [isUploading, setIsUploading] = useState(false);
        const [selectedFile, setSelectedFile] = useState(null);
        const [newTask, setNewTask] = useState({ createdTime: moment().unix(), description: "", dueBy: moment().unix(), label: title, shipmentNumber: "", status: 'OPEN', title: "", userId: myUser?.id, attachment: [], comments: [], username: myUser?.name })

        const [comment, setComment] = useState({ userId: myUser?.id, username: myUser?.name, message: '', createdAt: null })
        
          async function handleDrop(file) {
            if(!file.type.includes("image")){
                toast.error("Invalid file type! Only image files are allowed.")
                setSelectedFile(null); 
                return;
            }
            setIsUploading(true);
            let temp = structuredClone(attachment);
            temp.push({
              type: file?.type,
              url: await fileUpload(file),

            });
           setAttachment(temp);
           setIsUploading(false);
          }

        const handleRemove = (item) => {
            setAttachment((prev) => prev.filter((url) => url !== item));
          };
        
        

        async function handleTask(task) {
            try {
                for (const [key, value] of Object.entries(task)) {
                    if (typeof value === 'string' && /^\s+$/.test(value)) {
                        const formattedKey = key
                            .replace(/([A-Z])/g, ' $1')
                            .replace(/^./, str => str.toUpperCase())
                            .trim();
                        return toast.error(`Invalid input: "${formattedKey}" should not contain spaces.`);
                    }
                }
                let shipmentId = null;
                setIsLoading(true)
                if (task.shipmentNumber) {
                    shipmentId = await getIdByShipmentNumber(task.shipmentNumber)
                    if (!shipmentId) {
                        setIsLoading(false)
                        return toast.error("No Shipment Found. Please check the Shipment Number entered !")
                    }
                }
                let rawIUrls = attachment.map(item=>item?.url)
                let input = {
                    createdTime: moment().unix(), description: task.description, dueBy: task.dueBy, label: task.label, shipmentId, status: 'OPEN', title: task.title, userId: task.userId,
                    comments: JSON.stringify(task.comments), attachment:rawIUrls
                }
                if (task?.id) {
                    delete input.createdTime
                    input.status = task.status
                    input.id = task.id
                }

                await API.graphql({ query: task?.id ? updateTaskMutation : createTaskMutation, variables: { input } })

                setShowModal(false)
                setNewTask({ createdTime: moment().unix(), description: "", dueBy: moment().unix(), label: title, shipmentNumber: "", status: 'OPEN', title: "", userId: myUser?.id, attachment: [], comments: [], username: myUser?.name })

                toast.success(`Task ${task?.id ? "updated" : 'added'} successfully!`)
            } catch (error) {
                console.log(error)
                setIsLoading(false)
                toast.error("Something went wrong !")
            }
        }

        return (
            <div className="bg-white rounded col col-3 p-0 border-">
                <div className={`p-3 rounded-top fw-bold`}>{title}</div>
                <div
                    style={{ overflowY: "auto", maxHeight: "60vh" }}
                    className="p-3"
                >
                    {tasks?.map((task, index) => (
                        <Card key={index} index={index} task={task} setShowModal={setShowModal} setNewTask={setNewTask} setAttachment={setAttachment} />
                    ))}
                </div>
                <Modal show={showModal} centered size='xl'>
                    <Modal.Body>
                        <h1>{newTask?.id ? 'Update' : 'Create a new'}  task</h1>
                        <hr />
                        <form className='row justify-content-between' onSubmit={(e) => {
                            e.preventDefault()
                            handleTask(newTask)
                        }}>
                            <div className='d-flex gap-2 flex-column col col-8'>
                                <Form.Group>
                                    <FormLabel>Title</FormLabel>
                                    <Form.Control required type='text' placeholder='Add a Title' defaultValue={newTask?.title} onChange={(e) => setNewTask((prev) => ({ ...prev, title: e.target.value }))} />
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel>Description</FormLabel>
                                    <textarea required className='form-control' type='textarea' rows={3} cols={30} placeholder='Add a Description' defaultValue={newTask?.description} onChange={(e) => setNewTask((prev) => ({ ...prev, description: e.target.value }))} />
                                </Form.Group>
                                <div className="mb-4">
                                <div
                                className="card"
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    handleDrop(e.dataTransfer.files[0], setAttachment);
                                }}
                                >
                                <div className="card-body">
                                    <div
                                    className="dropzone dropzone-multiple"
                                    role="presentation"
                                    tabIndex="0"
                                    >
                                    <input
                                        type="file"
                                        hidden
                                        ref={imgInputRef}
                                        accept="image/*"
                                        onChange={(e) =>
                                        handleDrop(e.target.files[0], setAttachment)
                                        }
                                    />
                                    <div className="dz-message">
                                        Drop files or{" "}
                                        <button
                                        type="button"
                                        className="btn btn-sm mx-2 btn-dark fw-bold"
                                        onClick={() => imgInputRef.current.click()}
                                        disabled={isUploading}
                                        >
                                       {isUploading ? "Uploading..." : "Upload"}
                                        </button>
                                    </div>
                                    </div>
                                </div>

                                {/* Display Uploaded Files */}
                                <div className="d-flex gap-3 mt-3">
                                    {Array.isArray(attachment) &&
                                    attachment.map((item, index) => (
                                        <div key={index} className="position-relative" style={{ width: "fit-content", borderRadius: ".4rem" }}>
                                            <img
                                            src={item?.url || item}
                                            alt="Uploaded preview"
                                            height="50px"
                                            width="40px"
                                            style={{ borderRadius: ".4rem", objectFit: "cover" }}
                                            />
                                        <i
                                            className="fe fe-x position-absolute top-0 end-0 bg-light"
                                            style={{ cursor: "pointer", zIndex: 10 }} 
                                            onClick={() => handleRemove(item)}
                                        ></i>
                                        </div>
                                    ))}
                                </div>
                                </div>
                                </div>
                                <div>
                                    <FormLabel>Comments</FormLabel>
                                    <div className='row'>
                                        <div className="input-group mb-2 mr-sm-2">
                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder='Add a comment...' value={comment?.message} onChange={(e) => setComment((prev) => ({ ...prev, message: e.target.value }))} />
                                            <div className="input-group-postpend">
                                                <button type='button' disabled={comment.message === ''} className='btn btn-dark ms-2' onClick={() => {
                                                    setComment({ userId: myUser?.id, username: myUser?.name, message: '', createdAt: null })
                                                    setNewTask((prev) => ({ ...prev, comments: [{ ...comment, createdAt: moment().unix() }, ...prev?.comments || []] }))
                                                }}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* comments */}
                                    <div className='mt-3'>
                                        {newTask?.comments?.map((item) => (
                                            <div key={item?.createdAt} className="comment mb-3">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <div className="avatar-group bg-light rounded-circle d-flex align-items-center justify-content-center p-3" style={{ width: '20px', height: '20px' }}>
                                                            <h5 className='m-0'>{item?.username?.split(" ")[0][0]?.toUpperCase()}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col ms-n2">
                                                        <div className="comment-body">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h5 className="comment-title">{item?.username}</h5>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <time className="comment-time">{moment.unix(item?.createdAt).format("MMM DD hh:mm A")}</time>
                                                                </div>
                                                            </div>{" "}
                                                            <p className="comment-text">
                                                                {item?.message}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='col col-4 border-left d-flex gap-2 flex-column' style={{ borderLeft: "2px solid  #e6e6e6" }}>
                                <Form.Group>
                                    <FormLabel>Status</FormLabel>
                                    <Form.Select disabled={!newTask?.id} className='phox-form-select' value={newTask?.status} onChange={(e) => setNewTask((prev) => ({ ...prev, status: e.target.value }))}>
                                        <option value='TODO'>OPEN</option>
                                        <option value='DONE'>DONE</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group>
                                    <FormLabel>User</FormLabel>
                                    <AsyncSelect cacheOptions defaultOptions
                                        placeholder='Select User'
                                        defaultValue={newTask?.userId ? { value: newTask?.userId, label: newTask?.username } : null}
                                        loadOptions={searchUser}
                                        onChange={(e) => setNewTask((prev) => ({ ...prev, userId: e.value, username: e.label }))}
                                    />
                                </Form.Group>
                                <div>
                                    <FormLabel>Label</FormLabel>
                                    <input type="text" required placeholder='Add a Label' className='form-control'
                                        value={newTask?.label} onChange={(e) => setNewTask((prev) => ({ ...prev, label: e.target.value }))}
                                    />
                                </div>
                                <div>
                                    <FormLabel>Shipment</FormLabel>
                                    <input type="text" placeholder='Enter Shipment Number' className='form-control'
                                        value={newTask?.shipmentNumber} onChange={(e) => setNewTask((prev) => ({ ...prev, shipmentNumber: e.target.value }))}
                                    />
                                </div>
                                <div>
                                    <FormLabel>Due By</FormLabel>
                                    <Flatpickr
                                        className="form-control"
                                        options={{
                                            enableTime: true,
                                            dateFormat: "Y-m-d h:i K",
                                            minDate: "today", 
                                            
                                        }}
                                        value={new Date(moment.unix(newTask?.dueBy).format('YYYY-MM-DDTHH:mm'))} 
                                        onChange={([selectedDate]) =>
                                            setNewTask((prev) => ({
                                                ...prev,
                                                dueBy: moment(selectedDate).unix(), 
                                            }))
                                        }
                                    />
                                </div>
                                <div className='d-flex justify-content-end mt-auto'>
                                    <button className='btn btn-light' type='button' onClick={() => {setShowModal(false)
                                     setAttachment([])
                                        setNewTask({ createdTime: moment().unix(), description: "",  dueBy: moment().unix(),  label: title, shipmentNumber: "", status: 'OPEN', title: "", userId: myUser?.id, attachment: [], comments: [], username: myUser?.name })
                                    }
                                        
                                    }>Cancel</button>
                                    <button disabled={!isFileValid || isLoading} className='btn btn-dark ms-1' type='submit'>
                                        {isLoading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            newTask?.id ? "Update Task" : "+ Create Task"
                                        )}
                                    </button>

                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
                <div className="d-flex justify-content-center">
                    <button onClick={() => {setShowModal(true)
                    setAttachment([])
                        setNewTask({ createdTime: moment().unix(), description: "",  dueBy: moment().unix(),  label: title, shipmentNumber: "", status: 'OPEN', title: "", userId: myUser?.id, attachment: [], comments: [], username: myUser?.name })
                    }
                    } className='m-2 btn btn-sm btn-dark'>+ Add Task</button>
                </div>
            </div>
        )
    }
    const clearFilters = () => {
		setDateFilters(null);
		setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
        setUserList(allUsers);
        setLoading(true)
        getTaskList();
        setSelectedUser(null);
		
	}
	const tzHandle = (e) => {
		setTimeZone(e);
	};
    return (
        <div>
            <PageHeader name='Tasks Board' onStatusChange={handleStatusChange}>
                <div className='d-flex flex-row gap-3 align-items-center'>
            <Link to="/tasklist">
                    <Button className='bg-dark border border-0'>
                        Task List
                    </Button>
                  </Link>
                    <div className="fullPage cursor-pointer" onClick={handleFullPage}>
                        {isFullScreen ? (
                            <i className='fe fe-minimize' />
                        ) : (
                            <i className='fe fe-maximize' />
                        )}
                    </div>
                </div>
            </PageHeader>
            <div className="d-flex align-items-center justify-content-between py-1 px-5">
   
    <InputGroup style={{ width: 'auto', marginRight: '1rem' }}>
        <Form.Control
            placeholder="Search here..."
            aria-label="Search list"
            onChange={(e) => setKeyword(e.target.value.toLowerCase())}
            style={{ height: '38px' }} 
        />
    </InputGroup>

   
    <div className="d-flex align-items-center gap-3 px-1 my-1">
        <div style={{ minWidth: '150px' }}>
    <div className={`small ${textColor}`}>Users</div>
    <AsyncSelect
        cacheOptions
        defaultOptions
        styles={{
            control: (baseStyles) => ({
                ...baseStyles,
                fontSize: '0.75rem', 
                backgroundColor: '#EDF2F9',
                borderWidth: '0px',
                minHeight: '28px'
            }),
            dropdownIndicator: (baseStyles) => ({
                ...baseStyles,
                padding: '2px', 
            }),
            clearIndicator: (baseStyles) => ({
                ...baseStyles,
                padding: '2px', 
            }),
        }}
        placeholder="Select User"
        value={selectedUser ? { value: selectedUser?.value, label: selectedUser?.label } : null}
        loadOptions={searchUser}
        onChange={(e) => setSelectedUser(e)}
    />
</div>
         <DateFilter
            key={resetDate}
            onChange={setDateFilters}
            
        />
        <TableClearFilter
            onClick={clearFilters}
            styleName="mt-4"
        />
    </div>
</div>


<Spinner display={loading}>
            <div className='px-5 d-flex gap-3 align-items-start' style={{ overflow: 'auto' }}>
            {Object.keys(taskList).map((item, idx) => (
                <Column key={idx} title={item} tasks={taskList[item]} id={idx} />
            ))}
            <Column title={''} tasks={[]} id={'new'} />
            </div>
    </Spinner>
        </div>
    )
}

