/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { UserRoleBadge } from '../../components/StatusBadge';
import { PAGE_TITLE, isOwner } from '../../helpers';
import ReactGA from "react-ga4"

const usersByShipperId = /* GraphQL */ `
  query UsersByShipperId (
    $shipperId: ID!
    $nextToken: String
  ) {
    shipperUsersByShipperId(
      shipperId: $shipperId
      nextToken: $nextToken
    ) {
	  nextToken
      items {
        id
        role
        user {
          id
          name
          title
          company
          phone
          email
        }
      }
    }
  }
`;

const ShipperUsers = () => {

	const navigate = useNavigate();
	const { shipperGroupId, shipperId } = useParams();
	const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP)
	const myShipper = useSelector((state) => state.slice.SHIPPER)
	const myUserRole = useSelector((state) => state.slice.USER.role)

	const [users, setUsers] = useState([]);
	const [spinner, showSpinner] = useState(true);

	const menus = [
		{ 'name': 'Overview', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/overview`, 'active': false },
		{ 'name': 'Settings', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/setting`, 'active': false },
		{ 'name': 'Services', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/service`, 'active': false },
		{ 'name': 'Products', 'url': `/shipper-group/${shipperGroupId}/shipper/${shipperId}/product`, 'active': false },
	]

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: `/shipper-group/${shipperGroupId}/shipper/${shipperId}/user`,
		})
	}, [])


	useEffect(() => getUsers(), [shipperId])

	const getUsers = () => {
		showSpinner(true);
		API.graphql({ query: usersByShipperId, variables: { shipperId: shipperId } })
			.then((res) => setUsers(res?.data?.shipperUsersByShipperId?.items?.sort((a, b) => a?.user?.name.localeCompare(b?.user?.name, undefined, { sensitivity: 'accent' }))))
			.catch((error) => console.error(error))
			.finally(() => showSpinner(false))
	}

	return (
		<>
			<PageHeader title={myShipperGroup?.name} name={myShipper?.name} pageTitle={"Shipper User"} menus={menus} image={myShipper?.image}>
				<Link to={'/user/invite/create'} className='btn btn-dark ms-2' >
					Invite User
				</Link >
				<Button className='btn btn-light ms-2' onClick={() => { getUsers() }}>
					<i className='fe fe-refresh-cw'></i>
				</Button>
			</PageHeader>

			<Container fluid>
				<Card>
					<Spinner display={spinner}>
						<Table responsive size='sm'>
							<thead>
								<tr>
									<th>Name</th>
									<th>Phone</th>
									<th>Email</th>
									<th className='text-center'>Role</th>
								</tr>
							</thead>
							<tbody>
								{
									users && users.map((user, index) => {
										return (
											<tr key={index} onClick={() => isOwner(myUserRole) && navigate(`/user/${user.user.id}/overview`)} style={{ cursor: 'pointer' }}>
												<td>
													{user?.user?.name}
													<div className='small text-muted'>
														{user?.user?.title} {user?.user?.company}
													</div>
													<div className='small text-muted'></div>
													<div className='small text-muted'></div>
												</td>
												<td>{user?.user?.phone}</td>
												<td>{user?.user?.email}</td>
												<td>
													<UserRoleBadge role={user?.role} />
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</Table>
					</Spinner>
				</Card>
			</Container>
		</>
	)
}

export default ShipperUsers;