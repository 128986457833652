/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, ListGroup, ListGroupItem, Modal, Row, Table } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import uniqid from 'uniqid';
import PageHeader from '../../components/PageHeader';
import SelectRole from '../../components/SelectRole';
import Spinner from '../../components/Spinner';
import { handleApiError } from '../../helpers';
import ReactGA from "react-ga4"
import { storeUser } from '../../stores/slice';
import { useDispatch } from 'react-redux';

const getUserQuery = /* GraphQL */ `
  query GetUser ($id: ID!) {
    getUser (id: $id) {
		id
		name
		email
		phone
		role
		active
		shipperGroups {
			items {
				id
				role
				shipperGroup {
					id
					name
				}
			}
		}
		shippers {
			items {
				id
				role
				shipper {
					id
					name
				}
			}
		}
		carriers {
			items {
				id
				role
				carrier {
					id
					name
				}
			}
		}
    }
  }
`;

const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      name
      title
      company
      phone
      email
	  image
      active
      role
    }
  }
`;

const updateShipperGroupUserMutation = /* GraphQL */ `
  mutation UpdateShipperGroupUser($input: UpdateShipperGroupUserInput!) {
    updateShipperGroupUser(input: $input) {
      id
    }
  }
`;

const deleteShipperGroupUserMutation = /* GraphQL */ `
  mutation DeleteShipperGroupUser($input: DeleteShipperGroupUserInput!) {
    deleteShipperGroupUser(input: $input) {
      id
    }
  }
`;

const updateShipperUserMutation = /* GraphQL */ `
  mutation UpdateShipperUser($input: UpdateShipperUserInput!) {
    updateShipperUser(input: $input) {
      id
    }
  }
`;

const deleteShipperUserMutation = /* GraphQL */ `
  mutation DeleteShipperUser($input: DeleteShipperUserInput!) {
    deleteShipperUser(input: $input) {
      id
    }
  }
`;

const updateCarrierUserMutation = /* GraphQL */ `
  mutation UpdateCarrierUser($input: UpdateCarrierUserInput!) {
    updateCarrierUser(input: $input) {
      id
    }
  }
`;

const deleteCarrierUserMutation = /* GraphQL */ `
  mutation DeleteCarrierUser($input: DeleteCarrierUserInput!) {
    deleteCarrierUser(input: $input) {
      id
    }
  }
`;

const createShipperGroupUser = /* GraphQL */ `
  mutation CreateShipperGroupUser( $input: CreateShipperGroupUserInput!) {
    createShipperGroupUser(input: $input) {
      id
    }
  }
`;

const createShipperUser = /* GraphQL */ `
  mutation CreateShipperUser($input: CreateShipperUserInput!) {
    createShipperUser(input: $input) {
      id
    }
  }
`;

const createCarrierUser = /* GraphQL */ `
  mutation CreateCarrierUser($input: CreateCarrierUserInput!) {
    createCarrierUser(input: $input) {
      id
    }
  }
`;

const UserOverview = () => {

	const { userId } = useParams();
	const dispatch = useDispatch();

	const [user, setUser] = useState();
	const [userName, setUserName] = useState();
	const [newUserRole, setNewUserRole] = useState([{ id: '', name: '', role: 'VIEWER' }]);

	const [spinner, showSpinner] = useState(true);
	const [editStatus, setEditStatus] = useState(false);

	const [shipperGroupModal, showShipperGroupModal] = useState(false);
	const [shipperModal, showShipperModal] = useState(false);
	const [carrierModal, showCarrierModal] = useState(false);

	useEffect(() => {
		getUser()
		ReactGA.send({
			hitType: "pageview",
			page: `/user/${userId}/overview`,
		})
	}, [])
	useEffect(() => {
		setUserName(user?.name || "")
	}, [editStatus])

	const getUser = () => {
		showSpinner(true);
		API.graphql({ query: getUserQuery, variables: { id: userId } })
			.then((res) => setUser(res?.data?.getUser))
			.catch(handleApiError)
			.finally(() => showSpinner(false))
	}
	const updateUserHandle = () => {
		showSpinner(true);
		API.graphql({ query: updateUser, variables: { input: { id: userId, name: userName } } })
			.then((response) => {
				const dbUser = response?.data?.updateUser;
				dispatch(storeUser(dbUser))
				getUser();
				toast.success(`${user.name}'s account has been updated`);
			})
			.catch(handleApiError)
			.finally(() => showSpinner(false))
	}

	const disableUser = async () => {
		showSpinner(true);

		for (var item of user.shipperGroups?.items) {
			updateShipperGroupRole(item.id, 'REMOVE')
		}

		for (var item of user.shippers?.items) {
			updateShipperRole(item.id, 'REMOVE')
		}

		for (var item of user.carriers?.items) {
			updateCarrierRole(item.id, 'REMOVE')
		}

		API.graphql({ query: updateUser, variables: { input: { id: userId, role: null, active: false } } })
			.then((response) => {
				getUser();
				toast.success(`${user.name}'s account has been disabled`);
			})
			.catch(handleApiError)
			.finally(() => showSpinner(false))
	}

	const enableUser = () => {
		showSpinner(true)
		API.graphql({ query: updateUser, variables: { input: { id: userId, active: true } } })
			.then((response) => {
				getUser();
				toast.success(`${user.name}'s account has been enabled`);
			})
			.catch(handleApiError)
			.finally(() => showSpinner(false))
	}

	const updateUserRole = (role) => {
		showSpinner(true)
		if (role === 'REMOVE') role = null
		API.graphql({ query: updateUser, variables: { input: { id: userId, role: role } } })
			.then((response) => {
				getUser();
				toast.success(`${user.name}'s role has been updated`);
			})
			.catch(handleApiError)
			.finally(() => showSpinner(false))
	}

	const updateShipperGroupRole = (id, role) => {
		showSpinner(true);
		if (role === 'REMOVE') {
			API.graphql({ query: deleteShipperGroupUserMutation, variables: { input: { id: id } } })
				.then(async (response) => {
					await API.graphql({ query: updateUser, variables: { input: { id: userId, email: user?.email } } })
					getUser();
					toast.success(`${user.name}'s role has been updated`);
				}).catch(handleApiError)
		} else {
			API.graphql({ query: updateShipperGroupUserMutation, variables: { input: { id: id, role: role } } })
				.then(async (response) => {
					await API.graphql({ query: updateUser, variables: { input: { id: userId, email: user?.email } } })
					getUser();
					toast.success(`${user.name}'s role has been updated`);
				}).catch(handleApiError)
		}
	}

	const updateShipperRole = (id, role) => {
		showSpinner(true);
		if (role === 'REMOVE') {
			API.graphql({ query: deleteShipperUserMutation, variables: { input: { id: id } } })
				.then(async (response) => {
					await API.graphql({ query: updateUser, variables: { input: { id: userId, email: user?.email } } })
					getUser();
					toast.success(`${user.name}'s role has been updated`);
				}).catch(handleApiError)
		} else {
			API.graphql({ query: updateShipperUserMutation, variables: { input: { id: id, role: role } } })
				.then(async (response) => {
					await API.graphql({ query: updateUser, variables: { input: { id: userId, email: user?.email } } })
					getUser();
					toast.success(`${user.name}'s role has been updated`);
				}).catch(handleApiError)
		}
	}

	const updateCarrierRole = (id, role) => {
		showSpinner(true);
		if (role === 'REMOVE') {
			API.graphql({ query: deleteCarrierUserMutation, variables: { input: { id: id } } })
				.then(async (response) => {
					await API.graphql({ query: updateUser, variables: { input: { id: userId, email: user?.email } } })
					getUser();
					toast.success(`${user.name}'s role has been updated`);
				}).catch(handleApiError)
		} else {
			API.graphql({ query: updateCarrierUserMutation, variables: { input: { id: id, role: role } } })
				.then(async (response) => {
					await API.graphql({ query: updateUser, variables: { input: { id: userId, email: user?.email } } })
					getUser();
					toast.success(`${user.name}'s role has been updated`);
				}).catch(handleApiError)
		}
	}

	//#region Create User
	const handleCreateUser = (name) => {
		name === 'ShipperGroupUser' && showShipperGroupModal(false);
		name === 'ShipperUser' && showShipperModal(false);
		name === 'CarrierUser' && showCarrierModal(false);

		showSpinner(true)
		try {
			newUserRole.forEach(async (item) => {

				name === 'ShipperGroupUser' && await API.graphql({ query: createShipperGroupUser, variables: { input: { id: uniqid(), shipperGroupId: item.id, userId: userId, role: item.role, } } })
				name === 'ShipperUser' && await API.graphql({ query: createShipperUser, variables: { input: { id: uniqid(), shipperId: item.id, userId: userId, role: item.role, } } })
				name === 'CarrierUser' && await API.graphql({ query: createCarrierUser, variables: { input: { id: uniqid(), carrierId: item.id, userId: userId, role: item.role, } } })
				await API.graphql({ query: updateUser, variables: { input: { id: userId, email: user?.email } } })
				getUser()
				setNewUserRole([{ id: '', name: '', role: 'VIEWER' }])
				toast.success('Role added successfully')
				showSpinner(false)
			})
		} catch (error) {
			console.log(error)
			setNewUserRole([{ id: '', name: '', role: 'VIEWER' }])
			showSpinner(false)
			toast.error('Oops! Looks like there was an error trying to process your request. Please try again.')
		}
	}
	//#endregion

	//#region Search
	const searchShipperGroup = async (keyword) => {
		let items = []
		try {
			const apiName = 'api';
			const path = `/search/shipper-group?size=1000&from=0`;

			let init = {
				body: {
					sort: [{ "name.keyword": { "order": "asc" } }],
					query: {
						bool: {
							must_not: [
								{
									match: {
										active: false
									}
								}
							]
						}
					}
				}
			};

			if (keyword) {
				delete init.body.sort;
				init.body.query.bool.should = [{ "prefix": { "name": keyword.toLowerCase() } }]
			} else {
				init.body.match = { _index: "shipper-group" };
			}

			const { hits } = await API.post(apiName, path, init);
			const sourceData = hits?.hits?.map(item => item?._source) || [];
			sourceData.forEach(item => items.push({
				label: item.name,
				value: item.id
			}));
		} catch (error) {
			console.error('Error fetching courier data:', error);
		} finally {
			showSpinner(false);
		}

		let shipperGroupIds = user?.shipperGroups?.items?.map(item => item.shipperGroup.id)
		const shipperGroupList = items.filter(item => {
			return !shipperGroupIds.includes(item.value);
		});

		return shipperGroupList
	};

	const searchShipper = async (keyword) => {
		let items = []
		try {
			const apiName = 'api';
			const path = `/search/shipper?size=1000&from=0`;

			let init = {
				body: {
					sort: [{ "name.keyword": { "order": "asc" } }],
					query: {
						bool: {
							must_not: [
								{
									match: {
										active: false
									}
								}
							]
						}
					}
				}
			};

			if (keyword) {
				delete init.body.sort;
				init.body.query.bool.should = [{ "prefix": { "name": keyword.toLowerCase() } }]
			} else {
				init.body.match = { _index: "shipper" };
			}

			const { hits } = await API.post(apiName, path, init);
			const sourceData = hits?.hits?.map(item => item?._source) || [];
			sourceData.forEach(item => items.push({
				label: item.name,
				value: item.id
			}));

			let shipperIds = user?.shippers?.items?.map(item => item.shipper.id)
			const shipperList = items.filter(item => {
				return !shipperIds.includes(item.value);
			});

			return shipperList

		} catch (error) {
			console.error('Error fetching courier data:', error);
		} finally {
			showSpinner(false);
		}

		let shippersIds = user?.shippers?.items?.map(item => item.shipper.id)

		const shipperList = items.filter(item => {
			return !shippersIds.includes(item.value);
		});
		return shipperList;
	};

	const searchCarrier = async (keyword) => {
		let items = []
		try {
			const apiName = 'api';
			const path = `/search/carrier?size=1000&from=0`;

			let init = {
				body: {
					sort: [{ "name.keyword": { "order": "asc" } }],
					query: {
						bool: {
							must_not: [
								{
									match: {
										active: false
									}
								}
							]
						}
					}
				}
			};

			if (keyword) {
				delete init.body.sort;
				init.body.query.bool.should = [{ "prefix": { "name": keyword.toLowerCase() } }]
			} else {
				init.body.match = { _index: "carrier" };
			}

			const { hits } = await API.post(apiName, path, init);
			const sourceData = hits?.hits?.map(item => item?._source) || [];
			sourceData.forEach(item => items.push({
				label: item.name,
				value: item.id
			}));

			let carrierIds = user?.carriers?.items?.map(item => item.carrier.id)
			const carrierList = items.filter(item => {
				return !carrierIds.includes(item.value);
			});

			return carrierList

		} catch (error) {
			console.error('Error fetching courier data:', error);
		} finally {
			showSpinner(false);
		}
		let carrierIds = user?.carriers?.items?.map(item => item.carrier.id)

		const carrierList = items.filter(item => {
			return !carrierIds.includes(item.value);
		});

		return carrierList;
	};
	//#endregion

	//#region Add role 
	const addRole = (e) => {
		let shipperGroup = newUserRole ? [...newUserRole] : [];
		shipperGroup.push({
			id: 'NEW',
			name: '',
			role: 'VIEWER'
		})
		setNewUserRole(shipperGroup);
	}

	const removeRole = (index) => {
		let shipperGroup = newUserRole ? [...newUserRole] : [];
		shipperGroup.splice(index, 1);
		setNewUserRole(shipperGroup);
	}

	const handleRoleChange = (index, event, name, field) => {
		let item = newUserRole ? [...newUserRole] : [];
		let accessIds = item.map((role) => role?.id)
		if (accessIds?.includes(event?.value)) {
			toast.error(`${field} is already selected. Please choose a different one.`)
		} else {
			if (name === 'id') {
				item[index].id = event.value;
				item[index].name = event.label;
			}
			if (name === 'role') item[index].role = event;
			setNewUserRole(item);
		}
	}
	//#endregion

	const menus = [{ 'name': 'Overview', 'url': `/user/${userId}/overview`, 'active': true }]

	return (
		<>
			<PageHeader name={user?.name || '-'} title={user?.active ? 'Active User' : 'Disabled User'} pageTitle={"User Overview"} cover='/img/profile-cover-5.jpg' menus={menus}>
				{
					user && <Row>
						<Col className='col-auto'>
							<SelectRole value={user?.role} onChange={(role) => updateUserRole(role)} />
						</Col>
						<Col className='col-auto ps-0'>
							{
								user?.active ? <button className='btn btn-sm btn-light' onClick={() => disableUser()}>Disable User</button>
									: <button className='btn btn-sm btn-light' onClick={() => enableUser()}>Enable User</button>
							}
						</Col>
					</Row>
				}
			</PageHeader>
			<Spinner display={spinner}>
				<Container fluid>
					<Row>
						<Col lg={8}>
							<Card>
								<Card.Header>
									<h4 className='mb-0'>Shipper Group Roles</h4>
									<Button variant='light' size='sm' onClick={() => showShipperGroupModal(true)}>Add More</Button>
								</Card.Header>
								<Card.Body className='py-0'>
									<ListGroup className='list-group-flush'>
										{
											user?.shipperGroups?.items?.map((item, index) =>
												<ListGroupItem index={index}>
													<Row>
														<Col>
															{item.shipperGroup?.name}
														</Col>
														<Col className='col-auto text-muted'>
															<SelectRole value={item.role} onChange={(role) => updateShipperGroupRole(item.id, role)} />
														</Col>
													</Row>
												</ListGroupItem>
											)
										}
									</ListGroup>
								</Card.Body>
							</Card>
							<Card>
								<Card.Header>
									<h4 className='mb-0'>Shipper Roles</h4>
									<Button variant='light' size='sm' onClick={() => showShipperModal(true)}>Add More</Button>
								</Card.Header>
								<Card.Body className='py-0'>
									<ListGroup className='list-group-flush'>
										{
											user?.shippers?.items?.map((item, index) =>
												<ListGroupItem index={index}>
													<Row>
														<Col>
															{item.shipper?.name}
														</Col>
														<Col className='col-auto text-muted'>
															<SelectRole value={item.role} onChange={(role) => updateShipperRole(item.id, role)} />
														</Col>
													</Row>
												</ListGroupItem>
											)
										}
									</ListGroup>
								</Card.Body>
							</Card>
							<Card>
								<Card.Header>
									<h4 className='mb-0'>Courier Roles</h4>
									<Button variant='light' size='sm' onClick={() => showCarrierModal(true)} >Add More</Button>
								</Card.Header>
								<Card.Body className='py-0'>
									<ListGroup className='list-group-flush'>
										{
											user?.carriers?.items?.map((item, index) =>
												<ListGroupItem index={index}>
													<Row>
														<Col>
															{item.carrier?.name}
														</Col>
														<Col className='col-auto text-muted'>
															<SelectRole value={item.role} onChange={(role) => updateCarrierRole(item.id, role)} />
														</Col>
													</Row>
												</ListGroupItem>
											)
										}
									</ListGroup>
								</Card.Body>
							</Card>
						</Col>
						<Col lg={4}>
							<Card>
								<Card.Body className='py-0'>
									<ListGroup className='list-group-flush'>
										<ListGroupItem>
											<Row className='align-items-center'>
												<Col>Name</Col>
												{!editStatus ?
													<>
														<Col className='col-auto text-muted'>
															{user?.name || '-'}
														</Col>
														<Col lg={1} role='button' className='fe fe-edit-2' onClick={() => setEditStatus(true)}></Col>
													</>
													:
													<>
														<Col>
															<input
																value={userName}
																type="text"
																className="form-control"
																onChange={(e) => {
																	const value = e.target.value;
																	const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, '');
																	setUserName(alphabeticValue);
																}}
															/>
														</Col>
														<Col lg={1} role='button' className='fe fe-save' onClick={() => {
															if (userName?.length <= 0) {
																toast.error(`Please enter a valid name.`)
																return;
															}
															setEditStatus(false)
															updateUserHandle()
														}}></Col>
													</>}
											</Row>
										</ListGroupItem>
										<ListGroupItem>
											<Row>
												<Col>Email</Col>
												<Col className='col-auto text-muted'>
													{user?.email || '-'}
												</Col>
											</Row>
										</ListGroupItem>

										<ListGroupItem>
											<Row>
												<Col>Phone</Col>
												<Col className='col-auto text-muted'>
													{user?.phone || '-'}
												</Col>
											</Row>
										</ListGroupItem>
									</ListGroup>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Spinner>

			{/* Shipper Group Modal */}
			<Modal show={shipperGroupModal}>
				<Modal.Header>
					<Modal.Title>
						Shipper Group Roles
					</Modal.Title>
					<Button variant='outline-secondary' size='sm' onClick={() => addRole()}><i className='fe fe-plus mx-2'></i>Add More</Button>
				</Modal.Header>
				<Modal.Body className='p-0'>
					<Table className='table-sm table-fixed mb-0'>
						<thead>
							<tr>
								<th className='text-start'>Name</th>
								<th>Role</th>
							</tr>
						</thead>
						<tbody className='list font-size-base'>
							{
								newUserRole?.map((item, index) => (
									<tr key={index}>
										<td>
											<AsyncSelect key={index} value={{ label: item?.name || 'Choose Shipper Group', value: item.id }}
												type='text' loadOptions={searchShipperGroup} defaultOptions
												onChange={(e) => handleRoleChange(index, e, 'id', "shipper group")} />
										</td>
										<td>
											<button className={`btn btn-${newUserRole[index].role === 'OWNER' ? 'success' : 'light'} me-2`}
												onClick={() => handleRoleChange(index, 'OWNER', 'role')}>
												Owner
											</button>

											<button className={`btn btn-${newUserRole[index].role === 'EDITOR' ? 'success' : 'light'} me-2`}
												onClick={() => handleRoleChange(index, 'EDITOR', 'role')}>
												Editor
											</button>

											<button className={`btn btn-${newUserRole[index].role === 'VIEWER' ? 'success' : 'light'} me-2`}
												onClick={() => handleRoleChange(index, 'VIEWER', 'role')}>
												Viewer
											</button>
											{index > 0 && <button className='btn btn-light' onClick={() => removeRole(index)}>
												<i className='fe fe-trash'></i>
											</button>}
										</td>
									</tr>
								))
							}
						</tbody>
					</Table>


				</Modal.Body>
				<Modal.Footer className='display-flex-start'>
					<Spinner display={spinner}>
						<button className='btn btn-dark ms-2' onClick={() => handleCreateUser('ShipperGroupUser')}>Continue</button>
						<button className='btn btn-link text-muted' onClick={() => { showShipperGroupModal(false); setNewUserRole([{ id: '', name: '', role: 'VIEWER' }]) }} >Cancel</button>
					</Spinner>
				</Modal.Footer>
			</Modal>

			{/* shipper Modal  */}
			<Modal show={shipperModal}>
				<Modal.Header>
					<Modal.Title>
						Shipper Roles
					</Modal.Title>
					<button className='btn btn-sm btn-light' onClick={() => addRole()}>
						Add More
					</button>
				</Modal.Header>
				<Modal.Body className='p-0'>
					<Table className='table-sm table-fixed mb-0'>
						<thead>
							<tr>
								<th className='text-start'>Name</th>
								<th>Role</th>
							</tr>
						</thead>
						<tbody className='list font-size-base'>
							{
								newUserRole?.map((item, index) => (
									<tr key={index}>
										<td>
											<AsyncSelect key={index} value={{ label: item.name || 'Choose Shipper', value: item.id }}
												type='text' loadOptions={searchShipper} defaultOptions
												onChange={(e) => handleRoleChange(index, e, 'id', "Shipper")} />
										</td>
										<td>
											<button className={`btn btn-${newUserRole[index].role === 'OWNER' ? 'success' : 'light'} me-2`}
												onClick={() => handleRoleChange(index, 'OWNER', 'role')}>
												Owner
											</button>

											<button className={`btn btn-${newUserRole[index].role === 'EDITOR' ? 'success' : 'light'} me-2`}
												onClick={() => handleRoleChange(index, 'EDITOR', 'role')}>
												Editor
											</button>

											<button className={`btn btn-${newUserRole[index].role === 'VIEWER' ? 'success' : 'light'} me-2`}
												onClick={() => handleRoleChange(index, 'VIEWER', 'role')}>
												Viewer
											</button>

											{index > 0 && <button className='btn btn-light' onClick={() => removeRole(index)}>
												<i className='fe fe-trash'></i>
											</button>}
										</td>
									</tr>
								))
							}
						</tbody>
					</Table>


				</Modal.Body>
				<Modal.Footer className='display-flex-start'>
					<Spinner display={spinner}>
						<button className='btn btn-dark ms-2' onClick={() => handleCreateUser('ShipperUser')}>Continue</button>
						<button className='btn btn-link text-muted' onClick={() => { showShipperModal(false); setNewUserRole([{ id: '', name: '', role: 'VIEWER' }]) }} >Cancel</button>
					</Spinner>
				</Modal.Footer>
			</Modal>

			{/* carrier Modal  */}
			<Modal show={carrierModal}>
				<Modal.Header>
					<Modal.Title>
						Courier Roles
					</Modal.Title>
					<Button variant='outline-secondary' size='sm' onClick={() => addRole()}><i className='fe fe-plus mx-2'></i>Add More</Button>
				</Modal.Header>
				<Modal.Body className='p-0'>
					<Table className='table-sm table-fixed mb-0'>
						<thead>
							<tr>
								<th className='text-start'>Name</th>
								<th>Role</th>
							</tr>
						</thead>
						<tbody className='list font-size-base'>
							{
								newUserRole?.map((item, index) => (
									<tr key={index}>
										<td>
											<AsyncSelect key={index} value={{ label: item.name || 'Choose Courier', value: item.id }}
												type='text' loadOptions={searchCarrier} defaultOptions
												onChange={(e) => handleRoleChange(index, e, 'id', "Courier")} />
										</td>
										<td>
											<button className={`btn btn-${newUserRole[index].role === 'OWNER' ? 'success' : 'light'} me-2`}
												onClick={() => handleRoleChange(index, 'OWNER', 'role')}>
												Owner
											</button>

											<button className={`btn btn-${newUserRole[index].role === 'EDITOR' ? 'success' : 'light'} me-2`}
												onClick={() => handleRoleChange(index, 'EDITOR', 'role')}>
												Editor
											</button>

											<button className={`btn btn-${newUserRole[index].role === 'VIEWER' ? 'success' : 'light'} me-2`}
												onClick={() => handleRoleChange(index, 'VIEWER', 'role')}>
												Viewer
											</button>

											{index > 0 && <button className='btn btn-light' onClick={() => removeRole(index)}>
												<i className='fe fe-trash'></i>
											</button>}
										</td>
									</tr>
								))
							}
						</tbody>
					</Table>

				</Modal.Body>
				<Modal.Footer className='display-flex-start'>
					<Spinner display={spinner}>
						<button className='btn btn-dark ms-2' onClick={() => handleCreateUser('CarrierUser')}>Continue</button>
						<button className='btn btn-link text-muted' onClick={() => { showCarrierModal(false); setNewUserRole([{ id: '', name: '', role: 'VIEWER' }]) }} >Cancel</button>
					</Spinner>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default UserOverview;
