/* eslint-disable react-hooks/exhaustive-deps */
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
	const navigate = useNavigate();
	useEffect(() => logout(), [])
	
	function logout() {
		localStorage.removeItem('phap')
		Auth.signOut().then((res) => navigate('/'));
	}
}

export default Logout;
