import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
const apiName = 'api';

const listWarehouses = /* GraphQL */ `
  query ListWarehouses($limit: Int) {
    listWarehouses( limit: $limit) {
      items {
        id
        name
        alias
        phone
        email
      }
    }
  }
`;


export const ShipperGroupFilter = ({ value, dark, onChange, setTimeZone, feedbackStatus }) => {
    const textColor = dark ? 'text-light' : 'text-dark'
    const [group, setGroup] = useState([]);
    const search = async (keyword) => {
        const path = `${`/search/shipper-group?size=30`}`;
        const init = {
            body: {
                sort: [{ "name.keyword": { "order": "asc" } }],
                query: {
                    bool:
                    {
                        must: [],
                        must_not: [
                            {
                                match: {
                                    active: false
                                }
                            }
                        ]
                    }
                }
            },
        };
        if (keyword) init.body.query.bool.must.push({
            "multi_match": {
                "query": keyword,
                "type": "phrase_prefix",
                "fields": ["name^2", "alias"]
            }
        })
        const { hits } = await API.post(apiName, path, init);
        const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
        setGroup(sourceData)
        let items = [];
        sourceData.forEach(item => items.push({
            label: item.name,
            value: item.id
        }));

        return items
    }

    const handleChange = (e) => {
        let shipper = group?.find((data) => data?.id === e?.value)
        onChange(e);
        feedbackStatus && setTimeZone(shipper?.timeZone?.id);
    }

    return (
        <Col className='col-auto px-1 my-1'>
            <div className={`small ${textColor}`}>Shipper Group</div>
            <AsyncSelect cacheOptions defaultOptions isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: textColor,
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '120px'
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder='All'
                value={value}
                loadOptions={search} onChange={(e) => handleChange(e)}
            />
        </Col>
    )
}

export const ShipperFilter = ({ value, shipperGroup, dark, onChange, setTimeZone, feedbackStatus }) => {

    const textColor = dark ? 'text-light' : 'text-dark'

    const [defaultOptions, setDefaultOptions] = useState([]);
    const [shipperFilter, setShipperFilter] = useState({});
    const [group, setGroup] = useState([]);


    const search = async (keyword, filter) => {
        const path = `${`/search/shipper?size=30`}`;
        const init = {
            body: {
                sort: [{ "name.keyword": { "order": "asc" } }],
                query: {
                    bool: {
                        must: [],
                        must_not: [
                            {
                                match: {
                                    active: false
                                }
                            }
                        ]
                    }
                }
            },
        };
        if (keyword) init.body.query.bool.must.push({
            "multi_match": {
                "query": keyword,
                "type": "phrase_prefix",
                "fields": ["name^2", "alias"]
            }
        })
        if (shipperGroup?.value) {
            init.body.query.bool.must.push({
                match: { shipperGroupId: shipperGroup.value }
            })
        }
        const { hits } = await API.post(apiName, path, init);
        const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
        setGroup(sourceData)
        const items = sourceData.map((item) => ({
            label: item.name,
            value: item.id,
        }));
        return items;
    };

    useEffect(() => { onChange(null); }, [shipperGroup])

    useEffect(() => {
        const fetchDefaultOptions = async () => {
            const items = await search('', shipperFilter);
            setDefaultOptions(items);
        };

        fetchDefaultOptions();
    }, [shipperFilter, shipperGroup]);
    const handleChange = (e) => {
        let shipper = group?.find((data) => data?.id === e?.value)
        onChange(e);
        feedbackStatus && setTimeZone(shipper?.timeZone?.id);
    }
    return (
        <Col className='col-auto px-1 my-1'>
            <div className={`small ${textColor}`}>Shipper</div>
            <AsyncSelect
                defaultOptions={defaultOptions}
                isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: '#283E59',
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '120px'
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder='All'
                value={value}
                loadOptions={search} onChange={(e) => handleChange(e)}
            />
        </Col>
    )
}

export const CarrierFilter = ({ value, shipper, shipperGroup, dark, onChange, setTimeZone, feedbackStatus }) => {
    const textColor = dark ? 'text-light' : 'text-dark'
    const [group, setGroup] = useState([]);

    const search = async (keyword) => {
        const path = `${`/search/carrier?size=30`}`;
        const init = {
            body: {
                sort: [{ "name.keyword": { "order": "asc" } }],
                query: {
                    bool: {
                        must: [],
                        must_not: [
                            {
                                match: {
                                    active: false
                                }
                            }
                        ]
                    }
                }
            },
        };
        if (keyword) init.body.query.bool.must.push({
            "multi_match": {
                "query": keyword,
                "type": "phrase_prefix",
                "fields": ["name^2", "alias"]
            }
        })
        const { hits } = await API.post(apiName, path, init);
        const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
        setGroup(sourceData)
        let items = [];
        sourceData.forEach(item => items.push({
            label: item.name,
            value: item.id
        }));

        return items
    }
    const handleChange = (e) => {
        let shipper = group?.find((data) => data?.id === e?.value)
        onChange(e);
        feedbackStatus && setTimeZone(shipper?.timeZone?.id);
    }
    return (
        <Col className='col-auto px-1 my-1'>
            <div className={`small ${textColor}`}>Courier</div>
            <AsyncSelect cacheOptions defaultOptions isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: '#283E59',
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '120px'
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder='All'
                value={value}
                loadOptions={search} onChange={(e) => handleChange(e)}
            />
        </Col>
    )
}
export const CarrierFilterFromDeliveryService = ({ value, shipper, shipperGroup, dark, onChange }) => {
    const deliveryServicesByShipperId = /* GraphQL */ `
    query DeliveryServicesByShipperId(
        $shipperId: ID!
    ) {
        deliveryServicesByShipperId(
            shipperId: $shipperId
        ) {
            items {
                id
                name
                shipperId
                carrierId
                carrier{
                    id
                    name
                    alias
                    image
                    active
                }
            }
        }
    }
`;
    const textColor = dark ? 'text-light' : 'text-dark'
    const search = async (keyword) => {
        let items = [];

        if (shipper?.value) {
            const response = await API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId: shipper?.value } });
            let deliveryServices = response.data.deliveryServicesByShipperId?.items.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }));
            let carriers = [];
            deliveryServices.forEach((item) => {
                if (!carriers.find(x => x?.id === item?.carrier?.id))
                    carriers.push(item.carrier)
            });

            carriers = carriers.filter((carrier) => carrier?.active !== false).sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }))
            items = carriers.map((item) => ({
                label: item.name,
                value: item.id,
            }));
        }
        return items
    }

    useEffect(() => {
        search()
    }, [shipper])

    useEffect(() => { onChange(null) }, [shipper, shipperGroup])

    return (
        <Col className='col-auto px-1 my-1'>
            <div className={`small ${textColor}`}>Courier</div>
            <AsyncSelect cacheOptions defaultOptions isClearable
                key={shipper?.value}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: '#283E59',
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '120px'
                    }),
                }}
                isSearchable={!shipper}
                classNamePrefix='phox-as-sm'
                placeholder='All'
                value={value}
                loadOptions={search} onChange={onChange}
            />
        </Col>
    )
}

export const DriverFilter = ({ value, carrier, dark, onChange }) => {
    const textColor = dark ? 'text-light' : 'text-dark'
    const search = async (keyword) => {
        const path = `${`/search/user?size=30`}`;
        const init = {
            body: {
                query: { bool: { must: [] } }
            },
        };
        if (keyword) init.body.query.bool.must.push({
            "multi_match": {
                "query": keyword,
                "type": "phrase_prefix",
                "fields": ["name", "alias"]
            }
        })
        const { hits } = await API.post(apiName, path, init);
        let sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
        sourceData?.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'accent' }))
        const items = sourceData.map((item) => ({
            label: item.name,
            value: item.id,
        }));
        return items;
    }

    useEffect(() => { onChange(null) }, [carrier])

    return (
        <Col className='col-auto px-1 my-1'>
            <div className={`small ${textColor}`}>Driver</div>
            <AsyncSelect defaultOptions isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: '#283E59',
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '120px'
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder='All'
                defaultValue={value}
                loadOptions={search} onChange={onChange}
            />
        </Col>
    )
}

export const StatusFilter = ({ value, dark, onChange, dontShowTitle = true }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    const textColor = dark ? 'text-light' : 'text-dark'

    const statusOptions = [
        { label: 'Ready for Pickup', value: 'READY_FOR_PICKUP' },
        { label: 'Out for Delivery', value: 'OUT_FOR_DELIVERY' },
        { label: 'Delivered', value: 'DELIVERED' },
        { label: 'In Transit', value: 'IN_TRANSIT' },
        { label: 'Out for Return', value: 'OUT_FOR_RETURN' },
        { label: 'Returned', value: 'RETURNED' },
        { label: 'Cancelled', value: 'EXCEPTION' },
        { label: 'Open', value: 'OPEN' },
    ]

    return (
        <Col className='col-auto px-1 my-1'>
            {dontShowTitle && < div className={`small ${textColor}`}>Status</div>}
            <Select isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: '#283E59',
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '120px'
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder={!dontShowTitle ? 'All Status' : "All"}
                value={value}
                options={statusOptions}
                onChange={onChange}
            />
        </Col >
    )
}

export const GreenPhoxFilter = ({ value, dark, onChange }) => {

    const color = dark ? 'btn-dark' : 'btn-light'
    const textColor = dark ? 'text-light' : 'text-dark'

    const Options = [
        { label: 'GreenPhox', value: true },
        { label: 'Other', value: false },
        { lable: 'All', value: 'All' }
    ]


    return (
        <Col className='col-auto px-1 my-1'>
            <div className={`small ${textColor}`}>Box Type</div>
            <Select isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: '#283E59',
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '120px'
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder='All'
                value={value}
                options={Options}
                onChange={onChange}
            />
        </Col>
    )
}


export const DateFilter = ({ dark, onChange, startOf = 0, isBilling = false }) => {
    const color = dark ? 'btn-dark' : 'btn-light'
    const textColor = dark ? 'text-light' : 'text-dark'

    const startDate = moment().tz('America/New_York').subtract(startOf, 'days').startOf('day')
    const endDate = moment().tz('America/New_York').endOf('day')

    const handleDateChange = (event, picker) => {
        const fromDate = moment.tz(picker.startDate.format('YYYY-MM-DD 00:00:00'), 'America/New_York').unix()
        const toDate = moment.tz(picker.endDate.format('YYYY-MM-DD 23:59:59'), 'America/Los_Angeles').unix()
        onChange({ fromDate, toDate })
    }

    let ranges = {
        'Tomorrow': [moment().add(1, 'day'), moment().add(1, 'day')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 14 Days': [moment().subtract(13, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    }

    if (isBilling) {
        ranges = {
            'Last 1H': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').startOf('month').add(14, 'days')],
            'Last 2H': [moment().subtract(1, 'month').startOf('month').add(15, 'days'), moment().subtract(1, 'month').endOf('month')],
            'Current 1H': [moment().startOf('month'), moment().startOf('month').add(14, 'days')],
            'Current 2H': [moment().startOf('month').add(15, 'days'), moment().endOf('month')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        }
    }

    return (
        <Col className='col-auto px-1 my-1'>
            <div className={`small ${textColor}`}>Date Range</div>
            <DateRangePicker onApply={handleDateChange}
                initialSettings={{
                    startDate: startDate,
                    endDate: endDate,
                    showDropdowns: true,
                    alwaysShowCalendars: true,
                    opens: 'left',
                    locale: { format: 'MMM D, YYYY' },
                    ranges
                }}>
                <input className={`btn btn-sm btn-light`} style={{ width: '200px' }} />
            </DateRangePicker>
        </Col>
    )
}

export const BoxShipperGroupFilter = ({ value, dark, onChange }) => {
    const textColor = dark ? 'text-light' : 'text-dark'
    const search = async (keyword) => {
        const path = `${`/search/shipper-group?size=30`}`;
        const init = { body: { sort: [{ "name.keyword": { "order": "asc" } }], query: { bool: { must: [] } } }, };
        if (keyword) init.body.query.bool.must.push({
            "multi_match": {
                "query": keyword,
                "type": "phrase_prefix",
                "fields": ["name^2", "alias"]
            }
        })
        const { hits } = await API.post(apiName, path, init);
        const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
        let items = [];
        sourceData.forEach(item => items.push({
            label: item.name,
            value: item.id
        }));

        return items
    }

    return (
        <Col className='col-auto px-1 my-1'>
            <AsyncSelect cacheOptions defaultOptions isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: textColor,
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '200px'
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder='Shipper Group'
                value={value}
                loadOptions={search} onChange={onChange}
            />
        </Col>
    )
}

export const BoxShipperFilter = (({ value, shipperGroup, dark, onChange }) => {
    const textColor = dark ? 'text-light' : 'text-dark'

    const [defaultOptions, setDefaultOptions] = useState([]);
    const [shipperFilter, setShipperFilter] = useState();

    const search = async (keyword, filter) => {
        const path = `${`/search/shipper?size=30`}`;
        const init = { body: { sort: [{ "name.keyword": { "order": "asc" } }], query: { bool: { must: [] } } }, };
        if (keyword) init.body.query.bool.must.push({
            "multi_match": {
                "query": keyword,
                "type": "phrase_prefix",
                "fields": ["name^2", "alias"]
            }
        })
        if (shipperGroup?.value) {
            init.body.query.bool.must.push({
                match: { shipperGroupId: filter.value }
            });
        }
        const { hits } = await API.post(apiName, path, init);
        const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
        const items = sourceData.map((item) => ({
            label: item.name,
            value: item.id,
        }));
        return items;
    };

    useEffect(() => {
        const fetchDefaultOptions = async () => {
            const items = await search('', shipperGroup);
            setDefaultOptions(items);
        };
        fetchDefaultOptions();
    }, [shipperGroup]);
    return (
        <Col className='col-auto px-1 my-1'>
            <AsyncSelect
                key={Math.random()}
                defaultOptions={defaultOptions}
                isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: '#283E59',
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '200px'
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder='Shipper'
                value={value}
                loadOptions={search} onChange={onChange}
            />
        </Col>
    )
})

export const BoxCarrierFilter = ({ value, shipper, shipperGroup, dark, onChange }) => {

    const deliveryServicesByShipperId = /* GraphQL */ `
        query DeliveryServicesByShipperId(
            $shipperId: ID!
        ) {
            deliveryServicesByShipperId(
                shipperId: $shipperId
            ) {
                items {
                    id
                    name
                    shipperId
                    carrierId
                    carrier{
                        id
                        name
                        alias
                        image
                    }
                }
            }
        }
    `;

    const textColor = dark ? 'text-light' : 'text-dark'

    const [defaultOptions, setDefaultOptions] = useState([]);

    const search = async (shipper) => {
        let items = [];
        if (shipper?.value) {
            const response = await API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId: shipper?.value } });
            let deliveryServices = response.data.deliveryServicesByShipperId?.items.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }));
            let carriers = [];
            deliveryServices.forEach((item) => {
                if (!carriers.find(x => x?.id === item?.carrier?.id))
                    if (!['FedEx', 'USPS', 'UPS'].includes(item?.carrier?.name)) carriers.push(item.carrier)
            });

            carriers = carriers.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }))
            items = carriers.map((item) => ({
                label: item.name,
                value: item.id,
            }));
        }
        return items; // Return the items array
    };

    useEffect(() => {
        onChange(null)
    }, [shipperGroup, shipper])

    useEffect(() => {
        const fetchDefaultOptions = async () => {
            const items = await search(shipper);
            setDefaultOptions(items);
        };
        fetchDefaultOptions();
    }, [shipper, shipperGroup]);

    return (
        <Col className='col-auto px-1 my-1'>
            <AsyncSelect
                defaultOptions={defaultOptions}
                isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: '#283E59',
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '200px'
                    }),
                }}
                isSearchable={false}
                classNamePrefix='phox-as-sm'
                placeholder='Carrier'
                value={value}
                loadOptions={search}
                onChange={onChange}
            />
        </Col>
    )
};



export const CardShipperGroupFilter = ({ value, dark, onChange, inCard }) => {
    const textColor = dark ? 'text-light' : 'text-dark'
    const search = async (keyword) => {
        const path = `${`/search/shipper-group?size=30`}`;
        const init = { body: { sort: [{ "name.keyword": { "order": "asc" } }], query: { bool: { must: [] } } }, };
        if (keyword) init.body.query.bool.must.push({
            "multi_match": {
                "query": keyword,
                "type": "phrase_prefix",
                "fields": ["name^2", "alias"]
            }
        })
        const { hits } = await API.post(apiName, path, init);
        const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
        let items = [];
        sourceData.forEach(item => items.push({
            label: item.name,
            value: item.id
        }));

        return items
    }

    return (
        <Col className='col-auto px-1 my-1'>
            <AsyncSelect cacheOptions defaultOptions isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: textColor,
                        backgroundColor: "#fff",
                        border: "1.5px solid #d0ddef",
                        minHeight: "40.62px",
                        minWidth: "250px"
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder='ShipperGroup'
                value={value}
                loadOptions={search} onChange={onChange}
            />
        </Col>
    )
}

export const CardShipperFilter = (({ value, dark, onChange, shipperGroup }) => {
    const textColor = dark ? 'text-light' : 'text-dark';
    const [defaultOptions, setDefaultOptions] = useState([]);
    const [currentShipperGroup, setCurrentShipperGroup] = useState(null);

    const search = async (keyword, filter) => {
        const path = `/search/shipper?size=30`;
        const init = {
            body: {
                sort: [{ "name.keyword": { "order": "asc" } }],
                query: { bool: { must: [] } }
            }
        };
        if (keyword) {
            init.body.query.bool.must.push({
                "multi_match": {
                    "query": keyword,
                    "type": "phrase_prefix",
                    "fields": ["name^2", "alias"]
                }
            });
        }
        if (filter?.value) {
            init.body.query.bool.must.push({
                match: { shipperGroupId: filter.value }
            });
        }
        const { hits } = await API.post(apiName, path, init);
        const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
        const items = sourceData.map((item) => ({
            label: item.name,
            value: item.id,
        }));
        return items;
    };

    useEffect(() => {
        const fetchDefaultOptions = async () => {
            const items = await search('', shipperGroup);
            setDefaultOptions(items);
        };
        fetchDefaultOptions();
        setCurrentShipperGroup(shipperGroup);
        onChange(null); // Reset value when shipperGroup changes
    }, [shipperGroup]);

    useEffect(() => {
        if (currentShipperGroup !== shipperGroup) {
            // If shipperGroup changes, reset the value
            onChange(null);
            setCurrentShipperGroup(shipperGroup);
        }
    }, [currentShipperGroup, shipperGroup]);

    return (
        <Col className='col-auto px-1 my-1'>
            <AsyncSelect
                key={Math.random()}
                defaultOptions={defaultOptions}
                isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: '#283E59',
                        backgroundColor: "#fff",
                        borderWidth: "0.5px",
                        border: "1.5px solid #d0ddef",
                        minHeight: "40.62px",
                        minWidth: "250px"
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder='Shipper'
                value={value}
                loadOptions={search}
                onChange={onChange}
            />
        </Col>
    );
});

export const DeliveryServiceFilter = ({ value, myShipper, carrier, dark, onChange }) => {

    const deliveryServicesByShipperId = /* GraphQL */ `
    query DeliveryServicesByShipperId(
        $shipperId: ID!
    ) {
        deliveryServicesByShipperId(
            shipperId: $shipperId
        ) {
            items {
                id
                name
                shipperId
                carrierId
                carrier{
                    id
                    name
                    alias
                    image
                }
            }
        }
    }
`;

    const [services, setServices] = useState([])
    const textColor = dark ? 'text-light' : 'text-dark'

    useEffect(() => {
        search();
    }, [myShipper])

    useEffect(() => { onChange(null) }, [myShipper, carrier])

    const search = async (keyword) => {
        let res;
        let newItem;
        let items = [];

        if (myShipper?.value) {
            res = myShipper?.value && await API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId: myShipper?.value } })
            res?.data && res?.data?.deliveryServicesByShipperId?.items.filter((item) => item?.carrierId === carrier?.value).forEach(item => items.push({
                label: item.name,
                value: item.id
            }));

            if (keyword) {
                newItem = items.filter((item) => {
                    if (item.label.toLowerCase().includes(keyword.toLowerCase())) {
                        return item
                    }
                })
                setServices(newItem)
                return newItem
            } else {
                setServices(items)
                return items
            }
        } else {
            return items
        }
    }

    return (
        <Col className='col-auto px-1 my-1'>
            <div className={`small ${textColor}`}>Service</div>
            <AsyncSelect
                isClearable
                isSearchable={false}
                className="basic-single"
                classNamePrefix='phox-as-sm'
                placeholder='All'
                options={value}
                defaultOptions={services}
                loadOptions={search}
                onChange={onChange}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: textColor,
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '120px'
                    }),
                }}
            />
        </Col>
    )
}

export const ListPageDriverFilter = ({ value, carrier, dark, onChange, shipperGroup, shipper }) => {
    const [newDriverOptions, setNewDriverOptions] = useState();
    const textColor = dark ? 'text-light' : 'text-dark'
    const search = async (keyword) => {
        const path = `${`/search/user?size=1000`}`;
        const init = {
            body: {
                sort: {
                    "name.keyword": {
                        "order": "asc"
                    }

                },
                query: {
                    bool: {
                        "filter": [
                            {
                                "term": {
                                    "carriers.carrier.name.keyword": carrier?.label
                                }
                            }
                        ]
                    }
                }
            },
        };
        if (keyword) init.body.query.bool.must.push({
            "multi_match": {
                "query": keyword,
                "type": "phrase_prefix",
                "fields": ["name", "alias"]
            }
        })
        const { hits } = await API.post(apiName, path, init);
        let sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
        sourceData?.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'accent' }))
        let items = [{
            label: "Unassigned",
            value: "driverNotAssigned",
        }]
        sourceData.map((item) => (
            items.push(
                {
                    label: item.name,
                    value: item.id,
                })
        ));
        setNewDriverOptions(items)
        return items;
    }

    useEffect(() => { search(); onChange(null) }, [carrier, shipperGroup, shipper])

    return (
        <Col className='col-auto px-1 my-1'>
            <div className={`small ${textColor}`}>Driver</div>
            <AsyncSelect isClearable
                key={carrier}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: '#283E59',
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '120px'
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder='All'
                value={value}
                loadOptions={search} onChange={onChange}
                defaultOptions={newDriverOptions}
            />
        </Col>
    )
}

export const ListWarehouseFilter = ({ value, dark, onChange, }) => {
    const [warehouseList, setWarehouseList] = useState([])

    const color = dark ? 'btn-dark' : 'btn-light'
    const textColor = dark ? 'text-light' : 'text-dark'

    useEffect(() => {
        getWareHouseList()
    }, [])

    const getWareHouseList = async () => {
        await API.graphql({ query: listWarehouses, variables: { limit: 100 } }).then((response) => {
            let items = []
            response?.data?.listWarehouses?.items?.forEach(item => items.push({
                label: item.name,
                value: item.id
            }));

            setWarehouseList(items);
        })
    }

    const search = (keyword) => {
        warehouseList.map((item) => { if (item?.label.includes(keyword)) return item })
    }

    return (
        <Col className='col-auto px-1 my-1'>
            < div className={`small ${textColor}`}>Warehouse</div>
            <Select isClearable
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '0.8125rem',
                        color: '#283E59',
                        backgroundColor: '#EDF2F9',
                        borderWidth: '0px',
                        minHeight: '30px',
                        minWidth: '120px'
                    }),
                }}
                classNamePrefix='phox-as-sm'
                placeholder={"All"}
                value={value}
                loadOptions={search}
                options={warehouseList}
                onChange={onChange}
            />
        </Col >
    )
}
