import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { StatCard } from '../../components/Card';
import { isViewer } from '../../helpers';
import ReactGA from "react-ga4"

const ShipperGroupOverview = () => {

  ReactGA.send({
    hitType: "pageview",
    page: "/shipper-group/:shipperGroupId/overview",
  })


  const { shipperGroupId } = useParams()
  const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP)
  const myUserRole = useSelector((state) => state.slice.USER.role)

  const menus = [
    { 'name': 'Overview', 'url': `/shipper-group/${shipperGroupId}/overview`, 'active': true },
    { 'name': 'Shippers', 'url': `/shipper-group/${shipperGroupId}/shipper`, 'active': false },
  ]

  return (
    <>
      <PageHeader title='Shipper Group' name={myShipperGroup?.name} menus={menus} image={myShipperGroup?.image}  >
        {!isViewer(myUserRole) && <Link className='btn btn-dark' to={`/shipper-group/${shipperGroupId}/edit`}>
          Edit Shipper Group
        </Link>}
      </PageHeader>

      <Container fluid>
        <Row>
          <StatCard title='Shipments' value='-' icon='box' />
          <StatCard title='Shipments' value='-' icon='box' />
          <StatCard title='Shipments' value='-' icon='box' />
          <StatCard title='Shipments' value='-' icon='box' />
        </Row>
      </Container>
    </>
  )
}

export default ShipperGroupOverview;