import React from 'react'
import { Link } from 'react-router-dom'
import ReactGA from "react-ga4"

const PageNotFound = () => {

	ReactGA.send({
		hitType: "pageview",
		page: "*",
		title: "Page Not Found"
	})

	return (
		<div className='container-fluid d-flex justify-content-center align-items-center vh-100 phox-font'>
			<div className='text-primary text-uppercase'>
				<h1>This page could not be found</h1>
				<Link to='/'>Go to login page</Link>
			</div>
		</div >
	)
}

export default PageNotFound
